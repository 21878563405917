import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import type RouterService from '@ember/routing/router-service';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

import type CartProviderService from './cart-provider';
import type LocaleService from './locale';
import type MembershipService from './membership';
import type PreloadService from './preload';

const CHECKOUT_NAMES = [
  'cart',
  'donations_prompt',
  'gift_aid_prompt',
  'products_prompt',
  'extras_prompt',
  'login_wall',
  'checkout',
  'confirm_order'
] as const;

export type CheckoutStepName = (typeof CHECKOUT_NAMES)[number];
interface BaseCheckoutStep {
  name: CheckoutStepName;
  route: string;
  label: string;
  available: boolean;
}
export interface CheckoutStep extends BaseCheckoutStep {
  index: number;
}

export default class CheckoutFlowService extends Service {
  @service private locale!: LocaleService;
  @service private router!: RouterService;
  @service private cartProvider!: CartProviderService;
  @service private membership!: MembershipService;
  @service private preload!: PreloadService;

  private referrer: number | null = null;

  get donationsPromptStep(): BaseCheckoutStep {
    return {
      name: 'donations_prompt',
      route: 'donations-prompt',
      label: this.locale.translate(`checkout.steps.donations_prompt`),
      available: this.cartProvider.cart.hasPromptableDonations
    };
  }
  get giftaidStep(): BaseCheckoutStep {
    return {
      name: 'gift_aid_prompt',
      route: 'gift-aid-prompt',
      label: this.locale.translate(`checkout.steps.gift_aid_prompt`),
      available:
        this.cartProvider.cart.hasGiftAidDonation &&
        !this.membership.hasGiftAidPreference &&
        this.preload.getValue('gift_aid')
    };
  }
  get productsPromptStep(): BaseCheckoutStep {
    return {
      name: 'products_prompt',
      route: 'products-prompt',
      label: this.locale.translate(`checkout.steps.product_prompt`),
      available: this.cartProvider.cart.hasProductSuggestions
    };
  }
  get extrasPromptStep(): BaseCheckoutStep {
    return {
      name: 'extras_prompt',
      route: 'extras-prompt',
      label: this.locale.translate(`checkout.steps.extras_prompt`),
      available: this.cartProvider.cart.hasExtrasDefinitions
    };
  }

  get cartStep(): BaseCheckoutStep {
    return {
      name: 'cart',
      route: 'cart',
      label: this.locale.translate(`checkout.steps.cart`),
      available: true
    };
  }
  get checkoutStep(): BaseCheckoutStep {
    return {
      name: 'checkout',
      route: 'checkout',
      label: this.locale.translate(`checkout.steps.checkout`),
      available: true
    };
  }
  get confirmOrderStep(): BaseCheckoutStep {
    return {
      name: 'confirm_order',
      route: 'confirm-order',
      label: this.locale.translate(`checkout.steps.confirm_order`),
      available: true
    };
  }
  get loginWallStep(): BaseCheckoutStep {
    return {
      name: 'login_wall',
      route: 'login-wall',
      label: this.locale.translate(`checkout.steps.login_wall`),
      available: this.membership.isEnabled
    };
  }

  private get allSteps(): CheckoutStep[] {
    const steps = this.preload.getValue('gift_aid')
      ? [
          this.cartStep,
          this.productsPromptStep,
          this.extrasPromptStep,
          this.loginWallStep,
          this.donationsPromptStep,
          this.giftaidStep,
          this.checkoutStep,
          this.confirmOrderStep
        ]
      : [
          this.cartStep,
          this.productsPromptStep,
          this.extrasPromptStep,
          this.donationsPromptStep,
          this.loginWallStep,
          this.checkoutStep,
          this.confirmOrderStep
        ];

    return steps.map((step, index) => ({ ...step, index }));
  }

  public get availableSteps(): CheckoutStep[] {
    const { currentRouteName } = this.router;
    return this.allSteps
      .filter(step => step.available || step.route === currentRouteName)
      .map((step, index) => ({ ...step, index }));
  }

  public prevAvailableStepFor(name: CheckoutStepName): CheckoutStep | null {
    const curStep = this.stepByName(name);
    const prevAvailStep = this.allSteps
      .reverse()
      .find(step => step.available && step.index < curStep.index);
    if (!prevAvailStep) {
      return null;
    }
    return this.availableStepByName(prevAvailStep.name);
  }

  public nextAvailableStepFor(name: CheckoutStepName): CheckoutStep | null {
    const curStep = this.stepByName(name);
    const nextAvailStep = this.allSteps.find(
      step => step.available && step.index > curStep.index
    );
    if (!nextAvailStep) {
      return null;
    }
    return this.availableStepByName(nextAvailStep.name);
  }

  private availableStepByName(name: CheckoutStepName): CheckoutStep | null {
    return this.availableSteps.find(step => step.name === name)!;
  }
  private stepByName(name: CheckoutStepName): CheckoutStep {
    return this.allSteps.find(step => step.name === name)!;
  }
  private stepByRoute(route: string): CheckoutStep {
    return this.allSteps.find(step => step.route === route)!;
  }

  @action
  goToNextStep(name: CheckoutStepName): Transition | null {
    const nextStep = this.nextAvailableStepFor(name);

    if (nextStep) {
      return this.router.transitionTo(nextStep.route);
    }

    return null;
  }

  @action
  goToPrevStep(name: CheckoutStepName): Transition | null {
    const nextStep = this.prevAvailableStepFor(name);

    if (nextStep) {
      return this.router.transitionTo(nextStep.route);
    }

    return null;
  }

  @action
  skipStep(
    name: CheckoutStepName,
    fromRoute: string | undefined
  ): Transition | null {
    if (!fromRoute) {
      return this.goToNextStep(name);
    }

    const curStep = this.stepByName(name);
    const fromStep = this.stepByRoute(fromRoute);

    if (!curStep || !fromStep) {
      return this.goToNextStep(name);
    }

    if (fromStep.index > curStep.index) {
      return this.goToPrevStep(name);
    }

    return this.goToNextStep(name);
  }

  @action
  setReferrer() {
    if (!this.referrer) {
      this.referrer = window.history.length;
    }
  }

  @action
  resetReferrer() {
    this.referrer = null;
  }

  @action
  async navigateBack() {
    const historyLength = window.history.length;
    const referrer = this.referrer || historyLength;
    if (this.referrer && this.referrer > 1) {
      this.resetReferrer();
      window.history.go(referrer - historyLength - 1);
    } else {
      this.router.transitionTo('listings.shows');
    }
  }
}
