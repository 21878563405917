import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { format } from 'date-fns';
import type RouterService from 'node_modules/@types/ember__routing/router-service';

import { concat } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';

import Icon from 'tangram/components/icon';
import ButtonTbPrimary from 'ticketbooth/components/button/tb/primary';
import CoverShowHeader from 'ticketbooth/components/cover/show-header';
import translate from 'ticketbooth/helpers/translate';
import type EventModel from 'ticketbooth/models/event';
import type OrderModel from 'ticketbooth/models/order';

interface EventHubEventInfoHeaderSignature {
  Args: {
    event: EventModel;
    order: OrderModel;
    openWallets: Function;
  };
}

export default class EventHubEventInfoHeader extends Component<EventHubEventInfoHeaderSignature> {
  @service private router!: RouterService;

  @tracked isScrolled = false;
  @tracked scrollContainer!: HTMLElement;
  @tracked stickyHeader!: HTMLElement;
  @tracked stickyHeaderOffset!: number;
  @tracked isTransitioning: boolean = false;

  willDestroy() {
    super.willDestroy();
    this.cleanupScrollTracking();
  }

  @action cleanupScrollTracking() {
    this.scrollContainer.removeEventListener(
      'scroll',
      this.trackScrollBehaviour
    );
    this.stickyHeader.removeEventListener(
      'transitionend',
      this.handleTransitionEnd
    );
  }

  @action
  setupScrollTracking(element: HTMLDivElement) {
    this.scrollContainer = document.querySelector(
      '#content-scroll-container'
    ) as HTMLElement;

    this.stickyHeader = element;

    // Get the initial position of the sticky header relative to the scroll container
    this.stickyHeaderOffset = this.stickyHeader.offsetTop;

    // Bind the scroll event handler
    this.scrollContainer?.addEventListener(
      'scroll',
      this.trackScrollBehaviour,
      { passive: true }
    );

    this.stickyHeader.addEventListener(
      'transitionend',
      this.handleTransitionEnd
    );
  }

  @action handleTransitionEnd(event: TransitionEvent) {
    if (event.propertyName === 'max-height') {
      // give a bit time to settle so that we don't retrigger right away
      later(
        this,
        () => {
          this.isTransitioning = false;
        },
        500
      );
    }
  }

  @action
  trackScrollBehaviour() {
    if (this.isTransitioning) {
      return;
    }

    const scrollTop = this.scrollContainer.scrollTop;

    // Check if the scroll position has reached the point where the sticky header would have scrolled away
    if (scrollTop > this.stickyHeaderOffset && !this.isScrolled) {
      this.isScrolled = true; // Shrink the header
      this.isTransitioning = true;
    } else if (scrollTop <= this.stickyHeaderOffset && this.isScrolled) {
      this.isScrolled = false; // Expand the header
      this.isTransitioning = true;
    }
  }

  get showItemControls() {
    return this.router.currentRouteName.includes('event-hub.index');
  }

  get show() {
    return this.args.event.show;
  }

  get event() {
    return this.args.event;
  }

  get date() {
    if (!this.event.day) return '';
    return format(this.event.day, 'd MMM');
  }

  get day() {
    if (!this.event.day) return '';
    return format(this.event.day, 'do MMMM yyyy');
  }

  get openingTime() {
    return (
      this.event.openingTime && `${format(this.event.openingTime, 'HH:MM')}`
    );
  }

  get numberOfTickets() {
    return this.args.order.totalTicketsQuantity;
  }

  get numberOfProducts() {
    return this.args.order.connectedOrders.reduce(
      (acc, order) => acc + order.totalProductQuantity,
      this.args.order.totalProductQuantity
    );
  }

  <template>
    <div class='sticky z-sticky top-14 bg-white'>
      <div
        id='event-info-header'
        class='flex
          {{if this.isScrolled "px-5 py-3.5" "p-5"}}
          transition-all duration-300 border-b border-gray-200'
        {{didInsert this.setupScrollTracking}}
      >
        {{#if this.show.defaultAttachment}}
          <div
            data-test-event-info-header-show-cover={{this.show.id}}
            class='relative overflow-hidden rounded transition-all duration-400 w-full
              {{if this.isScrolled "max-w-0" "max-w-16 mr-5"}}'
          >
            <CoverShowHeader
              @attachment={{this.show.defaultAttachment}}
              @cover={{true}}
            />
          </div>
        {{/if}}

        <div class='flex flex-col justify-center'>
          <h1
            class='text-lg leading-none font-semibold'
            data-test-event-info-header-show-name
          >
            {{@event.show.name}}
          </h1>

          {{! Display when not scrolling }}
          <div
            class='flex flex-col gap-3 transition-all duration-100 ease-in-out
              {{if this.isScrolled "max-h-0" "max-h-full mt-1"}}'
          >
            <div
              data-test-event-info-header-date
              class='truncate text-sm text-black leading-none opacity-60 uppercase font-semibold'
            >
              {{this.day}}
              {{if this.openingTime (concat ' - ' this.openingTime)}}
            </div>
            <div
              data-test-event-info-header-location
              class='truncate text-xs opacity-60 text-black uppercase font-semibold'
            >
              {{this.event.venue.name}}
            </div>
          </div>
        </div>

        {{! Display when scrolled }}
        <div
          class='flex flex-col gap-1 ml-auto transition-all duration-400 ease-in-out
            {{if this.isScrolled "max-w-full" "max-w-0"}}'
        >
          <div
            data-test-event-info-header-day
            class='truncate text-xs text-black leading-none opacity-60 font-semibold uppercase flex items-end gap-2'
          >
            <Icon @name='calendar' @w='w-3' />
            {{this.date}}
          </div>
          {{#if this.openingTime}}
            <div
              data-test-event-info-header-opening-time
              class='truncate text-xs text-black leading-none opacity-60 font-semibold uppercase flex items-end gap-2'
            >
              <Icon @name='clock' @w='w-3' />
              {{this.openingTime}}
            </div>
          {{/if}}
        </div>
      </div>

      {{#if this.showItemControls}}
        <div
          class='flex justify-between items-center px-5 transition-all duration-300 border-b
            {{if this.isScrolled "border-gray-200 py-2" "border-white py-5"}}'
        >
          <div class='items-center'>
            <h2
              class='leading-none {{if this.isScrolled "text-sm" "text-lg"}}'
            >My Items</h2>
            <div
              class='flex text-xs overflow-hidden opacity-80 transition-all duration-200
                {{unless this.isScrolled "max-h-0"}}'
              data-test-event-info-header-ticket-products-quantity
            >

              {{translate
                'eventhub.number_of_tickets'
                count=this.numberOfTickets
              }},
              {{translate
                'eventhub.number_of_products'
                count=this.numberOfProducts
              }}
            </div>
          </div>

          <ButtonTbPrimary
            @small={{true}}
            @narrowStyle={{true}}
            @classes='text-xs leading-none'
            @testSelector='open-wallets'
            @onClick={{@openWallets}}
            as |b|
          >
            {{translate 'eventhub.add_all_to_wallet'}}
            {{! @glint-ignore }}
            <b.ui.rightIcon @name='download' />
          </ButtonTbPrimary>
        </div>
      {{/if}}
    </div>
  </template>
}
