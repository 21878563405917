import type { Locale } from 'tangram/helpers/humanize-locale';

export type LocaleValue = {
  locale: Locale;
  value: string;
};
export type LocaleDefault = {
  default: string;
};
export type SnippetType = LocaleValue | LocaleDefault;

export type CountableLocaleObject = {
  other: SnippetType[];
  zero?: SnippetType[];
  one?: SnippetType[];
};

type LocaleType = SnippetType[] | CountableLocaleObject | null;
export type LocaleObject = { [k: string]: LocaleType | LocaleObject };

const locales: LocaleObject = {
  eventhub: {
    add_all_to_wallet: [
      { locale: 'en-GB', value: 'Add all to wallet' },
      { default: 'Add all to wallet' }
    ],
    number_of_tickets: [
      {
        locale: 'en-GB',
        value: '%{count} tickets'
      },
      {
        default: '%{count} tickets'
      }
    ],
    number_of_products: [
      {
        locale: 'en-GB',
        value: '%{count} products'
      },
      {
        default: '%{count} products'
      }
    ],
    wallets: {
      batch_download: [
        { locale: 'en-GB', value: 'Batch download' },
        { default: 'Batch download' }
      ],
      pass_download: [
        { locale: 'en-GB', value: 'Single download' },
        { default: 'Single download' }
      ],
      qr_downloads: [
        { locale: 'en-GB', value: 'QR Downloads' },
        { default: 'QR Downloads' }
      ],
      add_from_to: [
        { locale: 'en-GB', value: 'Add %{from}-%{to} to wallet' },
        { default: 'Add %{from}-%{to} to wallet' }
      ],
      add_all_to_wallet: [
        { locale: 'en-GB', value: 'Add all to wallet' },
        { default: 'Add all to wallet' }
      ]
    },
    preorder: {
      title: [
        { locale: 'en-GB', value: 'Preorder items' },
        { default: 'Preorder items' }
      ],
      select_pickup: [
        { locale: 'en-GB', value: 'Please select your pick-up time' },
        { default: 'Please select your pick-up time' }
      ]
    },
    cart: {
      title: [
        { locale: 'en-GB', value: 'Your cart' },
        { default: 'Your cart' }
      ],
      pickup_items: [
        {
          locale: 'en-GB',
          value:
            'You can pick up your items at %{pickup_point}. Check the event info for further details.'
        },
        {
          default:
            'You can pick up your items at %{pickup_point}. Check the event info for further details.'
        }
      ]
    },
    order_completed: {
      title: [
        {
          locale: 'en-GB',
          value: 'Thanks %{customerName}, your order has been completed.'
        },
        { default: 'Thanks %{customerName}, your order has been completed.' }
      ],
      your_order: [
        { locale: 'en-GB', value: 'Your order' },
        { default: 'Your order' }
      ]
    },
    header: {
      my_items: [
        { locale: 'en-GB', value: 'My Items' },
        { default: 'My Items' }
      ],
      preorder: [
        { locale: 'en-GB', value: 'Preorder' },
        { default: 'Preorder' }
      ],
      event_information: [
        { locale: 'en-GB', value: 'Event Information' },
        { default: 'Event Information' }
      ],
      map: [{ locale: 'en-GB', value: 'Map' }, { default: 'Map' }]
    },
    navigation: {
      go_back: [{ locale: 'en-GB', value: 'Back' }, { default: 'Back' }],
      continue: [
        { locale: 'en-GB', value: 'Continue' },
        { default: 'Continue' }
      ],
      goto_preorder: [
        { locale: 'en-GB', value: 'Preorder items' },
        { default: 'Preorder items' }
      ],
      return_to_preorder: [
        { locale: 'en-GB', value: 'Return to Preorder items' },
        { default: 'Return to Preorder items' }
      ],
      goto_cart: [
        { locale: 'en-GB', value: 'Checkout' },
        { default: 'Checkout' }
      ],
      view_all_items: [
        { locale: 'en-GB', value: 'View all items' },
        { default: 'Checkout' }
      ],
      order_more_products: [
        { locale: 'en-GB', value: 'Order again' },
        { default: 'Checkout' }
      ]
    }
  },
  date: {
    formats: {
      default: [
        {
          locale: 'en-GB',
          value: 'YYYY-MM-DD'
        },
        {
          locale: 'cy-CY',
          value: 'YYYY-MM-DD'
        },
        {
          locale: 'fr-FR',
          value: 'YYYY-MM-DD'
        },
        {
          locale: 'ga-IE',
          value: 'YYYY-MM-DD'
        },
        {
          default: 'YYYY-MM-DD'
        }
      ],
      short: [
        {
          locale: 'en-GB',
          value: 'D MMM YYYY'
        },
        {
          locale: 'cy-CY',
          value: 'D MMM YYYY'
        },
        {
          locale: 'fr-FR',
          value: 'D MMM YYYY'
        },
        {
          locale: 'ga-IE',
          value: 'D MMM YYYY'
        },
        {
          default: 'D MMM YYYY'
        }
      ],
      day_of_week: [
        {
          locale: 'en-GB',
          value: 'ddd'
        },
        {
          locale: 'cy-CY',
          value: 'ddd'
        },
        {
          locale: 'fr-FR',
          value: 'ddd'
        },
        {
          locale: 'ga-IE',
          value: 'ddd'
        },
        {
          default: 'ddd'
        }
      ],
      short_month: [
        {
          locale: 'en-GB',
          value: 'MMM'
        },
        {
          locale: 'cy-CY',
          value: 'MMM'
        },
        {
          locale: 'fr-FR',
          value: 'MMM'
        },
        {
          locale: 'ga-IE',
          value: 'MMM'
        },
        {
          default: 'MMM'
        }
      ],
      full_month: [
        {
          locale: 'en-GB',
          value: 'MMMM'
        },
        {
          locale: 'cy-CY',
          value: 'MMMM'
        },
        {
          locale: 'fr-FR',
          value: 'MMMM'
        },
        {
          locale: 'ga-IE',
          value: 'MMMM'
        },
        {
          default: 'MMMM'
        }
      ],
      full: [
        {
          locale: 'en-GB',
          value: 'dddd D MMMM YYYY'
        },
        {
          locale: 'cy-CY',
          value: 'dddd D MMMM YYYY'
        },
        {
          locale: 'fr-FR',
          value: 'dddd D MMMM YYYY'
        },
        {
          locale: 'ga-IE',
          value: 'dddd D MMMM YYYY'
        },
        {
          default: 'dddd D MMMM YYYY'
        }
      ],
      long: [
        {
          locale: 'en-GB',
          value: 'ddd D MMM YYYY'
        },
        {
          locale: 'cy-CY',
          value: 'ddd D MMM YYYY'
        },
        {
          locale: 'fr-FR',
          value: 'ddd D MMM YYYY'
        },
        {
          locale: 'ga-IE',
          value: 'ddd D MMM YYYY'
        },
        {
          default: 'ddd D MMM YYYY'
        }
      ]
    },
    order: {
      '0': [
        {
          locale: 'en-GB',
          value: 'year'
        },
        {
          locale: 'cy-CY',
          value: 'year'
        },
        {
          locale: 'fr-FR',
          value: 'année'
        },
        {
          locale: 'ga-IE',
          value: 'bliain'
        },
        {
          default: 'year'
        }
      ],
      '1': [
        {
          locale: 'en-GB',
          value: 'month'
        },
        {
          locale: 'cy-CY',
          value: 'month'
        },
        {
          locale: 'fr-FR',
          value: 'mois'
        },
        {
          locale: 'ga-IE',
          value: 'mí'
        },
        {
          default: 'month'
        }
      ],
      '2': [
        {
          locale: 'en-GB',
          value: 'day'
        },
        {
          locale: 'cy-CY',
          value: 'day'
        },
        {
          locale: 'fr-FR',
          value: 'jour'
        },
        {
          locale: 'ga-IE',
          value: 'lá'
        },
        {
          default: 'day'
        }
      ]
    },
    special: {
      all_day: [
        {
          locale: 'en-GB',
          value: 'All Day'
        },
        {
          locale: 'cy-CY',
          value: "Trwy'r dydd"
        },
        {
          locale: 'fr-FR',
          value: 'Toute la journée'
        },
        {
          locale: 'ga-IE',
          value: 'An lá ar fad'
        },
        {
          default: 'All Day'
        }
      ]
    }
  },
  common: {
    all_day: [
      {
        locale: 'en-GB',
        value: 'All Day'
      },
      {
        locale: 'cy-CY',
        value: "trwy'r dydd"
      },
      {
        locale: 'fr-FR',
        value: 'Toute la journée'
      },
      {
        locale: 'ga-IE',
        value: 'Ar feadh an lae'
      },
      {
        default: 'All Day'
      }
    ],
    at: [
      {
        locale: 'en-GB',
        value: 'at,'
      },
      {
        locale: 'cy-CY',
        value: 'yn'
      },
      {
        locale: 'fr-FR',
        value: 'à,'
      },
      {
        locale: 'ga-IE',
        value: 'ag'
      },
      {
        default: 'at,'
      }
    ],
    or: [
      {
        locale: 'en-GB',
        value: 'or'
      },
      {
        locale: 'cy-CY',
        value: 'oy'
      },
      {
        locale: 'fr-FR',
        value: 'ou'
      },
      {
        locale: 'ga-IE',
        value: 'nó'
      },
      {
        default: 'or'
      }
    ],
    home: [
      {
        locale: 'en-GB',
        value: 'Home'
      },
      {
        locale: 'cy-CY',
        value: 'Hafan'
      },
      {
        locale: 'fr-FR',
        value: 'Accueil'
      },
      {
        locale: 'ga-IE',
        value: 'Baile'
      },
      {
        default: 'Home'
      }
    ],
    loading: [
      {
        locale: 'en-GB',
        value: 'Loading...'
      },
      {
        locale: 'cy-CY',
        value: 'llwytho'
      },
      {
        locale: 'fr-FR',
        value: 'Chargement...'
      },
      {
        locale: 'ga-IE',
        value: 'ag luchtáil...'
      },
      {
        default: 'Loading...'
      }
    ],
    edit: [
      {
        locale: 'en-GB',
        value: 'Edit'
      },
      {
        locale: 'cy-CY',
        value: 'newid'
      },
      {
        locale: 'fr-FR',
        value: 'Modifier'
      },
      {
        locale: 'ga-IE',
        value: 'cuir in eagar'
      },
      {
        default: 'Edit'
      }
    ],
    four_oh_four: [
      {
        locale: 'en-GB',
        value: 'Sorry, something seems to have gone wrong.'
      },
      {
        locale: 'cy-CY',
        value: "Mae'n ddrwg gennym, mae rhywbeth wedi mynd o'i le"
      },
      {
        locale: 'fr-FR',
        value: "Désolé, il semble qu'il y ait eu un problème."
      },
      {
        locale: 'ga-IE',
        value: 'Ar leithscéal, tá rud éigin cearr.'
      },
      {
        default: 'Sorry, something seems to have gone wrong.'
      }
    ],
    free: [
      {
        locale: 'en-GB',
        value: 'Free'
      },
      {
        locale: 'cy-CY',
        value: 'am ddim'
      },
      {
        locale: 'fr-FR',
        value: 'Gratuit'
      },
      {
        locale: 'ga-IE',
        value: 'saor'
      },
      {
        default: 'Free'
      }
    ],
    here: [
      {
        locale: 'en-GB',
        value: 'here'
      },
      {
        locale: 'cy-CY',
        value: 'yma'
      },
      {
        locale: 'fr-FR',
        value: 'ici'
      },
      {
        locale: 'ga-IE',
        value: 'anseo'
      },
      {
        default: 'here'
      }
    ],
    less: [
      {
        locale: 'en-GB',
        value: 'less'
      },
      {
        locale: 'cy-CY',
        value: 'llai'
      },
      {
        locale: 'fr-FR',
        value: 'moins'
      },
      {
        locale: 'ga-IE',
        value: 'níos lú'
      },
      {
        default: 'less'
      }
    ],
    more: [
      {
        locale: 'en-GB',
        value: 'more'
      },
      {
        locale: 'cy-CY',
        value: 'mwy'
      },
      {
        locale: 'fr-FR',
        value: 'plus'
      },
      {
        locale: 'ga-IE',
        value: 'níos mó'
      },
      {
        default: 'more'
      }
    ],
    left: [
      {
        locale: 'en-GB',
        value: 'left'
      },
      {
        locale: 'cy-CY',
        value: 'ar ôl'
      },
      {
        locale: 'fr-FR',
        value: 'restant(s)'
      },
      {
        locale: 'ga-IE',
        value: 'fágtha'
      },
      {
        default: 'left'
      }
    ],
    fees: [
      {
        locale: 'en-GB',
        value: 'fees'
      },
      {
        locale: 'cy-CY',
        value: 'ffi'
      },
      {
        locale: 'fr-FR',
        value: 'frais'
      },
      {
        locale: 'ga-IE',
        value: 'táillí'
      },
      {
        default: 'fees'
      }
    ],
    cookies_consent: [
      {
        locale: 'en-GB',
        value: 'Cookies consent'
      },
      {
        locale: 'cy-CY',
        value: 'Cydsyniad cwcis'
      },
      {
        locale: 'fr-FR',
        value: 'Consentement aux cookies'
      },
      {
        locale: 'ga-IE',
        value: 'Toiliú fianán'
      },
      {
        default: 'Cookies consent'
      }
    ],
    no_cookies_text: [
      {
        locale: 'en-GB',
        value:
          'Using our online sales system requires that third party cookies are enabled in your browser. The type of cookies that we store on your system are only necessary to keep your order tracked and to make purchases through our system. They are not used to collect personal information or to promote products.\n If you are using Safari you need to turn off "Prevent cross site tracking" or switch to a private browser window.\n To continue please revert to the default cookie/privacy settings in your browser or add an exception for the following site:\n'
      },
      {
        locale: 'cy-CY',
        value:
          "Mae'r system archebu tocynnau yn gofyn bod cwcis trydydd parti yn cael eu galluogi yn eich porwr. Mae'r cwcis yn ydym yn storio yn eich system dim ond er mwyn i chi cwbwlhau'r archeb. Ni chânt eu defnyddio i gasglu gwybodaeth bersonol nac i hyrwyddo cynhyrchion.\n Os ydych chi'n defnyddio Safari, mae angen i chi ddiffodd \"Prevent cross site tracking\" neu newid i porwr preifat.\n I barhau, dychwelwch yn ôl i'r gosodiadau cwci / preifatrwydd diofyn yn eich porwr ac ychwanegwch eithriad ar gyfer y wefan ganlynol:\n"
      },
      {
        locale: 'fr-FR',
        value:
          'Pour utiliser notre système de vente en ligne, veuillez vous assurer que les cookies sont activés dans votre navigateur. Les cookies que nous stockons sur votre système ne servent qu’à suivre votre commande et à effectuer des achats par le biais de notre système. Ils ne servent pas à recueillir des données personnelles ou à promouvoir des produits. \\n Afin de continuer, merci de rétablir les paramètres de cookie/confidentialité par défaut dans votre navigateur ou d’ajouter une exception pour le site suivant :\n'
      },
      {
        locale: 'ga-IE',
        value:
          "Trí úsáid a bhaint as ár gcóras díolacháin ar líne, tá cumasú fianán tríú páirtí ar do bhrabhsalaí riachtanach. Ligean na cineál fíachán a stóráiltear ar an gcóras d'ordú a rianú agus ceannacháin a dhéanamh ar ár gcóras amháin. Ní úsáidtear na fianáin le heolas fút atá inaitheanta go pearsanta a bhailiú nó le cur chun cinn táirgí.\\n  Le leanúint ar aghaidh, fill ar na fianáin réamhshocruithe príomháideachais ar do bhrabhsálaí agus cuir eisceacht isteach don suíomh a leanas:\n"
      },
      {
        default:
          'Using our online sales system requires that third party cookies are enabled in your browser. The type of cookies that we store on your system are only necessary to keep your order tracked and to make purchases through our system. They are not used to collect personal information or to promote products.\n If you are using Safari you need to turn off "Prevent cross site tracking" or switch to a private browser window.\n To continue please revert to the default cookie/privacy settings in your browser or add an exception for the following site:\n'
      }
    ],
    iframe_disabled: [
      {
        locale: 'en-GB',
        value:
          'Putting the Ticketsolve ticketing system in an IFrame requires configurations handled by our staff. Please reach out to your contact for more information.'
      },
      {
        locale: 'cy-CY',
        value:
          'Wrth rhoi Ticketsolve mewn i iframe mae rhiad i staff Ticketsolve gwneud newidiadau yn y system. Cysylltwch a ni am fwy o wybodaeth.'
      },
      {
        locale: 'fr-FR',
        value:
          'L’installation du système de billetterie Ticketsolve dans un IFrame requiert des configurations gérées par notre personnel. Veuillez nous contacter pour plus d’informations.'
      },
      {
        locale: 'ga-IE',
        value:
          'Má theastaíonn uait córas ticéadaithe Ticketsolve a shuiteáil i fráma inlínithe beidh cumraíocht ónár bhfoireann ag teastáil. Déan teagmháil le do theagmhálaí chun tuilleadh eolais a fháil'
      },
      {
        default:
          'Putting the Ticketsolve ticketing system in an IFrame requires configurations handled by our staff. Please reach out to your contact for more information.'
      }
    ],
    price: [
      {
        locale: 'en-GB',
        value: 'Price'
      },
      {
        locale: 'cy-CY',
        value: 'pris'
      },
      {
        locale: 'fr-FR',
        value: 'Prix'
      },
      {
        locale: 'ga-IE',
        value: 'praghas'
      },
      {
        default: 'Price'
      }
    ],
    quantity: [
      {
        locale: 'en-GB',
        value: 'Quantity'
      },
      {
        locale: 'cy-CY',
        value: 'nifer'
      },
      {
        locale: 'fr-FR',
        value: 'Quantité'
      },
      {
        locale: 'ga-IE',
        value: 'méid'
      },
      {
        default: 'Quantity'
      }
    ],
    confirm: [
      {
        locale: 'en-GB',
        value: 'Confirm'
      },
      {
        locale: 'cy-CY',
        value: 'Cadarnhau'
      },
      {
        locale: 'ga-IE',
        value: 'Dearbhaigh'
      },
      {
        default: 'Confirm'
      }
    ],
    cancel: [
      {
        locale: 'en-GB',
        value: 'Cancel'
      },
      {
        locale: 'cy-CY',
        value: 'Canslo'
      },
      {
        locale: 'ga-IE',
        value: 'Cealaigh'
      },
      {
        default: 'Cancel'
      }
    ],
    click: [
      {
        locale: 'en-GB',
        value: 'Click'
      },
      {
        locale: 'cy-CY',
        value: 'Clic'
      },
      {
        locale: 'ga-IE',
        value: 'Cliceáil'
      },
      {
        default: 'Click'
      }
    ],
    save: [
      {
        locale: 'en-GB',
        value: 'Save'
      },
      {
        locale: 'cy-CY',
        value: 'Arbed'
      },
      {
        locale: 'ga-IE',
        value: 'Sábháil'
      },
      {
        default: 'Save'
      }
    ],
    see: [
      {
        locale: 'en-GB',
        value: 'See'
      },
      {
        locale: 'cy-CY',
        value: 'gwelwch'
      },
      {
        locale: 'fr-FR',
        value: 'Voir'
      },
      {
        locale: 'ga-IE',
        value: 'Amharc'
      },
      {
        default: 'See'
      }
    ],
    send: [
      {
        locale: 'en-GB',
        value: 'Send'
      },
      {
        locale: 'cy-CY',
        value: 'Danfon'
      },
      {
        locale: 'ga-IE',
        value: 'Seol'
      },
      {
        default: 'Send'
      }
    ],
    submit: [
      {
        locale: 'en-GB',
        value: 'Submit'
      },
      {
        locale: 'cy-CY',
        value: 'cyflwyno'
      },
      {
        locale: 'fr-FR',
        value: 'Envoyer'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir isteach'
      },
      {
        default: 'Submit'
      }
    ],
    subtotal: [
      {
        locale: 'en-GB',
        value: 'Subtotal'
      },
      {
        locale: 'cy-CY',
        value: 'is-gyfanswm'
      },
      {
        locale: 'fr-FR',
        value: 'Sous-total'
      },
      {
        locale: 'ga-IE',
        value: 'Fo-iomlán'
      },
      {
        default: 'Subtotal'
      }
    ],
    total: [
      {
        locale: 'en-GB',
        value: 'Total'
      },
      {
        locale: 'cy-CY',
        value: 'cyfanswm'
      },
      {
        locale: 'fr-FR',
        value: 'Total'
      },
      {
        locale: 'ga-IE',
        value: 'Iomlán'
      },
      {
        default: 'Total'
      }
    ],
    paid: [
      {
        locale: 'en-GB',
        value: 'Paid'
      },
      {
        locale: 'cy-CY',
        value: 'wedi talu'
      },
      {
        locale: 'fr-FR',
        value: 'Payé'
      },
      {
        locale: 'ga-IE',
        value: 'Íoctha'
      },
      {
        default: 'Paid'
      }
    ],
    remaining: [
      {
        locale: 'en-GB',
        value: 'Remaining'
      },
      {
        locale: 'cy-CY',
        value: "sy'n weddill"
      },
      {
        locale: 'fr-FR',
        value: 'Restant'
      },
      {
        locale: 'ga-IE',
        value: 'Fágtha'
      },
      {
        default: 'Remaining'
      }
    ],
    remaining_lowercase: [
      {
        locale: 'en-GB',
        value: 'remaining'
      },
      {
        locale: 'cy-CY',
        value: 'yn weddill'
      },
      {
        locale: 'fr-FR',
        value: 'restant'
      },
      {
        locale: 'ga-IE',
        value: 'fágtha'
      },
      {
        default: 'remaining'
      }
    ],
    outstanding: [
      {
        locale: 'en-GB',
        value: 'Outstanding'
      },
      {
        locale: 'cy-CY',
        value: 'Yn weddill'
      },
      {
        locale: 'fr-FR',
        value: 'À payer'
      },
      {
        locale: 'ga-IE',
        value: 'Dlite (suim dhlite)'
      },
      {
        default: 'Outstanding'
      }
    ],
    continue: [
      {
        locale: 'en-GB',
        value: 'Continue'
      },
      {
        locale: 'cy-CY',
        value: 'Parhau'
      },
      {
        locale: 'fr-FR',
        value: 'Continuer'
      },
      {
        locale: 'ga-IE',
        value: 'Lean ar aghaidh'
      },
      {
        default: 'Continue'
      }
    ],
    to_proceed: [
      {
        locale: 'en-GB',
        value: 'to proceed.'
      },
      {
        locale: 'cy-CY',
        value: 'i fwrw ymlaen.'
      },
      {
        locale: 'ga-IE',
        value: 'dul ar aghaidh.'
      },
      {
        default: 'to proceed.'
      }
    ],
    not_available: [
      {
        locale: 'en-GB',
        value: 'Not available'
      },
      {
        locale: 'cy-CY',
        value: 'Ddim ar gael'
      },
      {
        locale: 'fr-FR',
        value: 'Non disponible'
      },
      {
        locale: 'ga-IE',
        value: 'Níl ar fáil'
      },
      {
        default: 'Not available'
      }
    ],
    pay_now: [
      {
        locale: 'en-GB',
        value: 'Pay Now'
      },
      {
        locale: 'cy-CY',
        value: 'Talu nawr'
      },
      {
        locale: 'fr-FR',
        value: 'Payer maintenant'
      },
      {
        locale: 'ga-IE',
        value: 'Íoc Anois'
      },
      {
        default: 'Pay Now'
      }
    ],
    creditcard: [
      {
        locale: 'en-GB',
        value: 'Credit card'
      },
      {
        locale: 'cy-CY',
        value: 'Credit card'
      },
      {
        locale: 'fr-FR',
        value: 'Carte de crédit'
      },
      {
        locale: 'ga-IE',
        value: 'Cárta dochair/creidmheasa'
      },
      {
        default: 'Credit card'
      }
    ],
    voucher: [
      {
        locale: 'en-GB',
        value: 'Voucher'
      },
      {
        locale: 'cy-CY',
        value: 'Voucher'
      },
      {
        locale: 'fr-FR',
        value: 'Chèque cadeau'
      },
      {
        locale: 'ga-IE',
        value: 'Dearbhán'
      },
      {
        default: 'Voucher'
      }
    ],
    week_days_short: {
      monday: [
        { locale: 'cy-CY', value: 'Llun' },
        { locale: 'en-GB', value: 'Mon' },
        { locale: 'fr-FR', value: 'Lun' },
        { locale: 'ga-IE', value: 'Luan' }
      ],
      tuesday: [
        { locale: 'cy-CY', value: 'Maw' },
        { locale: 'en-GB', value: 'Tue' },
        { locale: 'fr-FR', value: 'Mar' },
        { locale: 'ga-IE', value: 'Máirt' }
      ],
      wednesday: [
        { locale: 'cy-CY', value: 'Mer' },
        { locale: 'en-GB', value: 'Wed' },
        { locale: 'fr-FR', value: 'Mer' },
        { locale: 'ga-IE', value: 'Céad' }
      ],
      thursday: [
        { locale: 'cy-CY', value: 'Iau' },
        { locale: 'en-GB', value: 'Thu' },
        { locale: 'fr-FR', value: 'Thu' },
        { locale: 'ga-IE', value: 'Déar' }
      ],
      friday: [
        { locale: 'cy-CY', value: 'Gwe' },
        { locale: 'en-GB', value: 'Fri' },
        { locale: 'fr-FR', value: 'Ven' },
        { locale: 'ga-IE', value: 'Aoine' }
      ],
      saturday: [
        { locale: 'cy-CY', value: 'Sad' },
        { locale: 'en-GB', value: 'Sat' },
        { locale: 'fr-FR', value: 'Sam' },
        { locale: 'ga-IE', value: 'Sath' }
      ],
      sunday: [
        { locale: 'cy-CY', value: 'Sul' },
        { locale: 'en-GB', value: 'Sun' },
        { locale: 'fr-FR', value: 'Dim' },
        { locale: 'ga-IE', value: 'Domh' }
      ]
    }
  },
  errors: {
    default: [
      {
        locale: 'en-GB',
        value:
          'Oops! Something went wrong. Please, try again later or contact support if the problem persists'
      },
      {
        locale: 'cy-CY',
        value:
          "Wps! Aeth rhywbeth o'i le. Ceisiwch eto neu cysylltwch cefnogaeth os yw'r broblem yn parhau"
      },
      {
        locale: 'fr-FR',
        value:
          "Oups ! Il semble qu'il y ait eu un problème. Veuillez réessayer plus tard ou contacter notre service client si le problème persiste"
      },
      {
        locale: 'ga-IE',
        value:
          'úps! Tá rud éigin cearr. Bain triail as níos déanaí. Mura mbíonn réiteach ar an bhfadhb, téigh i dteagmháil leis an bhfoireann tacaíochta'
      },
      {
        default:
          'Oops! Something went wrong. Please, try again later or contact support if the problem persists'
      }
    ],
    not_found_title: [
      {
        locale: 'en-GB',
        value: "The page you were looking for doesn't exist."
      },
      {
        locale: 'cy-CY',
        value: "Dydi'r dudalen yr oeddech yn chwilio amdano yn bodoli."
      },
      {
        locale: 'fr-FR',
        value: "La page que vous recherchez n’existe' pas."
      },
      {
        locale: 'ga-IE',
        value: 'Níl an leathanach atá á lorg agat ar fáil.'
      },
      {
        default: "The page you were looking for doesn't exist."
      }
    ],
    not_found_description: [
      {
        locale: 'en-GB',
        value: 'You may have mistyped the address or the page may have moved.'
      },
      {
        locale: 'cy-CY',
        value:
          "Efallai eich bod wedi camdeipio'r cyfeiriad neu efallai mae'r dudalen wedi symud."
      },
      {
        locale: 'fr-FR',
        value:
          'Vous avez peut-être fait une erreur dans l’adresse ou la page a peut-être été déplacée.'
      },
      {
        locale: 'ga-IE',
        value:
          'Seans go bhfuil botún clóscríofa sa seoladh nó gur bhog an leathanach.'
      },
      {
        default: 'You may have mistyped the address or the page may have moved.'
      }
    ],
    iframe: [
      {
        locale: 'en-GB',
        value: 'Something went wrong.'
      },
      {
        locale: 'cy-CY',
        value: "Mae rwybeth wedi mynd o'i le."
      },
      {
        locale: 'fr-FR',
        value: "Il semble qu'il y ait eu un problème."
      },
      {
        locale: 'ga-IE',
        value: 'Theip ar rud éigin'
      },
      {
        default: 'Something went wrong.'
      }
    ],
    promotion_code: {
      default: [
        {
          locale: 'en-GB',
          value: 'There was an error trying to apply your promotion code'
        },
        {
          locale: 'cy-CY',
          value: 'Roedd gwall wrth i ni ceisio defnyddio ei cod hyrwyddo'
        },
        {
          locale: 'fr-FR',
          value:
            'Une erreur s’est produite lors de l’application de votre code promotionnel'
        },
        {
          locale: 'ga-IE',
          value: 'Tharla earráid le cur i bhfeidhm an cód promóisin'
        },
        {
          default: 'There was an error trying to apply your promotion code'
        }
      ],
      already_applied: [
        {
          locale: 'en-GB',
          value: 'You have already applied this promotion code'
        },
        {
          locale: 'cy-CY',
          value: "Yr ydych eisoes wedi gwneud cais gyda'r cod hyrwyddo yma"
        },
        {
          locale: 'fr-FR',
          value: 'Vous avez déjà appliqué ce code promotionnel'
        },
        {
          locale: 'ga-IE',
          value: 'Tá an cód promóisin curtha i bhfeidhm agat cheana féin'
        },
        {
          default: 'You have already applied this promotion code'
        }
      ],
      not_found: [
        {
          locale: 'en-GB',
          value: 'The specified promotion code does not exist'
        },
        {
          locale: 'cy-CY',
          value: "Nid yw'r cod hyrwyddo yma yn bodoli"
        },
        {
          locale: 'fr-FR',
          value: 'Le code promotionnel spécifié n’existe pas'
        },
        {
          locale: 'ga-IE',
          value: 'Níor aimsíodh an cód promóisin seo'
        },
        {
          default: 'The specified promotion code does not exist'
        }
      ],
      required: [
        {
          locale: 'en-GB',
          value: 'You must specify a promotion code'
        },
        {
          locale: 'cy-CY',
          value: 'Rhaid i chi nodi cod hyrwyddo'
        },
        {
          locale: 'fr-FR',
          value: 'Vous devez spécifier un code promotionnel'
        },
        {
          locale: 'ga-IE',
          value: 'Caithfidh tú cód promóisin a shonrú'
        },
        {
          default: 'You must specify a promotion code'
        }
      ]
    }
  },
  cart: {
    added: [
      {
        locale: 'en-GB',
        value: 'added to cart'
      },
      {
        locale: 'cy-CY',
        value: 'wedi ei ychwanegu at eich cert'
      },
      {
        locale: 'fr-FR',
        value: 'ajouté au panier'
      },
      {
        locale: 'ga-IE',
        value: 'cuireadh sa chiseán'
      },
      {
        default: 'added to cart'
      }
    ],
    removed: [
      {
        locale: 'en-GB',
        value: 'removed from cart'
      },
      {
        locale: 'cy-CY',
        value: "wedi ei tynnu o'r cert"
      },
      {
        locale: 'fr-FR',
        value: 'retiré du panier'
      },
      {
        locale: 'ga-IE',
        value: 'baineadh ón gciseán.'
      },
      {
        default: 'removed from cart'
      }
    ],
    add_to_cart: [
      {
        locale: 'en-GB',
        value: 'Add to cart'
      },
      {
        locale: 'cy-CY',
        value: 'Ychwanegu at eich cert'
      },
      {
        locale: 'fr-FR',
        value: 'Ajouter au panier'
      },
      {
        locale: 'ga-IE',
        value: 'cuir le ciseán'
      },
      {
        default: 'Add to cart'
      }
    ],
    gift_to: [
      {
        locale: 'en-GB',
        value: 'Gift to:'
      },
      {
        locale: 'cy-CY',
        value: 'Rhodd i:'
      },
      {
        locale: 'ga-IE',
        value: 'Bronn ar:'
      },
      {
        default: 'Gift to:'
      }
    ],
    update_cart: [
      {
        locale: 'en-GB',
        value: 'Update cart'
      },
      {
        locale: 'cy-CY',
        value: 'Diweddaru eich cert'
      },
      {
        locale: 'fr-FR',
        value: 'Mettre à jour le panier'
      },
      {
        locale: 'ga-IE',
        value: 'Uasdátaigh ciseán'
      },
      {
        default: 'Update cart'
      }
    ],
    in_your_cart: [
      {
        locale: 'en-GB',
        value: 'in your cart'
      },
      {
        locale: 'cy-CY',
        value: 'yn eich cert'
      },
      {
        locale: 'fr-FR',
        value: 'dans votre panier'
      },
      {
        locale: 'ga-IE',
        value: 'i do chiseán'
      },
      {
        default: 'in your cart'
      }
    ],
    confirm_selection: [
      {
        locale: 'en-GB',
        value: 'Confirm selection'
      },
      {
        locale: 'cy-CY',
        value: 'Cadarnhewch eich dewis'
      },
      {
        locale: 'fr-FR',
        value: 'Confirmer la sélection'
      },
      {
        locale: 'ga-IE',
        value: 'Deimhnigh rogha'
      },
      {
        default: 'Confirm selection'
      }
    ],
    repick_seats: [
      {
        locale: 'en-GB',
        value: 'Repick seats'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswch eich seddi eto'
      },
      {
        locale: 'fr-FR',
        value: 'Choisir de nouveaux sièges'
      },
      {
        locale: 'ga-IE',
        value: 'Athroghnaigh suíocháin'
      },
      {
        default: 'Repick seats'
      }
    ],
    empty: [
      {
        locale: 'en-GB',
        value: 'Your shopping cart is empty'
      },
      {
        locale: 'cy-CY',
        value: "Mae'ch cert yn wag"
      },
      {
        locale: 'fr-FR',
        value: 'Votre panier est vide'
      },
      {
        locale: 'ga-IE',
        value: 'Tá do chiseán folamh'
      },
      {
        default: 'Your shopping cart is empty'
      }
    ],
    expiration: {
      you_have: [
        {
          locale: 'en-GB',
          value: 'You have'
        },
        {
          locale: 'cy-CY',
          value: 'Mae gennych'
        },
        {
          locale: 'fr-FR',
          value: 'Vous devez'
        },
        {
          locale: 'ga-IE',
          value: 'Tá' // this makes sense when paired with expiration.to_confirm
        },
        {
          default: 'You have'
        }
      ],
      to_confirm: [
        {
          locale: 'en-GB',
          value: 'to confirm your order before tickets in your cart expire.'
        },
        {
          locale: 'cy-CY',
          value: 'i gadarnhau eich archeb cyn i’r fasged gael eu dileu'
        },
        {
          locale: 'fr-FR',
          value:
            'confirmer votre commande avant que les billets dans votre panier n’expirent.'
        },
        {
          locale: 'ga-IE',
          value: "d'ordú a dheimhniú sula dtéann na ticéid i do chiseán in éag"
        },
        {
          default: 'to confirm your order before tickets in your cart expire.'
        }
      ]
    },
    expired: [
      {
        locale: 'en-GB',
        value: 'Cart has expired'
      },
      {
        locale: 'cy-CY',
        value: 'Cert wedi ei gwagu'
      },
      {
        locale: 'fr-FR',
        value: 'Votre panier a expiré'
      },
      {
        locale: 'ga-IE',
        value: 'Ciseán imithe as feidhm'
      },
      {
        default: 'Cart has expired'
      }
    ],
    view_cart: [
      {
        locale: 'en-GB',
        value: 'View cart'
      },
      {
        locale: 'cy-CY',
        value: 'dangos y cert'
      },
      {
        locale: 'fr-FR',
        value: 'Voir le panier'
      },
      {
        locale: 'ga-IE',
        value: 'Amharc ar do chiseán'
      },
      {
        default: 'View cart'
      }
    ],
    empty_cart: [
      {
        locale: 'en-GB',
        value: 'Empty cart'
      },
      {
        locale: 'cy-CY',
        value: "Gwagio'r fasged"
      },
      {
        locale: 'fr-FR',
        value: 'Panier vide'
      },
      {
        locale: 'ga-IE',
        value: 'Folmhaigh do chiseán'
      },
      {
        default: 'Empty cart'
      }
    ],
    continue_shopping: [
      {
        locale: 'en-GB',
        value: 'Continue shopping'
      },
      {
        locale: 'cy-CY',
        value: 'Parhewch i siopa'
      },
      {
        locale: 'fr-FR',
        value: 'Continuer mes achats'
      },
      {
        locale: 'ga-IE',
        value: 'Lean ort ag siopadóireacht'
      },
      {
        default: 'Continue shopping'
      }
    ],
    checkout: [
      {
        locale: 'en-GB',
        value: 'Checkout'
      },
      {
        locale: 'cy-CY',
        value: 'Man Talu'
      },
      {
        locale: 'fr-FR',
        value: 'Paiement'
      },
      {
        locale: 'ga-IE',
        value: 'Seiceáil Amach'
      },
      {
        default: 'Checkout'
      }
    ],
    item: {
      one: [
        {
          locale: 'en-GB',
          value: 'item'
        },
        {
          locale: 'cy-CY',
          value: 'eitem'
        },
        {
          locale: 'fr-FR',
          value: 'article'
        },
        {
          locale: 'ga-IE',
          value: 'mír'
        },
        {
          default: 'item'
        }
      ],
      one_title: [
        {
          locale: 'en-GB',
          value: 'Item'
        },
        {
          locale: 'cy-CY',
          value: 'Eitem'
        },
        {
          locale: 'fr-FR',
          value: 'article'
        },
        {
          locale: 'ga-IE',
          value: 'Mír'
        },
        {
          default: 'Item'
        }
      ],
      other: [
        {
          locale: 'en-GB',
          value: 'items'
        },
        {
          locale: 'cy-CY',
          value: 'Eitemau'
        },
        {
          locale: 'fr-FR',
          value: 'articles'
        },
        {
          locale: 'ga-IE',
          value: 'míreanna'
        },
        {
          default: 'items'
        }
      ]
    },
    item_added: [
      {
        locale: 'en-GB',
        value: 'Your ticket was added to the cart'
      },
      {
        locale: 'cy-CY',
        value: "Mae'ch tocyn wedi ei ychwanegu at eich cert"
      },
      {
        locale: 'fr-FR',
        value: 'Votre billet a été ajouté au panier'
      },
      {
        locale: 'ga-IE',
        value: 'Cuireadh do thicéad sa chiseán.'
      },
      {
        default: 'Your ticket was added to the cart'
      }
    ],
    item_updated: [
      {
        locale: 'en-GB',
        value: 'Your item has been updated.'
      },
      {
        locale: 'cy-CY',
        value: "Mae'ch eitem wedi ei ddiweddaru"
      },
      {
        locale: 'fr-FR',
        value: 'Votre article a été mis à jour.'
      },
      {
        locale: 'ga-IE',
        value: 'Nuashonraíodh do thicéad sa chiseán.'
      },
      {
        default: 'Your item has been updated.'
      }
    ],
    item_removed: [
      {
        locale: 'en-GB',
        value: 'Your ticket has been removed from the cart'
      },
      {
        locale: 'cy-CY',
        value: "Mae'ch tocynnau wedi cael ei tynnu o'r cert"
      },
      {
        locale: 'fr-FR',
        value: 'Votre billet a été retiré du panier'
      },
      {
        locale: 'ga-IE',
        value: 'Baineadh do thicéad den chiseán.'
      },
      {
        default: 'Your ticket has been removed from the cart'
      }
    ],
    item_expired: [
      {
        locale: 'en-GB',
        value:
          'Some items from your cart have been lost because they have expired'
      },
      {
        locale: 'cy-CY',
        value:
          "Mae rhai eitemau o eich cert wedi cael eu colli oherwydd mae'r amser wedi dod i ben"
      },
      {
        locale: 'fr-FR',
        value:
          'Certains articles de votre panier ont disparu parce qu’ils ont expiré'
      },
      {
        locale: 'ga-IE',
        value:
          'Tá do chiseán imithe as feidhm. Roghnaigh do thicéid arís agus lean ort go dtí lánseiceáil'
      },
      {
        default:
          'Some items from your cart have been lost because they have expired'
      }
    ],
    delivery_method_set: [
      {
        locale: 'en-GB',
        value: 'Delivery method set to %{name}'
      },
      {
        locale: 'cy-CY',
        value: 'Dull cyflenwi wedi osod i %{name}'
      },
      {
        locale: 'fr-FR',
        value: 'Mode de livraison %{name}'
      },
      {
        locale: 'ga-IE',
        value: 'Modh bailiúcháin socraithe %{name}'
      },
      {
        default: 'Delivery method set to %{name}'
      }
    ],
    your_cart: [
      {
        locale: 'en-GB',
        value: 'Your cart'
      },
      {
        locale: 'cy-CY',
        value: 'Eich cert'
      },
      {
        locale: 'fr-FR',
        value: 'Votre panier'
      },
      {
        locale: 'ga-IE',
        value: 'Do chiseán'
      },
      {
        default: 'Your cart'
      }
    ],
    choose_delivery_method: [
      {
        locale: 'en-GB',
        value: 'Choose a delivery method'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswch eich dull cyflenwi'
      },
      {
        locale: 'fr-FR',
        value: 'Choisissez un mode de livraison'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaigh modh bailiúcháin'
      },
      {
        default: 'Choose a delivery method'
      }
    ],
    fulfillment_product_required: [
      {
        locale: 'en-GB',
        value: 'You need to select a delivery method'
      },
      {
        locale: 'cy-CY',
        value: 'Mae rhaid i chi dewis dull cyflenwi'
      },
      {
        locale: 'fr-FR',
        value: 'Vous devez sélectionner un mode de livraison'
      },
      {
        locale: 'ga-IE',
        value: 'Ní mór duit modh bailiúcháin a roghnú.'
      },
      {
        default: 'You need to select a delivery method'
      }
    ],
    discounted: [
      {
        locale: 'en-GB',
        value: 'discounted'
      },
      {
        locale: 'cy-CY',
        value: 'o ostyngiad'
      },
      {
        locale: 'fr-FR',
        value: 'en solde'
      },
      {
        locale: 'ga-IE',
        value: 'lascainithe'
      },
      {
        default: 'discounted'
      }
    ],
    hpp_blocked: [
      {
        locale: 'en-GB',
        value:
          'Your cart has expired. Please select your tickets again and proceed to checkout.'
      },
      {
        locale: 'cy-CY',
        value:
          "Mae eich fasged wedi wag. Dewiswch eich tocynnau eto ac ewch ymlaen i'r tudalen talu"
      },
      {
        locale: 'fr-FR',
        value:
          "Des articles dans votre panier sont sur le point d’expirer. Si vous' n’avez pas confirmé votre paiement, veuillez vérifier votre panier."
      },
      {
        locale: 'ga-IE',
        value:
          "Tá míreanna i do chiseán a bhfuil ar tí dul as feidhm. Muna bhfuil d'íocaíocht dearbhaithe, fill agus caith súil siar ar do chiseán."
      },
      {
        default:
          "There are items in your cart that are about to expire. If you haven't confirmed your payment, please return and review your cart."
      }
    ],
    ticket: {
      one: [
        {
          locale: 'en-GB',
          value: '1 Ticket for %{name}'
        },
        {
          locale: 'cy-CY',
          value: '1 tocyn am %{name}'
        },
        {
          locale: 'fr-FR',
          value: '1 billet pour %{name}'
        },
        {
          locale: 'ga-IE',
          value: '1 ticéad do %{name}'
        },
        {
          default: '1 Ticket for %{name}'
        }
      ],
      other: [
        {
          locale: 'en-GB',
          value: '%{count} Tickets for %{name}'
        },
        {
          locale: 'cy-CY',
          value: '%{count} tocynnau am %{name}'
        },
        {
          locale: 'fr-FR',
          value: '%{count} Billets pour %{name}'
        },
        {
          locale: 'ga-IE',
          value: '%{count} ticéid do %{name}'
        },
        {
          default: '%{count} Tickets for %{name}'
        }
      ]
    },
    charge: {
      one: [
        {
          locale: 'en-GB',
          value: '1 Charge'
        },
        {
          locale: 'cy-CY',
          value: '1 ffi'
        },
        {
          locale: 'fr-FR',
          value: '1 Tarif'
        },
        {
          locale: 'ga-IE',
          value: '1 Muirear'
        },
        {
          default: '1 Charge'
        }
      ],
      other: [
        {
          locale: 'en-GB',
          value: '%{count} Charges'
        },
        {
          locale: 'cy-CY',
          value: '%{count} ffi'
        },
        {
          locale: 'fr-FR',
          value: '%{count} Tarifs'
        },
        {
          locale: 'ga-IE',
          value: '%{count} Muirir'
        },
        {
          default: '%{count} Charges'
        }
      ]
    },
    return_to_browse: [
      {
        locale: 'en-GB',
        value: 'browse for more tickets'
      },
      {
        locale: 'cy-CY',
        value: 'Chwiliwch am fwy o docynnau'
      },
      {
        locale: 'fr-FR',
        value: 'Rechercher plus de billets'
      },
      {
        locale: 'ga-IE',
        value: 'Fill agus brabhsáil ar tuilleadh ticéad'
      },
      {
        default: 'browse for more tickets'
      }
    ],
    summary: [
      {
        locale: 'en-GB',
        value: 'Cart summary'
      },
      {
        locale: 'cy-CY',
        value: 'Crynodeb'
      },
      {
        locale: 'fr-FR',
        value: 'Récapitulatif de la commande'
      },
      {
        locale: 'ga-IE',
        value: 'Achoimre ciseán'
      },
      {
        default: 'Cart summary'
      }
    ],
    payment_summary: [
      {
        locale: 'en-GB',
        value: 'Payment summary'
      },
      {
        locale: 'cy-CY',
        value: 'Crynodeb talu'
      },
      {
        locale: 'fr-FR',
        value: 'Récapitulatif de paiement'
      },
      {
        locale: 'ga-IE',
        value: 'Achoimre íocaíochta'
      },
      {
        default: 'Payment summary'
      }
    ],
    edit: [
      {
        locale: 'en-GB',
        value: 'Edit cart'
      },
      {
        locale: 'cy-CY',
        value: "Golygu'r fasged"
      },
      {
        locale: 'fr-FR',
        value: 'Modifier le panier'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir in eagar a bhfuil sa chiseán'
      },
      {
        default: 'Edit cart'
      }
    ],
    confirmation_failed_title: [
      {
        locale: 'en-GB',
        value: 'Cart confirmation failed'
      },
      {
        locale: 'cy-CY',
        value: "Methwyd â chadarnhau'r fasged"
      },
      {
        locale: 'fr-FR',
        value: 'Cart confirmation failed'
      },
      {
        locale: 'ga-IE',
        value: 'Theip ar dheimhniú ciseáin'
      },
      {
        default: 'Cart confirmation failed'
      }
    ],
    confirmation_failed_message: [
      {
        locale: 'en-GB',
        value:
          'Please retry confirmation with link below. Otherwise contact the boxoffice with confirmation id %{uuid}'
      },
      {
        locale: 'cy-CY',
        value:
          'Please retry confirmation with link below. Otherwise contact the boxoffice with confirmation id %{uuid}'
      },
      {
        locale: 'fr-FR',
        value:
          'Please retry confirmation with link below. Otherwise contact the boxoffice with confirmation id %{uuid}'
      },
      {
        locale: 'ga-IE',
        value:
          'Déan iarracht athdheimhniú leis an nasc thíos. Seachas sin déan teagmhail le oifig na dticéad leis an ID deimhnithe'
      },
      {
        default:
          'Please retry confirmation with link below. Otherwise contact the boxoffice with confirmation id %{uuid}'
      }
    ],
    confirmation_retry: [
      {
        locale: 'en-GB',
        value: 'Retry confirmation'
      },
      {
        locale: 'cy-CY',
        value: 'Ceisiwch eto'
      },
      {
        locale: 'fr-FR',
        value: 'Retry confirmation'
      },
      {
        locale: 'ga-IE',
        value: 'Déan iarracht athdheimhniú'
      },
      {
        default: 'Retry confirmation'
      }
    ],
    has_split_seats: [
      {
        locale: 'en-GB',
        value:
          'The seats added to cart are not together. Please confirm only if you are happy to proceed.'
      },
      {
        locale: 'cy-CY',
        value:
          "Nid yw'r seddi yn eich fasged gyda'i gilydd. Cadarnhewch dim ond os ydych chi'n hapus i symud ymlaen."
      },
      {
        locale: 'fr-FR',
        value:
          'Dû à une limitation au niveau des disponibilités, l’attribution n’a pas pu se faire tous les sièges de façon à ce qu’ils soient l’un à côté des autres. Veuillez seulement confirmer si vous agréer.'
      },
      {
        locale: 'ga-IE',
        value:
          "Níl na suíocháin a chuir tú sa chiseán le chéile. Má tá tú sásta leanúint ar aghaidh, deimhnigh d'ordú."
      },
      {
        default:
          'The seats added to cart are not together. Please confirm only if you are happy to proceed.'
      }
    ]
  },
  gifting: {
    add_gift: [
      { locale: 'en-GB', value: 'Add as gift' },
      { locale: 'cy-CY', value: 'Ychwanegu fel anrheg' },
      { locale: 'ga-IE', value: 'Tabhair mar bhronntanas:' },
      { default: 'Add as gift' }
    ],
    badge: {
      text: [
        { locale: 'en-GB', value: 'Gift to:' },
        { locale: 'cy-CY', value: 'Rhodd i:' },
        { locale: 'ga-IE', value: 'Bronn ar:' },
        { default: 'Gift to:' }
      ]
    },
    form: {
      labels: {
        name: [
          { locale: 'en-GB', value: "Recipient's name" },
          { locale: 'cy-CY', value: "Enw'r derbynnydd" },
          { locale: 'ga-IE', value: 'Ainm an fhaighteora' },
          { default: "Recipient's name" }
        ],
        email: [
          { locale: 'en-GB', value: "Recipient's email" },
          { locale: 'cy-CY', value: 'E-bost y derbynnydd' },
          { locale: 'ga-IE', value: 'Ríomhphost an fhaighteora' },
          { default: "Recipient's email" }
        ],
        amount: [
          { locale: 'en-GB', value: 'Amount' },
          { locale: 'cy-CY', value: 'Swm' },
          { locale: 'ga-IE', value: 'Méid' },
          { default: 'Amount' }
        ],
        date: [
          { locale: 'en-GB', value: 'Delivery Date' },
          { locale: 'cy-CY', value: 'Dyddiad Cyflwyno' },
          { locale: 'ga-IE', value: 'Dáta seachadtha' },
          { default: 'Delivery Date' }
        ],
        message: [
          { locale: 'en-GB', value: 'Message' },
          { locale: 'cy-CY', value: 'Neges' },
          { locale: 'ga-IE', value: 'Teachtaireacht' },
          { default: 'Message' }
        ]
      },
      validations: {
        name: {
          required: [
            { locale: 'en-GB', value: 'Recipient name is required.' },
            { locale: 'cy-CY', value: "Mae angen enw'r derbynnydd" },
            { locale: 'ga-IE', value: 'Ainm an fhaighteora riachtanach' },
            { default: 'Recipient name is required.' }
          ]
        },
        email: {
          required: [
            { locale: 'en-GB', value: 'Recipient email is required.' },
            { locale: 'cy-CY', value: 'Mae angen e-bost y derbynnydd' },
            { locale: 'ga-IE', value: 'Ríomhphost an fhaighteora riachtanach' },
            { default: 'Recipient email is required.' }
          ]
        },
        amount: {
          required: [
            { locale: 'en-GB', value: 'Amount is required.' },
            { locale: 'cy-CY', value: 'Mae angen y swm' },
            { locale: 'ga-IE', value: 'Méid riachtanach' },
            { default: 'Amount is required.' }
          ]
        },
        date: {
          required: [
            { locale: 'en-GB', value: 'Delivery date is required.' },
            { locale: 'cy-CY', value: 'Méid riachtanach' },
            { locale: 'ga-IE', value: 'Dáta seachadtha riachtanach' },
            { default: 'Delivery date is required.' }
          ]
        }
      }
    }
  },
  checkout: {
    address: [
      {
        locale: 'en-GB',
        value: 'Address'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfeiriad'
      },
      {
        locale: 'fr-FR',
        value: 'Adresse'
      },
      {
        locale: 'ga-IE',
        value: 'Seoladh'
      },
      {
        default: 'Address'
      }
    ],
    address_line_1: [
      {
        locale: 'en-GB',
        value: 'Address Line 1'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfeiriad Llinell 1'
      },
      {
        locale: 'fr-FR',
        value: 'Adresse ligne 1'
      },
      {
        locale: 'ga-IE',
        value: 'Seoladh Líne 1'
      },
      {
        default: 'Address Line 1'
      }
    ],
    address_line_2: [
      {
        locale: 'en-GB',
        value: 'Address Line 2'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfeiriad Llinell 2'
      },
      {
        locale: 'fr-FR',
        value: 'Adresse ligne 2'
      },
      {
        locale: 'ga-IE',
        value: 'Seoladh Líne 2'
      },
      {
        default: 'Address Line 2'
      }
    ],
    back_to_credit_card_form: [
      {
        locale: 'en-GB',
        value: 'Back to credit cart payment form'
      },
      {
        locale: 'cy-CY',
        value: "Nol i'r ffurflen talu"
      },
      {
        locale: 'fr-FR',
        value: 'Retour au formulaire de paiement par carte bancaire'
      },
      {
        locale: 'ga-IE',
        value: 'Ar ais chuig foirm íocaíochta cárta creidmheasa'
      },
      {
        default: 'Back to credit cart payment form'
      }
    ],
    card_number: [
      {
        locale: 'en-GB',
        value: 'Card Number'
      },
      {
        locale: 'cy-CY',
        value: 'Rhif y cerdyn'
      },
      {
        locale: 'fr-FR',
        value: 'Numéro de carte'
      },
      {
        locale: 'ga-IE',
        value: 'Uimhir Chárta'
      },
      {
        default: 'Card Number'
      }
    ],
    cardholder_name: [
      {
        locale: 'en-GB',
        value: 'Cardholder Name'
      },
      {
        locale: 'cy-CY',
        value: 'Enw perchennog y cerdyn'
      },
      {
        locale: 'fr-FR',
        value: 'Nom du titulaire de la carte'
      },
      {
        locale: 'ga-IE',
        value: 'Ainm Shealbhóir an Chárta'
      },
      {
        default: 'Cardholder Name'
      }
    ],
    city_town: [
      {
        locale: 'en-GB',
        value: 'City/Town'
      },
      {
        locale: 'cy-CY',
        value: 'Dinas/Tref'
      },
      {
        locale: 'fr-FR',
        value: 'Ville'
      },
      {
        locale: 'ga-IE',
        value: 'Cathair/Baile'
      },
      {
        default: 'City/Town'
      }
    ],
    ccv_security_code: [
      {
        locale: 'en-GB',
        value: 'CCV Security Code'
      },
      {
        locale: 'cy-CY',
        value: 'Cod diogelwch CCV'
      },
      {
        locale: 'fr-FR',
        value: 'Code de sécurité CCV'
      },
      {
        locale: 'ga-IE',
        value: 'Cód Slándála CVV'
      },
      {
        default: 'CCV Security Code'
      }
    ],
    confirm_payment: [
      {
        locale: 'en-GB',
        value: 'Confirm payment'
      },
      {
        locale: 'cy-CY',
        value: 'Cadarnhau Taliad'
      },
      {
        locale: 'fr-FR',
        value: 'Confirmer le paiement'
      },
      {
        locale: 'ga-IE',
        value: "Dearbhaigh d'ordú"
      },
      {
        default: 'Confirm payment'
      }
    ],
    contact_name: [
      {
        locale: 'en-GB',
        value: 'Contact name'
      },
      {
        locale: 'cy-CY',
        value: 'Enw Cyswllt'
      },
      {
        locale: 'fr-FR',
        value: 'Nom du contact'
      },
      {
        locale: 'ga-IE',
        value: 'Ainm teagmhála'
      },
      {
        default: 'Contact name'
      }
    ],
    county: [
      {
        locale: 'en-GB',
        value: 'County/Region'
      },
      {
        locale: 'cy-CY',
        value: 'Sir'
      },
      {
        locale: 'fr-FR',
        value: 'Canton/région'
      },
      {
        locale: 'ga-IE',
        value: 'Contae/Ceantar'
      },
      {
        default: 'County/Region'
      }
    ],
    country: [
      {
        locale: 'en-GB',
        value: 'Country'
      },
      {
        locale: 'cy-CY',
        value: 'Gwlad'
      },
      {
        locale: 'fr-FR',
        value: 'Pays'
      },
      {
        locale: 'ga-IE',
        value: 'Tír'
      },
      {
        default: 'Country'
      }
    ],
    customer_name: [
      {
        locale: 'en-GB',
        value: 'Name'
      },
      {
        locale: 'cy-CY',
        value: 'Enw'
      },
      {
        locale: 'fr-FR',
        value: 'Nom'
      },
      {
        locale: 'ga-IE',
        value: 'Ainm'
      },
      {
        default: 'Name'
      }
    ],
    date_of_birth: [
      {
        locale: 'en-GB',
        value: 'Date of birth'
      },
      {
        locale: 'cy-CY',
        value: 'Dyddiad Geni'
      },
      {
        locale: 'fr-FR',
        value: 'Date de naissance'
      },
      {
        locale: 'ga-IE',
        value: 'Dáta breithe'
      },
      {
        default: 'Date of birth'
      }
    ],
    donation_price_override: [
      {
        locale: 'en-GB',
        value: 'Your amount'
      },
      {
        locale: 'cy-CY',
        value: 'Eich swm'
      },
      {
        locale: 'fr-FR',
        value: 'Montant'
      },
      {
        locale: 'ga-IE',
        value: 'Méid do thabhartais'
      },
      {
        default: 'Your amount'
      }
    ],
    email: [
      {
        locale: 'en-GB',
        value: 'Email'
      },
      {
        locale: 'cy-CY',
        value: 'E-bost'
      },
      {
        locale: 'fr-FR',
        value: 'Email'
      },
      {
        locale: 'ga-IE',
        value: 'Ríomhphost'
      },
      {
        default: 'Email'
      }
    ],
    expiration_date: [
      {
        locale: 'en-GB',
        value: 'Expiration Date'
      },
      {
        locale: 'cy-CY',
        value: 'Dyddiad terfyn'
      },
      {
        locale: 'fr-FR',
        value: 'Date d’expiration'
      },
      {
        locale: 'ga-IE',
        value: 'Dáta as Feidhm'
      },
      {
        default: 'Expiration Date'
      }
    ],
    expiration_date_sep: [
      {
        locale: 'en-GB',
        value: '/'
      },
      {
        locale: 'cy-CY',
        value: '/'
      },
      {
        locale: 'fr-FR',
        value: '/'
      },
      {
        locale: 'ga-IE',
        value: '/'
      },
      {
        default: '/'
      }
    ],
    first_name: [
      {
        locale: 'en-GB',
        value: 'First name'
      },
      {
        locale: 'cy-CY',
        value: 'Enw Cyntaf'
      },
      {
        locale: 'fr-FR',
        value: 'Prénom'
      },
      {
        locale: 'ga-IE',
        value: 'Céadainm'
      },
      {
        default: 'First name'
      }
    ],
    have_account: [
      {
        locale: 'en-GB',
        value: 'Already have an account? Login'
      },
      {
        locale: 'cy-CY',
        value: 'Oes cyfrif gennych? Mewngofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Vous possédez déjà un compte ? Connexion'
      },
      {
        locale: 'ga-IE',
        value: 'An bhfuil cuntas agat cheana féin? Logáil isteach'
      },
      {
        default: 'Already have an account? Login'
      }
    ],
    click_here_to_login: [
      {
        locale: 'en-GB',
        value: 'Click here to login'
      },
      {
        locale: 'cy-CY',
        value: 'Click here to login CY'
      },
      {
        locale: 'fr-FR',
        value: 'Cliquez ici pour vous connecter'
      },
      {
        locale: 'ga-IE',
        value: 'Cliceáil anseo chun logáil isteach'
      },
      {
        default: 'Click here to login'
      }
    ],
    login_instead: [
      {
        locale: 'en-GB',
        value: 'Login instead'
      },
      {
        locale: 'cy-CY',
        value: 'Mewngofnodwch yn lle'
      },
      {
        locale: 'fr-FR',
        value: 'Se connecter'
      },
      {
        locale: 'ga-IE',
        value: 'Logáil isteach ina ionad sin'
      },
      {
        default: 'Login instead'
      }
    ],
    please_enter_valid_email: [
      {
        locale: 'en-GB',
        value: 'Please enter a valid email address'
      },
      {
        locale: 'cy-CY',
        value: 'Rhowch gyfeiriad e-bost dilys'
      },
      {
        locale: 'fr-FR',
        value: 'Veuillez entrer une adresse email valide'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir isteach ríomhphost bailí'
      },
      {
        default: 'Please enter a valid email address'
      }
    ],
    how_did_you_hear_about_us: [
      {
        locale: 'en-GB',
        value: 'How did you hear about us?'
      },
      {
        locale: 'cy-CY',
        value: 'Sut y gwnaethoch glywed amdanom?'
      },
      {
        locale: 'fr-FR',
        value: 'Comment avez-vous entendu parler de nous ?'
      },
      {
        locale: 'ga-IE',
        value: 'Cár chuala tú fúinn?'
      },
      {
        default: 'How did you hear about us?'
      }
    ],
    expiration_date_month: [
      {
        locale: 'en-GB',
        value: 'Expiration Date (Month)'
      },
      {
        locale: 'cy-CY',
        value: 'Dyddiad Terfyn (Mis)'
      },
      {
        locale: 'fr-FR',
        value: 'Date d’expiration (mois)'
      },
      {
        locale: 'ga-IE',
        value: 'Dáta éaga'
      },
      {
        default: 'Expiration Date (Month)'
      }
    ],
    expiration_date_year: [
      {
        locale: 'en-GB',
        value: 'Expiration Date (Year)'
      },
      {
        locale: 'cy-CY',
        value: 'Dyddiad Terfyn (Blwyddyn)'
      },
      {
        locale: 'fr-FR',
        value: 'Date d’expiration (année)'
      },
      {
        locale: 'ga-IE',
        value: 'Bliain éaga'
      },
      {
        default: 'Expiration Date (Year)'
      }
    ],
    last_name: [
      {
        locale: 'en-GB',
        value: 'Last name'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfenw'
      },
      {
        locale: 'fr-FR',
        value: 'Nom de famille'
      },
      {
        locale: 'ga-IE',
        value: 'Sloinne'
      },
      {
        default: 'Last name'
      }
    ],
    login: [
      {
        locale: 'en-GB',
        value: 'Login'
      },
      {
        locale: 'cy-CY',
        value: 'Mwengofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Identifiant'
      },
      {
        locale: 'ga-IE',
        value: 'Logáil isteach'
      },
      {
        default: 'Login'
      }
    ],
    mobile: [
      {
        locale: 'en-GB',
        value: 'Mobile'
      },
      {
        locale: 'cy-CY',
        value: 'Ffôn symudol'
      },
      {
        locale: 'fr-FR',
        value: 'Mobile'
      },
      {
        locale: 'ga-IE',
        value: 'Fón Póca'
      },
      {
        default: 'Mobile'
      }
    ],
    invalid_phone_number: [
      {
        locale: 'en-GB',
        value: 'Invalid phone number pattern'
      },
      {
        locale: 'cy-CY',
        value: 'Patrwm rhif ffôn annily'
      },
      {
        locale: 'fr-FR',
        value: 'Modèle de numéro de téléphone invalid'
      },
      {
        locale: 'ga-IE',
        value: 'Patrún uimhir ghutháin neamhbhail'
      },
      {
        default: 'Invalid phone number pattern'
      }
    ],
    country_code: [
      {
        locale: 'en-GB',
        value: 'Calling code'
      },
      {
        locale: 'cy-CY',
        value: 'Côd gwlad'
      },
      {
        locale: 'fr-FR',
        value: 'Code pays'
      },
      {
        locale: 'ga-IE',
        value: 'Cód tíre'
      },
      {
        default: 'Calling code'
      }
    ],
    no_account: [
      {
        locale: 'en-GB',
        value: 'Register for an account'
      },
      {
        locale: 'cy-CY',
        value: 'Cofrestru am cyfrif'
      },
      {
        locale: 'fr-FR',
        value: 'Créer un compte'
      },
      {
        locale: 'ga-IE',
        value: 'Cláraigh chun cuntas a chruthú'
      },
      {
        default: 'Register for an account'
      }
    ],
    option: [
      {
        locale: 'en-GB',
        value: 'Option'
      },
      {
        locale: 'cy-CY',
        value: 'Opsiwn'
      },
      {
        locale: 'fr-FR',
        value: ''
      },
      {
        locale: 'ga-IE',
        value: 'Rogha'
      },
      {
        default: 'Option'
      }
    ],
    opt_in_3rd_party: [
      {
        locale: 'en-GB',
        value: 'Receive updates from partners?'
      },
      {
        locale: 'cy-CY',
        value: "Derbyn gwybodaeth o'n partneriaid?"
      },
      {
        locale: 'fr-FR',
        value:
          'Vous souhaitez recevoir des alertes de la part de nos partenaires ?'
      },
      {
        locale: 'ga-IE',
        value: 'Faigh an nuacht is déanaí ó chomhpháirtithe?'
      },
      {
        default: 'Receive updates from partners?'
      }
    ],
    opt_in_mail: [
      {
        locale: 'en-GB',
        value: 'Receive updates in the mail?'
      },
      {
        locale: 'cy-CY',
        value: 'Derbyn gwybodaeth yn y post?'
      },
      {
        locale: 'fr-FR',
        value: 'Vous souhaitez recevoir des alertes par mail ?'
      },
      {
        locale: 'ga-IE',
        value: 'Faigh an nuacht is déanaí sa phost?'
      },
      {
        default: 'Receive updates in the mail?'
      }
    ],
    opt_in_sms: [
      {
        locale: 'en-GB',
        value: 'Receive updates via SMS?'
      },
      {
        locale: 'cy-CY',
        value: 'Derbyn gwybodaeth trwy SMS?'
      },
      {
        locale: 'fr-FR',
        value: 'Vous souhaitez recevoir des alertes par SMS ?'
      },
      {
        locale: 'ga-IE',
        value: 'Faigh an nuacht is déanaí i bhfoirm SMS?'
      },
      {
        default: 'Receive updates via SMS?'
      }
    ],
    ordering_for_a_company: [
      {
        locale: 'en-GB',
        value: 'ordering for a company'
      },
      {
        locale: 'cy-CY',
        value: 'archebu ar gyfer cwmni'
      },
      {
        locale: 'fr-FR',
        value: 'vous passez votre commande pour une entreprise'
      },
      {
        locale: 'ga-IE',
        value: 'ag ordú do chomhlacht'
      },
      {
        default: 'ordering for a company'
      }
    ],
    other_region: [
      {
        locale: 'en-GB',
        value: 'other region'
      },
      {
        locale: 'cy-CY',
        value: 'ranbarth arall'
      },
      {
        locale: 'fr-FR',
        value: 'autre région'
      },
      {
        locale: 'ga-IE',
        value: 'réigiún eile'
      },
      {
        default: 'other region'
      }
    ],
    pay_with_rewards: [
      {
        locale: 'en-GB',
        value: 'Use Rewards Balance'
      },
      {
        locale: 'cy-CY',
        value: 'Defnyddiwch eich pwntiau'
      },
      {
        locale: 'fr-FR',
        value: 'Utiliser vos récompenses'
      },
      {
        locale: 'ga-IE',
        value: 'Bain Úsáid as Iarmhéid Duaiseanna'
      },
      {
        default: 'Use Rewards Balance'
      }
    ],
    pay_with_rewards_preview: [
      {
        locale: 'en-GB',
        value: 'Use Rewards Balance (%{amount})'
      },
      {
        locale: 'cy-CY',
        value: 'Defnyddiwch eich pwntiau (%{amount})'
      },
      {
        locale: 'fr-FR',
        value: 'Utiliser vos récompenses (%{amount})'
      },
      {
        locale: 'ga-IE',
        value: 'Úsáid iarmhéid duaise (%{amount})'
      },
      {
        default: 'Use Rewards Balance (%{amount})'
      }
    ],
    rewards_balance: [
      {
        locale: 'en-GB',
        value: 'You have %{amount} available from your membership rewards.'
      },
      {
        locale: 'cy-CY',
        value: "Mae gennych chi %{amount} ar gael o'ch gwobr aelodaeth."
      },
      {
        locale: 'ga-IE',
        value: 'Tá %{amount} de do chuid duaiseanna ballraíochta ar fail'
      },
      {
        default: 'Use Rewards Balance'
      }
    ],
    dont_use_rewards_balance: [
      {
        locale: 'en-GB',
        value: "Don't use my rewards"
      },
      {
        locale: 'cy-CY',
        value: 'Peidiwch â Defnyddio fy ngwobrau'
      },
      {
        locale: 'ga-IE',
        value: 'Ná húsáid iarmhéid duaise'
      },
      {
        default: "Don't use my rewards"
      }
    ],
    use_rewards_balance: [
      {
        locale: 'en-GB',
        value:
          'Your current rewards balance is %{amount}. Do you want to use it for this order?'
      },
      {
        locale: 'cy-CY',
        value:
          'Mae gennych %{amount} o gwobrau aelodaeth. Ydych chi am defnyddio rhain am yr archeb hyn?'
      },
      {
        locale: 'ga-IE',
        value:
          "%{amount} is ea d'iarmhéid duaise faoi láthair. Ar mhaith leat é a úsáid don ordú seo?"
      },
      {
        default:
          'Your current rewards balance is %{amount}. Do you want to use it for this order?'
      }
    ],
    rewards_balance_used: [
      {
        locale: 'en-GB',
        value:
          'You are using %{amount} out of your %{remaining} membership rewards.'
      },
      {
        locale: 'cy-CY',
        value:
          "Rydych chi'n defnyddio %{amount} allan o'ch %{remaining} o wobrau aelodaeth."
      },
      {
        locale: 'ga-IE',
        value: 'Tá tú ag úsáid 100 as 200 de do chuid duaiseanna ballraíochta'
      },
      {
        default:
          'You are using %{amount} out of your %{remaining} membership rewards.'
      }
    ],
    payment_method_cc: [
      {
        locale: 'en-GB',
        value: 'Pay with card'
      },
      {
        locale: 'cy-CY',
        value: 'Talwch gyda cerdyn'
      },
      {
        locale: 'fr-FR',
        value: 'Payer par carte'
      },
      {
        locale: 'ga-IE',
        value: 'Íoc le cárta'
      },
      {
        default: 'Pay with card'
      }
    ],
    payment_method_voucher: [
      {
        locale: 'en-GB',
        value: 'Pay with voucher code'
      },
      {
        locale: 'cy-CY',
        value: 'Talwch gyda cod taleb'
      },
      {
        locale: 'fr-FR',
        value: 'Payer avec un code promotionnel'
      },
      {
        locale: 'ga-IE',
        value: 'Íoc le cód dearbhán'
      },
      {
        default: 'Pay with voucher code'
      }
    ],
    payment_method_voucher_logged_in: [
      {
        locale: 'en-GB',
        value: 'Pay with voucher'
      },
      {
        locale: 'cy-CY',
        value: 'Talwch gyda taleb'
      },
      {
        locale: 'fr-FR',
        value: 'Payer avec un chèque cadeau'
      },
      {
        locale: 'ga-IE',
        value: 'Íoc le dearbhán'
      },
      {
        default: 'Pay with voucher'
      }
    ],
    voucher_not_found: [
      {
        locale: 'en-GB',
        value: 'Voucher not found'
      },
      {
        locale: 'cy-CY',
        value: 'Ni ddarganfuwyd y daleb'
      },
      {
        locale: 'ga-IE',
        value: 'Ar an drochuair níl fáil ar do dhearbhán'
      },
      {
        default: 'Voucher not found'
      }
    ],
    payment_fulfilled: [
      {
        locale: 'en-GB',
        value: 'You have already paid the order total amount.'
      },
      {
        locale: 'cy-CY',
        value: 'Rydych chi eisoes wedi talu.'
      },
      {
        locale: 'ga-IE',
        value: 'Tá an méid iomlán íoctha agat.'
      },
      {
        default: 'You have already paid the order total amount.'
      }
    ],
    order_thank_you: [
      {
        locale: 'en-GB',
        value: 'Thank you for placing your order'
      },
      {
        locale: 'cy-CY',
        value: 'Diolch am osod eich archeb'
      },
      {
        locale: 'ga-IE',
        value: "Míle buíochas as d'ordú"
      },
      {
        default: 'Thank you for placing your order'
      }
    ],
    return_to_queue: [
      {
        locale: 'en-GB',
        value:
          'As this is a high demand event you will now be placed back in the queue before you can make another order'
      },
      {
        locale: 'cy-CY',
        value:
          'Mae yna galw mawr am y ddigwyddiad hyn, byddwch nawr yn cael eich rhoi yn ôl yn y ciw cyn y gallwch wneud archeb arall'
      },
      {
        locale: 'ga-IE',
        value:
          'Os rud é go bhfuil an-éileamh ar an imeacht seo cuirfear ar ais sa scuaine thú sula gcruthaíonn tú ordú eile'
      },
      {
        default:
          'As this is a high demand event you will now be placed back in the queue before you can make another order'
      }
    ],
    phone: [
      {
        locale: 'en-GB',
        value: 'Phone'
      },
      {
        locale: 'cy-CY',
        value: 'Ffôn'
      },
      {
        locale: 'fr-FR',
        value: 'Téléphone'
      },
      {
        locale: 'ga-IE',
        value: 'Teileafón'
      },
      {
        default: 'Phone'
      }
    ],
    postcode_lookup: [
      {
        locale: 'en-GB',
        value: 'Address finder'
      },
      {
        locale: 'cy-CY',
        value: 'Dod o hyd i gyfeiriad'
      },
      {
        locale: 'fr-FR',
        value: `Chercheur d'adresse`
      },
      {
        locale: 'ga-IE',
        value: 'Aimsitheoir seolta'
      },
      {
        default: 'Address finder'
      }
    ],
    postcode_lookup_placeholder: [
      {
        locale: 'en-GB',
        value: 'Start typing an address...'
      },
      {
        locale: 'cy-CY',
        value: 'Dechreuwch deipio cyfeiriad'
      },
      {
        locale: 'ga-IE',
        value: 'Tosaigh ag clóscríobh seoladh'
      },
      {
        default: 'Start typing an address...'
      }
    ],
    postcode: [
      {
        locale: 'en-GB',
        value: 'Postcode'
      },
      {
        locale: 'cy-CY',
        value: 'Cod Post'
      },
      {
        locale: 'fr-FR',
        value: 'Code postal'
      },
      {
        locale: 'ga-IE',
        value: 'Cód Poist'
      },
      {
        default: 'Postcode'
      }
    ],
    review_and_confirm: [
      {
        locale: 'en-GB',
        value: 'Review and confirm'
      },
      {
        locale: 'cy-CY',
        value: 'Adolygu a chadarnhau'
      },
      {
        locale: 'fr-FR',
        value: 'Vérifier et confirmer'
      },
      {
        locale: 'ga-IE',
        value: 'Athbhreithnigh agus deimhnigh'
      },
      {
        default: 'Review and confirm'
      }
    ],
    salutation: [
      {
        locale: 'en-GB',
        value: 'Salutation'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfarchiad'
      },
      {
        locale: 'fr-FR',
        value: 'Formule de politesse'
      },
      {
        locale: 'ga-IE',
        value: 'Beannú'
      },
      {
        default: 'Salutation'
      }
    ],
    i_agree: [
      {
        locale: 'en-GB',
        value: 'I agree to the'
      },
      {
        locale: 'cy-CY',
        value: 'Yr wyf yn cytuno â’r'
      },
      {
        locale: 'fr-FR',
        value: 'J’accepte les'
      },
      {
        locale: 'ga-IE',
        value: 'Aontaím'
      },
      {
        default: 'I agree to the'
      }
    ],
    terms_and_conditions: [
      {
        locale: 'en-GB',
        value: 'Terms & Conditions'
      },
      {
        locale: 'cy-CY',
        value: 'Telerau ac Amodau'
      },
      {
        locale: 'fr-FR',
        value: 'conditions générales de vente'
      },
      {
        locale: 'ga-IE',
        value: 'Téarmaí agus Coinníollacha'
      },
      {
        default: 'Terms & Conditions'
      }
    ],
    payments: [
      {
        locale: 'en-GB',
        value: 'Payments'
      },
      {
        locale: 'cy-CY',
        value: 'Payments'
      },
      {
        locale: 'fr-FR',
        value: 'Paiements'
      },
      {
        locale: 'ga-IE',
        value: 'Íocaíochtaí'
      },
      {
        default: 'Payments'
      }
    ],
    voucher_code: [
      {
        locale: 'en-GB',
        value: 'Voucher Code'
      },
      {
        locale: 'cy-CY',
        value: 'Cod taleb'
      },
      {
        locale: 'fr-FR',
        value: 'Code promotionnel'
      },
      {
        locale: 'ga-IE',
        value: 'Cód Dearbháin'
      },
      {
        default: 'Voucher Code'
      }
    ],
    voucher_payment: [
      {
        locale: 'en-GB',
        value: 'Voucher Payment'
      },
      {
        locale: 'cy-CY',
        value: 'Taliad taleb'
      },
      {
        locale: 'fr-FR',
        value: 'Paiement par code promotionnel'
      },
      {
        locale: 'ga-IE',
        value: 'Íocaíocht Dhearbháin'
      },
      {
        default: 'Voucher Payment'
      }
    ],
    voucher_remaining: [
      {
        locale: 'en-GB',
        value: 'Remaining'
      },
      {
        locale: 'cy-CY',
        value: 'Yn weddill'
      },
      {
        locale: 'fr-FR',
        value: 'Restant'
      },
      {
        locale: 'ga-IE',
        value: 'Fágtha'
      },
      {
        default: 'Remaining'
      }
    ],
    billing_details: [
      {
        locale: 'en-GB',
        value: 'Billing Details'
      },
      {
        locale: 'cy-CY',
        value: 'Manylion Bilio'
      },
      {
        locale: 'fr-FR',
        value: 'Détails de la facturation'
      },
      {
        locale: 'ga-IE',
        value: 'Sonraí Billeála'
      },
      {
        default: 'Billing Details'
      }
    ],
    your_details: [
      {
        locale: 'en-GB',
        value: 'Your Details'
      },
      {
        locale: 'cy-CY',
        value: 'Eich manylion'
      },
      {
        locale: 'fr-FR',
        value: 'Vos coordonnées'
      },
      {
        locale: 'ga-IE',
        value: 'Do Shonraí'
      },
      {
        default: 'Your Details'
      }
    ],
    your_details_summary: [
      {
        locale: 'en-GB',
        value: 'Your details summary'
      },
      {
        locale: 'cy-CY',
        value: 'Crynodeb manylion'
      },
      {
        locale: 'fr-FR',
        value: 'Résumé de vos coordonnées'
      },
      {
        locale: 'ga-IE',
        value: 'Achoimre ar do Shonraí'
      },
      {
        default: 'Your details summary'
      }
    ],
    extra_info_summary: [
      {
        locale: 'en-GB',
        value: 'Extra info summary'
      },
      {
        locale: 'cy-CY',
        value: "Crynhoad o'r wybodaeth ychwanegol"
      },
      {
        locale: 'fr-FR',
        value: 'Résumé des informations supplémentaires'
      },
      {
        locale: 'ga-IE',
        value: 'Achoimre ar eolas breise'
      },
      {
        default: 'Extra info summary'
      }
    ],
    extra_info_summary_edit: [
      {
        locale: 'en-GB',
        value: 'Edit'
      },
      {
        locale: 'cy-CY',
        value: 'Golygu'
      },
      {
        locale: 'fr-FR',
        value: 'Modifier'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir in eagar'
      },
      {
        default: 'Edit'
      }
    ],
    steps: {
      cart: [
        {
          locale: 'en-GB',
          value: 'Cart'
        },
        {
          locale: 'cy-CY',
          value: 'Cert'
        },
        {
          locale: 'fr-FR',
          value: 'Panier'
        },
        {
          locale: 'ga-IE',
          value: 'Ciseán'
        },
        {
          default: 'Cart'
        }
      ],
      donations_prompt: [
        {
          locale: 'en-GB',
          value: 'Donations'
        },
        {
          locale: 'cy-CY',
          value: 'Rhodd'
        },
        {
          locale: 'fr-FR',
          value: 'Dons'
        },
        {
          locale: 'ga-IE',
          value: 'Tabhartais'
        },
        {
          default: 'Donations'
        }
      ],
      gift_aid_prompt: [
        {
          locale: 'en-GB',
          value: 'Gift Aid Preferences'
        },
        {
          locale: 'cy-CY',
          value: 'Dewisiadau Cymorth Rhodd'
        },
        {
          locale: 'fr-FR',
          value: 'Préférences d’Aide sous forme de Don'
        },
        {
          locale: 'ga-IE',
          value: 'Sainroghanna Cúnaimh Bronntanais'
        },
        {
          default: 'Gift Aid Preferences'
        }
      ],
      product_prompt: [
        {
          locale: 'en-GB',
          value: 'Suggestions'
        },
        {
          locale: 'cy-CY',
          value: 'Awgrymiadau'
        },
        {
          locale: 'fr-FR',
          value: 'Suggestions'
        },
        {
          locale: 'ga-IE',
          value: 'Moltaí'
        },
        {
          default: 'Suggestions'
        }
      ],
      extras_prompt: [
        {
          locale: 'en-GB',
          value: 'Extra Info'
        },
        {
          locale: 'cy-CY',
          value: 'Gwybodaeth Ychwanegol'
        },
        {
          locale: 'fr-FR',
          value: 'Informations supplémentaires'
        },
        {
          locale: 'ga-IE',
          value: 'Eolas Breise'
        },
        {
          default: 'Extra Info'
        }
      ],
      login_wall: [
        {
          locale: 'en-GB',
          value: 'Login'
        },
        {
          locale: 'cy-CY',
          value: 'Login'
        },
        {
          locale: 'fr-FR',
          value: 'Identifiant'
        },
        {
          locale: 'ga-IE',
          value: 'Logáil isteach'
        },
        {
          default: 'Login'
        }
      ],
      checkout: [
        {
          locale: 'en-GB',
          value: 'Billing Details'
        },
        {
          locale: 'cy-CY',
          value: 'Manylion Bilio'
        },
        {
          locale: 'fr-FR',
          value: 'Détails de la facturation'
        },
        {
          locale: 'ga-IE',
          value: 'Sonraí Billeála'
        },
        {
          default: 'Billing Details'
        }
      ],
      confirm_order: [
        {
          locale: 'en-GB',
          value: 'Payment'
        },
        {
          locale: 'cy-CY',
          value: 'Talu'
        },
        {
          locale: 'fr-FR',
          value: 'Paiement'
        },
        {
          locale: 'ga-IE',
          value: 'Íocaíocht'
        },
        {
          default: 'Payment'
        }
      ]
    },
    donation_prompt: [
      {
        locale: 'en-GB',
        value: "I'd like to donate:"
      },
      {
        locale: 'cy-CY',
        value: 'Hoffwn gyfrannu:'
      },
      {
        locale: 'fr-FR',
        value: "Je' souhaite faire un don :"
      },
      {
        locale: 'ga-IE',
        value: 'Ba mhaith liom síntiús a thabhairt'
      },
      {
        default: "I'd like to donate:"
      }
    ]
  },
  benefit: {
    email_placeholder: [
      {
        locale: 'en-GB',
        value: 'Gift Recipient Email'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfeiriad e-bost'
      },
      {
        locale: 'fr-FR',
        value: 'Email du bénéficiaire'
      },
      {
        locale: 'ga-IE',
        value: 'Ríomhphost faighteoir an bhronntanais'
      },
      {
        default: 'Gift Recipient Email'
      }
    ]
  },
  donations: {
    your_amount: [
      {
        locale: 'en-GB',
        value: 'Enter your amount here'
      },
      {
        locale: 'cy-CY',
        value: 'Ychwanegwch cyfraniad eich hun'
      },
      {
        locale: 'fr-FR',
        value: 'Indiquez le montant'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir isteach suim anseo'
      },
      {
        default: 'Enter your amount here'
      }
    ],
    your_amount_short: [
      {
        locale: 'en-GB',
        value: 'Your amount'
      },
      {
        locale: 'cy-CY',
        value: 'Eich swm'
      },
      {
        locale: 'fr-FR',
        value: 'Montant'
      },
      {
        locale: 'ga-IE',
        value: 'Suim'
      },
      {
        default: 'Your amount'
      }
    ]
  },
  event: {
    default_soldout_message: [
      {
        locale: 'en-GB',
        value: 'SOLD OUT'
      },
      {
        locale: 'cy-CY',
        value: 'WEDI GWETHRU ALLAN'
      },
      {
        locale: 'fr-FR',
        value: 'ÉPUISÉ'
      },
      {
        locale: 'ga-IE',
        value: 'TÁ AN T-IMEACHT DÍOLTA AMACH'
      },
      {
        default: 'SOLD OUT'
      }
    ]
  },
  events: {
    waiting_list_available: [
      {
        locale: 'en-GB',
        value: 'Waiting list available'
      },
      {
        locale: 'cy-CY',
        value: 'Rhestr aros ar gael'
      },
      {
        locale: 'fr-FR',
        value: 'Liste d’attente disponible'
      },
      {
        locale: 'ga-IE',
        value: 'Liosta feithimh ar fáil'
      },
      {
        default: 'Waiting list available'
      }
    ],
    waiting_list_available_index: [
      {
        locale: 'en-GB',
        value: 'Waiting list available for sold out times'
      },
      {
        locale: 'cy-CY',
        value: 'Rhestr aros ar gael am amserau sydd wedi gwerthu allan'
      },
      {
        locale: 'fr-FR',
        value: 'Liste d’attente disponible pour les dates à guichets fermés'
      },
      {
        locale: 'ga-IE',
        value: "Liosta feithimh ar fáil d'amanna díolta amach"
      },
      {
        default: 'Waiting list available for sold out times'
      }
    ],
    add_me_to_waiting_list: [
      {
        locale: 'en-GB',
        value: 'Add me to the waiting list'
      },
      {
        locale: 'cy-CY',
        value: 'Ychwanegwch fi at y rhestr aros'
      },
      {
        locale: 'fr-FR',
        value: 'Ajoutez-moi à la liste d’attente'
      },
      {
        locale: 'ga-IE',
        value: "Cuir m'ainm leis an liosta feithimh"
      },
      {
        default: 'Add me to the waiting list'
      }
    ],
    added_to_waiting_list: [
      {
        locale: 'en-GB',
        value: "Thanks! We've added you to the waiting list for this event"
      },
      {
        locale: 'cy-CY',
        value:
          "Diolch! Yr ydym wedi ychwanegu chi i'r rhestr aros am y sioe yma"
      },
      {
        locale: 'fr-FR',
        value:
          "Merci ! Nous' vous avons ajouté à la liste d’attente pour cet événement"
      },
      {
        locale: 'ga-IE',
        value:
          "Go raibh maith agat! Cuireadh d'ainm leis an liosta feithimh don imeacht seo"
      },
      {
        default: "Thanks! We've added you to the waiting list for this event"
      }
    ],
    already_registered: [
      {
        locale: 'en-GB',
        value: 'Already registered? Login instead'
      },
      {
        locale: 'cy-CY',
        value: 'Oes cyfrif gennych? Mewngofnodwch yn lle'
      },
      {
        locale: 'fr-FR',
        value: 'Déjà inscrit ? Se connecter'
      },
      {
        locale: 'ga-IE',
        value: 'Cláraithe cheana féin? Logáil isteach ina ionad sin'
      },
      {
        default: 'Already registered? Login instead'
      }
    ],
    available_for: [
      {
        locale: 'en-GB',
        value: 'Events available for'
      },
      {
        locale: 'cy-CY',
        value: 'Sioiau ar gael am'
      },
      {
        locale: 'fr-FR',
        value: 'Événements disponibles pour'
      },
      {
        locale: 'ga-IE',
        value: 'Imeachtaí ar fáil do'
      },
      {
        default: 'Events available for'
      }
    ],
    login_and_add_me: [
      {
        locale: 'en-GB',
        value: 'Login and join waiting list'
      },
      {
        locale: 'cy-CY',
        value: "Mewngofnodwch i ymuno a'r rhestr aros,"
      },
      {
        locale: 'fr-FR',
        value: 'Se connecter et rejoindre la liste d’attente'
      },
      {
        locale: 'ga-IE',
        value: "Logáil isteach agus cuir m'ainm leis an liosta feithimh"
      },
      {
        default: 'Login and join waiting list'
      }
    ],
    on_waiting_list: {
      one: [
        {
          locale: 'en-GB',
          value: 'You are on the waiting list (1 ticket requested)'
        },
        {
          locale: 'cy-CY',
          value: 'Yr ydych ar y rhestr aros (gofynwyd am 1 tocyn)'
        },
        {
          locale: 'fr-FR',
          value: 'Vous êtes sur la liste d’attente (1 billet demandé)'
        },
        {
          locale: 'ga-IE',
          value: "Tá d'ainm curtha leis an liosta feithimh (1 ticéad iarrtha)"
        },
        {
          default: 'You are on the waiting list (1 ticket requested)'
        }
      ],
      other: [
        {
          locale: 'en-GB',
          value: 'You are on the waiting list (%{count} tickets requested)'
        },
        {
          locale: 'cy-CY',
          value: 'Yr ydych ar y rhestr aros (gofynwyd am %{count} tocyn)'
        },
        {
          locale: 'fr-FR',
          value: 'Vous êtes sur la liste d’attente (%{count} billets demandés)'
        },
        {
          locale: 'ga-IE',
          value: "Tá d'ainm curtha leis an liosta feithimh (2 ticéad iarrtha)"
        },
        {
          default: 'You are on the waiting list (%{count} tickets requested)'
        }
      ]
    },
    register_and_add_me: [
      {
        locale: 'en-GB',
        value: 'Register and join waiting list'
      },
      {
        locale: 'cy-CY',
        value: 'Cofrestrwch a ymuno â rhestr aros'
      },
      {
        locale: 'fr-FR',
        value: 'S’inscrire et rejoindre la liste d’attente'
      },
      {
        locale: 'ga-IE',
        value:
          "Cláraigh thíos le d'ainm a chur leis an liosta feithimh don imeacht seo"
      },
      {
        default: 'Register and join waiting list'
      }
    ],
    register_instead: [
      {
        locale: 'en-GB',
        value: 'Register instead'
      },
      {
        locale: 'cy-CY',
        value: 'Cofrestrwch yn lle'
      },
      {
        locale: 'fr-FR',
        value: 'S’inscrire'
      },
      {
        locale: 'ga-IE',
        value: 'Cláraigh ina ionad sin'
      },
      {
        default: 'Register instead'
      }
    ],
    review_cart_and_checkout: [
      {
        locale: 'en-GB',
        value: 'Review Cart &amp; Checkout'
      },
      {
        locale: 'cy-CY',
        value: 'Adolygu eich cert ac yna ewch i talu'
      },
      {
        locale: 'fr-FR',
        value: 'Voir le panier et payer'
      },
      {
        locale: 'ga-IE',
        value: 'Súil siar ar Chiseán &amp; Seiceáil Amach'
      },
      {
        default: 'Review Cart &amp; Checkout'
      }
    ],
    sold_out: [
      {
        locale: 'en-GB',
        value: 'This event is sold out'
      },
      {
        locale: 'cy-CY',
        value: "Mae'r sioe yma wedi eu gwerthu allan"
      },
      {
        locale: 'fr-FR',
        value: 'Cet événement affiche complet'
      },
      {
        locale: 'ga-IE',
        value: 'Tá an t-imeacht díolta amach'
      },
      {
        default: 'This event is sold out'
      }
    ],
    sold_out_cross_sell: [
      {
        locale: 'en-GB',
        value: 'Check out these suggestions instead:'
      },
      {
        locale: 'cy-CY',
        value: 'Edrychwch ar y cynigion hyn yn lle:'
      },
      {
        locale: 'fr-FR',
        value: 'Découvrez ces suggestions à la place :'
      },
      {
        locale: 'ga-IE',
        value: 'Amharc ar na moltaí seo ina ionad:'
      },
      {
        default: 'Check out these suggestions instead:'
      }
    ],
    waiting_list_join: [
      {
        locale: 'en-GB',
        value: 'Join the waiting list?'
      },
      {
        locale: 'cy-CY',
        value: 'Ymunwch ar rhestr aros?'
      },
      {
        locale: 'fr-FR',
        value: 'Rejoindre la liste d’attente ?'
      },
      {
        locale: 'ga-IE',
        value: "Ar mhaith leat d'ainm a chur leis an liosta feithimh?"
      },
      {
        default: 'Join the waiting list?'
      }
    ],
    waiting_list_login: [
      {
        locale: 'en-GB',
        value: 'Login below to join the waiting list for this event'
      },
      {
        locale: 'cy-CY',
        value: "Mewngofnodwch isod i ymuno â'r rhestr aros am y sioe hwn"
      },
      {
        locale: 'fr-FR',
        value:
          'Connectez-vous ci-dessous pour rejoindre la liste d’attente pour cet événement'
      },
      {
        locale: 'ga-IE',
        value: "Logáil isteach agus cuir m'ainm leis an liosta feithimh"
      },
      {
        default: 'Login below to join the waiting list for this event'
      }
    ],
    waiting_list_register: [
      {
        locale: 'en-GB',
        value: 'Register below to join the waiting list for this event'
      },
      {
        locale: 'cy-CY',
        value: "Cofrestrwch isod i ymuno â'r rhestr aros am y sioe hwn"
      },
      {
        locale: 'fr-FR',
        value:
          'Inscrivez-vous ci-dessous pour rejoindre la liste d’attente pour cet événement'
      },
      {
        locale: 'ga-IE',
        value: "Cláraigh agus cuir m'ainm leis an liosta feithimh"
      },
      {
        default: 'Register below to join the waiting list for this event'
      }
    ],
    doors_open: [
      {
        locale: 'en-GB',
        value: 'Doors open'
      },
      {
        locale: 'cy-CY',
        value: 'Drysau yn agor'
      },
      {
        locale: 'fr-FR',
        value: 'Portes ouvertes'
      },
      {
        locale: 'ga-IE',
        value: 'Doirse ar oscailt'
      },
      {
        default: 'Doors open'
      }
    ],
    no_events_this_month: [
      {
        locale: 'en-GB',
        value: 'There are no events this month.'
      },
      {
        locale: 'cy-CY',
        value: 'Does dim sioeau mis hyn'
      },
      {
        locale: 'fr-FR',
        value: 'Il n’y a aucun événement ce mois-ci.'
      },
      {
        locale: 'ga-IE',
        value: 'Níl aon imeacht sceidealta don mhí seo'
      },
      {
        default: 'There are no events this month.'
      }
    ],
    no_events_current_day: [
      {
        locale: 'en-GB',
        value: 'No tickets available today'
      },
      {
        locale: 'cy-CY',
        value: 'Dim tocynnau ar gael heddiw'
      },
      {
        locale: 'fr-FR',
        value: "Aucun billet disponible aujourd'hui"
      },
      {
        locale: 'ga-IE',
        value: 'Níl ticéid ar fáil inniu'
      },
      {
        default: 'No tickets available today'
      }
    ],
    soldout_title: [
      {
        locale: 'en-GB',
        value: 'Event not on sale'
      },
      {
        locale: 'cy-CY',
        value: "Dydi'r sioe ddim ar gwerth"
      },
      {
        locale: 'fr-FR',
        value: 'Événement non disponible à la vente'
      },
      {
        locale: 'ga-IE',
        value: 'Níl imeacht ar díol'
      },
      {
        default: 'Event not on sale'
      }
    ],
    soldout_title_short: [
      {
        locale: 'en-GB',
        value: 'Not on sale'
      },
      {
        locale: 'cy-CY',
        value: 'Dim ar werth'
      },
      {
        locale: 'fr-FR',
        value: 'Non disponible à la vente'
      },
      {
        locale: 'ga-IE',
        value: 'Níl sé ar díol'
      },
      {
        default: 'Not on sale'
      }
    ],
    soldout_options_short: [
      {
        locale: 'en-GB',
        value: 'Options available'
      },
      {
        locale: 'cy-CY',
        value: 'Opsiynau eraill'
      },
      {
        locale: 'fr-FR',
        value: 'Options disponibles'
      },
      {
        locale: 'ga-IE',
        value: 'Roghanna ar fáil'
      },
      {
        default: 'Options available'
      }
    ],
    tickets_required: [
      {
        locale: 'en-GB',
        value: 'Number of Tickets required'
      },
      {
        locale: 'cy-CY',
        value: 'Nifer o tocynnau eu hangen'
      },
      {
        locale: 'fr-FR',
        value: 'Nombre de billets requis'
      },
      {
        locale: 'ga-IE',
        value: 'Líon Ticéad atá ag teastáil'
      },
      {
        default: 'Number of Tickets required'
      }
    ]
  },
  event_seats: {
    choose_zone: [
      {
        locale: 'en-GB',
        value: 'Select a zone'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswch ardal'
      },
      {
        locale: 'fr-FR',
        value: 'Sélectionnez une zone'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaigh zón eile'
      },
      {
        default: 'Select a zone'
      }
    ],
    choose_seats: [
      {
        locale: 'en-GB',
        value: 'Select your seats'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswch eich seddi'
      },
      {
        locale: 'fr-FR',
        value: 'Sélectionnez vos sièges'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaigh do Shuíocháin'
      },
      {
        default: 'Select your seats'
      }
    ],
    choose_seats_short: [
      {
        locale: 'en-GB',
        value: 'Choose seats'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswch seddi'
      },
      {
        locale: 'fr-FR',
        value: 'Choisir des sièges'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaigh suíocháin'
      },
      {
        default: 'Choose seats'
      }
    ],
    view_prices: [
      {
        locale: 'en-GB',
        value: 'View prices'
      },
      {
        locale: 'cy-CY',
        value: 'Golygfa pris'
      },
      {
        locale: 'fr-FR',
        value: 'Afficher les prix'
      },
      {
        locale: 'ga-IE',
        value: 'Féach ar phraghsanna'
      },
      {
        default: 'View prices'
      }
    ],
    prices_header: [
      {
        locale: 'en-GB',
        value: 'Ticket Prices'
      },
      {
        locale: 'cy-CY',
        value: 'Prisiau tocynnau'
      },
      {
        locale: 'fr-FR',
        value: 'Prix des billets'
      },
      {
        locale: 'ga-IE',
        value: 'Praghsanna ticéad'
      },
      {
        default: 'Ticket Prices'
      }
    ],
    choose_prices_for: [
      {
        locale: 'en-GB',
        value: 'Select prices for'
      },
      {
        locale: 'cy-CY',
        value: 'Dewisiwch prisiau'
      },
      {
        locale: 'fr-FR',
        value: 'Sélectionner les prix pour'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaigh praghasanna do'
      },
      {
        default: 'Select prices for'
      }
    ],
    errors: {
      single_seats: [
        {
          locale: 'en-GB',
          value: 'Your selection leaves an empty seat'
        },
        {
          locale: 'cy-CY',
          value: 'Mae eich dewis yn gadael sedd wag'
        },
        {
          locale: 'fr-FR',
          value: 'Votre sélection laisse un siège vide'
        },
        {
          locale: 'ga-IE',
          value: 'Fágann do roghnúchán suíochán leis féin. Beidh ort'
        },
        {
          default: 'Your selection leaves an empty seat'
        }
      ],
      insufficient_group_tickets: [
        {
          locale: 'en-GB',
          value: 'Insufficient group tickets selected'
        },
        {
          locale: 'cy-CY',
          value: "Mae angen dewis fwy o seddi i cymryd mantais o'r pris grwp"
        },
        {
          locale: 'fr-FR',
          value: 'Nombre de billets de groupe sélectionnés insuffisant'
        },
        {
          locale: 'ga-IE',
          value: 'Níl go leor grúp-thicéid roghnaithe'
        },
        {
          default: 'Insufficient group tickets selected'
        }
      ]
    },
    statuses: {
      blocked: [
        {
          locale: 'en-GB',
          value: 'Unavailable'
        },
        {
          locale: 'cy-CY',
          value: 'Ddim ar gael'
        },
        {
          locale: 'fr-FR',
          value: 'Non disponible'
        },
        {
          locale: 'ga-IE',
          value: 'Níl fáil air'
        },
        {
          default: 'Unavailable'
        }
      ],
      selected: [
        {
          locale: 'en-GB',
          value: 'Selected'
        },
        {
          locale: 'cy-CY',
          value: 'Wedi ei ddewis'
        },
        {
          locale: 'fr-FR',
          value: 'Sélectionné'
        },
        {
          locale: 'ga-IE',
          value: 'Roghnaithe'
        },
        {
          default: 'Selected'
        }
      ],
      in_cart: [
        {
          locale: 'en-GB',
          value: 'In cart'
        },
        {
          locale: 'cy-CY',
          value: 'Yn y cert'
        },
        {
          locale: 'fr-FR',
          value: 'Dans le panier'
        },
        {
          locale: 'ga-IE',
          value: 'I gciseán'
        },
        {
          default: 'In cart'
        }
      ]
    },
    use_two_fingers: [
      {
        locale: 'en-GB',
        value: 'Use 2 fingers to move/zoom seat map'
      },
      {
        locale: 'cy-CY',
        value: 'Defnyddiwch 2 fys i symud/chwyddo y map sedd'
      },
      {
        locale: 'fr-FR',
        value: 'Utilisez 2 doigts pour déplacer/zoomer le plan de cabine'
      },
      {
        locale: 'ga-IE',
        value:
          'úsáid dhá mhéar chun zúmáil ar an bplean suíochán nó é a bhogadh'
      },
      {
        default: 'Use 2 fingers to move/zoom seat map'
      }
    ]
  },
  member: {
    name: [
      {
        locale: 'en-GB',
        value: 'Name'
      },
      {
        locale: 'cy-CY',
        value: 'Enw'
      },
      {
        locale: 'fr-FR',
        value: 'Nom'
      },
      {
        locale: 'ga-IE',
        value: 'Ainm'
      },
      {
        default: 'Name'
      }
    ],
    phone: [
      {
        locale: 'en-GB',
        value: 'Phone'
      },
      {
        locale: 'cy-CY',
        value: 'Ffôn'
      },
      {
        locale: 'fr-FR',
        value: 'Téléphone'
      },
      {
        locale: 'ga-IE',
        value: 'Teileafón'
      },
      {
        default: 'Phone'
      }
    ],
    back_to_login: [
      {
        locale: 'en-GB',
        value: 'Back to login'
      },
      {
        locale: 'cy-CY',
        value: 'Yn ol i mewngofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Retour à l’écran de connexion'
      },
      {
        locale: 'ga-IE',
        value: 'Ar ais chuig logáil isteach'
      },
      {
        default: 'Back to login'
      }
    ],
    successfully_logged_in: [
      {
        locale: 'en-GB',
        value: 'Successfully logged in'
      },
      {
        locale: 'cy-CY',
        value: "Wedi mewngofnodi'n llwyddiannus"
      },
      {
        locale: 'ga-IE',
        value: "D'éirigh le logáil isteach"
      },
      {
        default: 'Successfully logged in'
      }
    ],
    successfully_registered: [
      {
        locale: 'en-GB',
        value: 'Successfully registered'
      },
      {
        locale: 'cy-CY',
        value: "Wedi cofrestru'n llwyddiannus"
      },
      {
        locale: 'ga-IE',
        value: "D'éirigh le clárú"
      },
      {
        default: 'Successfully registered'
      }
    ],
    password_updated: [
      {
        locale: 'en-GB',
        value: 'Password updated'
      },
      {
        locale: 'cy-CY',
        value: "Cyfrinair wedi'i ddiweddaru"
      },
      {
        locale: 'ga-IE',
        value: 'Pasfhocal uasdátaithe'
      },
      {
        default: 'Password updated'
      }
    ],
    email_plain: [
      {
        locale: 'en-GB',
        value: 'Email'
      },
      {
        locale: 'cy-CY',
        value: 'E-bost'
      },
      {
        locale: 'fr-FR',
        value: 'Email'
      },
      {
        locale: 'ga-IE',
        value: 'Ríomphoist'
      },
      {
        default: 'Email'
      }
    ],
    email: [
      {
        locale: 'en-GB',
        value: 'Your email address'
      },
      {
        locale: 'cy-CY',
        value: 'Eich e-bost'
      },
      {
        locale: 'fr-FR',
        value: 'Votre adresse email'
      },
      {
        locale: 'ga-IE',
        value: 'Do sheoladh ríomhphoist'
      },
      {
        default: 'Your email address'
      }
    ],
    email_confirm: [
      {
        locale: 'en-GB',
        value: 'Confirm your email address'
      },
      {
        locale: 'cy-CY',
        value: 'Cadarnhau eich e-bost'
      },
      {
        locale: 'fr-FR',
        value: 'Confirmez votre adresse email'
      },
      {
        locale: 'ga-IE',
        value: 'Dearbhaigh do sheoladh ríomphoist'
      },
      {
        default: 'Confirm your email address'
      }
    ],
    forgot_your_password: [
      {
        locale: 'en-GB',
        value: 'Forgot your password?'
      },
      {
        locale: 'cy-CY',
        value: 'Wedi anghofio eich cyfrinair?'
      },
      {
        locale: 'fr-FR',
        value: 'Vous avez oublié votre mot de passe ?'
      },
      {
        locale: 'ga-IE',
        value: 'An bhfuil dearmad déanta agat ar do phasfhocal?'
      },
      {
        default: 'Forgot your password?'
      }
    ],
    provide_password_mandatory: [
      {
        locale: 'en-GB',
        value: 'Please provide a password to create an account, or'
      },
      {
        locale: 'cy-CY',
        value: "Mae'n rhaid i chi dewis cyfrinair i greu gyfrif, neu"
      },
      {
        locale: 'fr-FR',
        value: 'Veuillez fournir un mot de passe pour créer un compte, ou'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir pasfhocal ar fáil le cuntas a chruthú, nó'
      },
      {
        default: 'Please provide a password to create an account, or'
      }
    ],
    provide_password_optional: [
      {
        locale: 'en-GB',
        value: 'You can provide a password to create an account, or'
      },
      {
        locale: 'cy-CY',
        value: 'Gallwch dewis cyfrinair i greu gyfrif, neu'
      },
      {
        locale: 'fr-FR',
        value: 'Vous pouvez fournir un mot de passe pour créer un compte, ou'
      },
      {
        locale: 'ga-IE',
        value: 'Is féidir pasfhocal a chur ar fáil le cuntas a chruthú, nó'
      },
      {
        default: 'You can provide a password to create an account, or'
      }
    ],
    password_strength: {
      tooltip: {
        title: [
          {
            locale: 'en-GB',
            value: 'A strong password requires:'
          },
          {
            locale: 'cy-CY',
            value: 'Mae angen cyfrinair cryf'
          },
          {
            locale: 'ga-IE',
            value: 'Éilíonn pasfhocal láidir'
          },
          { default: 'A strong password requires:' }
        ],
        capital_letters: [
          { locale: 'en-GB', value: 'A capital letter' },
          { locale: 'cy-CY', value: 'Prif llythyren' },
          { locale: 'ga-IE', value: 'Ceannlitir' },
          { default: 'A capital letter' }
        ],
        lowercase_letters: [
          { locale: 'en-GB', value: 'A lowercase letter' },
          { locale: 'cy-CY', value: 'Llythyren fach' },
          { locale: 'ga-IE', value: 'Litir cáis íochtair' },
          { default: 'A lowercase letter' }
        ],
        a_symbol: [
          { locale: 'en-GB', value: 'A symbol' },
          { locale: 'cy-CY', value: 'Symbol' },
          { locale: 'ga-IE', value: 'Siombail' },
          { default: 'A symbol' }
        ],
        a_number: [
          { locale: 'en-GB', value: 'A number' },
          { locale: 'cy-CY', value: 'Rhif' },
          { locale: 'ga-IE', value: 'Uimhir' },
          { default: 'A number' }
        ],
        char_count: [
          { locale: 'en-GB', value: 'Atleast 12 characters' },
          { locale: 'cy-CY', value: 'O leiaf 12 o gymeriadau' },
          { locale: 'ga-IE', value: '12 charachtar a laghad' },
          { default: 'Atleast 12 characters' }
        ]
      },
      indicator: {
        very_weak: [
          { locale: 'en-GB', value: 'Password strength: Very Weak' },
          { locale: 'cy-CY', value: 'Cryfder Cyfrinair: Gwan Iawn' },
          { locale: 'ga-IE', value: 'Neart Pasfhocal: An-lag' },
          { default: 'Password strength: Very Weak' }
        ],
        weak: [
          { locale: 'en-GB', value: 'Password strength: Weak' },
          { locale: 'cy-CY', value: 'Cryfder Cyfrinair: Gwan' },
          { locale: 'ga-IE', value: 'Neart Pasfhocal: Lag' },
          { default: 'Password strength: Weak' }
        ],
        fair: [
          { locale: 'en-GB', value: 'Password strength: Fair' },
          { locale: 'cy-CY', value: 'Cryfder Cyfrinair: Teg' },
          { locale: 'ga-IE', value: 'Neart Pasfhocal: Cothrom' },
          { default: 'Password strength: Fair' }
        ],
        good: [
          { locale: 'en-GB', value: 'Password strength: Good' },
          { locale: 'cy-CY', value: 'Cryfder Cyfrinair: Cryf' },
          { locale: 'ga-IE', value: 'Neart Pasfhocal: Go maith' },
          { default: 'Password strength: Good' }
        ],
        strong: [
          { locale: 'en-GB', value: 'Password strength: Strong' },
          { locale: 'cy-CY', value: 'Cryfder Cyfrinair: Cryf Iawn' },
          { locale: 'ga-IE', value: 'Neart Pasfhocal: Láidir' },
          { default: 'Password strength: Strong' }
        ]
      }
    },
    log_in: [
      {
        locale: 'en-GB',
        value: 'log in'
      },
      {
        locale: 'cy-CY',
        value: 'Mewngofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'connectez-vous'
      },
      {
        locale: 'ga-IE',
        value: 'logáil'
      },
      {
        default: 'log in'
      }
    ],
    if_already_member: [
      {
        locale: 'en-GB',
        value: "if you're already a member."
      },
      {
        locale: 'cy-CY',
        value: 'os ydych eisoes yn aelod.'
      },
      {
        locale: 'fr-FR',
        value: "Si vous' possédez déjà un compte."
      },
      {
        locale: 'ga-IE',
        value: 'más ball thú cheana.'
      },
      {
        default: "if you're already a member."
      }
    ],
    your_account: [
      {
        locale: 'en-GB',
        value: 'Your account'
      },
      {
        locale: 'cy-CY',
        value: 'Eich cyfrif'
      },
      {
        locale: 'fr-FR',
        value: 'Votre compte'
      },
      {
        locale: 'ga-IE',
        value: 'Do Chuntas'
      },
      {
        default: 'Your account'
      }
    ],
    login_or_register: [
      {
        locale: 'en-GB',
        value: 'Login or register'
      },
      {
        locale: 'cy-CY',
        value: 'Mewngofodi neu cofrestrwch'
      },
      {
        locale: 'fr-FR',
        value: 'Se connecter ou s’inscrire'
      },
      {
        locale: 'ga-IE',
        value: 'Logáil isteach nó cláraigh'
      },
      {
        default: 'Login or register'
      }
    ],
    login: [
      {
        locale: 'en-GB',
        value: 'Login'
      },
      {
        locale: 'cy-CY',
        value: 'Mewngofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Se connecter'
      },
      {
        locale: 'ga-IE',
        value: 'Logáil'
      },
      {
        default: 'Login'
      }
    ],
    login_title: [
      {
        locale: 'en-GB',
        value: 'Login'
      },
      {
        locale: 'cy-CY',
        value: 'Mewngofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Se connecter'
      },
      {
        locale: 'ga-IE',
        value: 'Mewngofnodi'
      },
      {
        default: 'Login'
      }
    ],
    register: [
      {
        locale: 'en-GB',
        value: 'Register'
      },
      {
        locale: 'cy-CY',
        value: 'Cofrestrwch'
      },
      {
        locale: 'fr-FR',
        value: 'S’inscrire'
      },
      {
        locale: 'ga-IE',
        value: 'Cláraigh'
      },
      {
        default: 'Register'
      }
    ],
    logged_in_as: [
      {
        locale: 'en-GB',
        value: 'Logged in as %{username}'
      },
      {
        locale: 'cy-CY',
        value: '%{username} wedi mewngofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Connecté en tant que %{username}'
      },
      {
        locale: 'ga-IE',
        value: 'Tá %{username} logáilte isteach'
      },
      {
        default: 'Logged in as %{username}'
      }
    ],
    logout: [
      {
        locale: 'en-GB',
        value: 'Logout'
      },
      {
        locale: 'cy-CY',
        value: 'Allgofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Déconnexion'
      },
      {
        locale: 'ga-IE',
        value: 'Logáil amach'
      },
      {
        default: 'Logout'
      }
    ],
    account: [
      {
        locale: 'en-GB',
        value: 'Account'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfrif'
      },
      {
        locale: 'fr-FR',
        value: 'Compte'
      },
      {
        locale: 'ga-IE',
        value: 'Chuntas'
      },
      {
        default: 'Account'
      }
    ],
    my_orders: [
      {
        locale: 'en-GB',
        value: 'My orders'
      },
      {
        locale: 'cy-CY',
        value: 'Fy archebion'
      },
      {
        locale: 'fr-FR',
        value: 'Mes commandes'
      },
      {
        locale: 'ga-IE',
        value: 'Mo Chuid Orduithe'
      },
      {
        default: 'My orders'
      }
    ],
    no_orders: [
      {
        locale: 'en-GB',
        value: 'You have not placed any orders yet.'
      },
      {
        locale: 'cy-CY',
        value: 'Nid ydych wedi archebu tocynnau eto.'
      },
      {
        locale: 'fr-FR',
        value: 'Vous n’avez pas encore passé de commande.'
      },
      {
        locale: 'ga-IE',
        value: 'Níl ordú cruthaithe agat go fóill.'
      },
      {
        default: 'You have not placed any orders yet.'
      }
    ],
    view_order_details: [
      {
        locale: 'en-GB',
        value: 'View details'
      },
      {
        locale: 'cy-CY',
        value: 'Gwyliwch manylion'
      },
      {
        locale: 'fr-FR',
        value: 'Plus d’infos'
      },
      {
        locale: 'ga-IE',
        value: 'Amharc ar sonraí'
      },
      {
        default: 'View details'
      }
    ],
    register_title: [
      {
        locale: 'en-GB',
        value: 'Register'
      },
      {
        locale: 'cy-CY',
        value: 'Cofrestrwch'
      },
      {
        locale: 'fr-FR',
        value: 'S’inscrire'
      },
      {
        locale: 'ga-IE',
        value: 'Cláraigh'
      },
      {
        default: 'Register'
      }
    ],
    remember_me: [
      {
        locale: 'en-GB',
        value: 'Keep me logged in'
      },
      {
        locale: 'cy-CY',
        value: 'Cofiwch fy manylion ar y cyfrifiadur hwn'
      },
      {
        locale: 'fr-FR',
        value: 'Garder ma session ouverte'
      },
      {
        locale: 'ga-IE',
        value: 'Coimeád logáilte isteach mé'
      },
      {
        default: 'Keep me logged in'
      }
    ],
    reset_password: [
      {
        locale: 'en-GB',
        value: 'Reset your password'
      },
      {
        locale: 'cy-CY',
        value: 'Ailosod cyfrinair'
      },
      {
        locale: 'fr-FR',
        value: 'Réinitialiser votre mot de passe'
      },
      {
        locale: 'ga-IE',
        value: 'Athshocraigh do phasfhocal'
      },
      {
        default: 'Reset your password'
      }
    ],
    view_details: [
      {
        locale: 'en-GB',
        value: 'View details'
      },
      {
        locale: 'cy-CY',
        value: 'Gwelwch manylion'
      },
      {
        locale: 'fr-FR',
        value: 'Plus d’infos'
      },
      {
        locale: 'ga-IE',
        value: 'Amharc ar sonraí'
      },
      {
        default: 'View details'
      }
    ],
    hide_details: [
      {
        locale: 'en-GB',
        value: 'Hide details'
      },
      {
        locale: 'cy-CY',
        value: 'Cuddio Manylion'
      },
      {
        locale: 'fr-FR',
        value: 'Moins d’infos'
      },
      {
        locale: 'ga-IE',
        value: 'Folaigh sonraí'
      },
      {
        default: 'Hide details'
      }
    ],
    your_rewards: [
      {
        locale: 'en-GB',
        value: 'Your rewards'
      },
      {
        locale: 'cy-CY',
        value: 'Eich gwobrau'
      },
      {
        locale: 'fr-FR',
        value: 'Vos récompenses'
      },
      {
        locale: 'ga-IE',
        value: 'Do Chuid Duaiseanna'
      },
      {
        default: 'Your rewards'
      }
    ],
    valid_until: [
      {
        locale: 'en-GB',
        value: 'Valid until'
      },
      {
        locale: 'cy-CY',
        value: 'Yn ddilis tan'
      },
      {
        locale: 'fr-FR',
        value: 'Valable jusqu’au'
      },
      {
        locale: 'ga-IE',
        value: 'Bailí go dtí'
      },
      {
        default: 'Valid until'
      }
    ],
    my_redemptions: [
      {
        locale: 'en-GB',
        value: 'My redemptions'
      },
      {
        locale: 'cy-CY',
        value: 'Fy adbryniadau'
      },
      {
        locale: 'fr-FR',
        value: 'Mes remboursements'
      },
      {
        locale: 'ga-IE',
        value: 'Mo Chuid Fuascailtí'
      },
      {
        default: 'My redemptions'
      }
    ],
    password: [
      {
        locale: 'en-GB',
        value: 'Password'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfrinair'
      },
      {
        locale: 'fr-FR',
        value: 'Mot de passe'
      },
      {
        locale: 'ga-IE',
        value: 'Pasfhocal'
      },
      {
        default: 'Password'
      }
    ],
    password_confirm: [
      {
        locale: 'en-GB',
        value: 'Confirm your new password'
      },
      {
        locale: 'cy-CY',
        value: 'Cadarnhewch eich cyfrinair newydd'
      },
      {
        locale: 'fr-FR',
        value: 'Confirmez votre nouveau mot de passe'
      },
      {
        locale: 'ga-IE',
        value: 'Dearbhaigh do phasfhocal nua'
      },
      {
        default: 'Confirm your new password'
      }
    ],
    new_password: [
      {
        locale: 'en-GB',
        value: 'New Password'
      },
      {
        locale: 'cy-CY',
        value: 'Cyfrinair newydd'
      },
      {
        locale: 'fr-FR',
        value: 'Nouveau mot de passe'
      },
      {
        locale: 'ga-IE',
        value: 'Pasfhocal Nua'
      },
      {
        default: 'New Password'
      }
    ],
    new_password_confirm: [
      {
        locale: 'en-GB',
        value: 'New Password confirmation'
      },
      {
        locale: 'cy-CY',
        value: 'Cadarnhad Cyfrinair Newydd'
      },
      {
        locale: 'fr-FR',
        value: 'Confirmation du nouveau mot de passe'
      },
      {
        locale: 'ga-IE',
        value: 'Dearbhú Pasfhocal Nua'
      },
      {
        default: 'New Password confirmation'
      }
    ],
    email_not_found: [
      {
        locale: 'en-GB',
        value: 'Member with email not found'
      },
      {
        locale: 'cy-CY',
        value: "Methu dod o hyd i aelod â'r ebost hyn"
      },
      {
        locale: 'fr-FR',
        value: 'Membre avec email introuvable'
      },
      {
        locale: 'ga-IE',
        value: 'Ní bhfuarthas ball le ríomhphost'
      },
      {
        default: 'Member with email not found'
      }
    ],
    updated_successfully: [
      {
        locale: 'en-GB',
        value: 'Your profile has been successfully changed'
      },
      {
        locale: 'cy-CY',
        value: "Mae eich proffil wedi'i newid yn llwyddiannus"
      },
      {
        locale: 'ga-IE',
        value: "D'éirigh le athrú do phróifíl"
      },
      {
        default: 'Your profile has been successfully changed'
      }
    ]
  },
  recommendations: {
    you_might_like: [
      {
        locale: 'en-GB',
        value: 'You might also like'
      },
      {
        locale: 'cy-CY',
        value: "Efallai byddwch yn hoffi's isod"
      },
      {
        locale: 'fr-FR',
        value: 'Vous aimerez aussi'
      },
      {
        locale: 'ga-IE',
        value: 'Moltaí'
      },
      {
        default: 'You might also like'
      }
    ]
  },
  promotion_code: {
    title: [
      {
        locale: 'en-GB',
        value: 'Enter PROMO CODE here. Use voucher codes at the payment stage'
      },
      {
        locale: 'cy-CY',
        value: 'Rhowch COD PROMO yma. Defnyddiwch godau talebau ar y cam talu'
      },
      {
        locale: 'fr-FR',
        value: `Entrez le CODE PROMO ici. Utilisez les codes d'échange de bons d'achat au stade du paiement`
      },
      {
        locale: 'ga-IE',
        value:
          'Cuir isteach CÓD PROMO anseo. Úsáid cóid fuascailte dearbháin ag an gcéim íocaíochta'
      },
      {
        default: 'Enter PROMO CODE here. Use voucher codes at the payment stage'
      }
    ],
    apply: [
      {
        locale: 'en-GB',
        value: 'Apply'
      },
      {
        locale: 'cy-CY',
        value: 'Ceisiwch'
      },
      {
        locale: 'fr-FR',
        value: 'Appliquer'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir i bhFeidhm'
      },
      {
        default: 'Apply'
      }
    ],
    remove: [
      {
        locale: 'en-GB',
        value: 'Remove promotion code'
      },
      {
        locale: 'cy-CY',
        value: 'Cymryd y cod hyrwyddo i ffwrdd'
      },
      {
        locale: 'fr-FR',
        value: 'Supprimer le code promotionnel'
      },
      {
        locale: 'ga-IE',
        value: 'Bain cód promóisin'
      },
      {
        default: 'Remove promotion code'
      }
    ],
    label: [
      {
        locale: 'en-GB',
        value: 'Promotion Code:'
      },
      {
        locale: 'cy-CY',
        value: 'Cod Hyrwyddo'
      },
      {
        locale: 'fr-FR',
        value: 'Code promotionnel :'
      },
      {
        locale: 'ga-IE',
        value: 'Cód Promóisin:'
      },
      {
        default: 'Promotion Code:'
      }
    ],
    discounts_applied: [
      {
        locale: 'en-GB',
        value: 'Discount(s) applied to these items include:'
      },
      {
        locale: 'cy-CY',
        value: "Mae'r canlynol wedi cael ei gostyngu:"
      },
      {
        locale: 'fr-FR',
        value: 'Les réductions appliquées à ces articles comprennent :'
      },
      {
        locale: 'ga-IE',
        value: 'Baineann na lascainí a leanas leis na míreanna a leanas:'
      },
      {
        default: 'Discount(s) applied to these items include:'
      }
    ],
    code_set: [
      {
        locale: 'en-GB',
        value: 'Your promotion code has been set for the cart'
      },
      {
        locale: 'cy-CY',
        value: "Mae'ch cod hyrwyddo wedi cael ei osod i'r cert"
      },
      {
        locale: 'fr-FR',
        value: 'Votre code promotionnel a été appliqué pour votre panier'
      },
      {
        locale: 'ga-IE',
        value: 'Socraíodh do chód promóisin don chiseán'
      },
      {
        default: 'Your promotion code has been set for the cart'
      }
    ],
    code_removed: [
      {
        locale: 'en-GB',
        value: 'Promotion code removed from the cart'
      },
      {
        locale: 'cy-CY',
        value: "Mae'r cod hyrwyddo wedi cael ei tynnu o'r cert"
      },
      {
        locale: 'fr-FR',
        value: 'Le code promotionnel a été retiré du panier'
      },
      {
        locale: 'ga-IE',
        value: 'Baineadh an cód promóisin ón gciseán'
      },
      {
        default: 'Promotion code removed from the cart'
      }
    ],
    placeholder: [
      {
        locale: 'en-GB',
        value: 'Enter your promotion code'
      },
      {
        locale: 'cy-CY',
        value: 'Gosodwch eich cod hyrwyddo'
      },
      {
        locale: 'fr-FR',
        value: 'Entrez votre code promotionnel'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir isteach do chód promóisin'
      },
      {
        default: 'Enter your promotion code'
      }
    ]
  },
  product: {
    no_products_with_that_tag: [
      {
        locale: 'en-GB',
        value: 'Sorry, there are no products related to that tag.'
      },
      {
        locale: 'cy-CY',
        value: 'Sori ond does dim gynnyrch yn gysylltiedig ar tag yna.'
      },
      {
        locale: 'fr-FR',
        value: 'Désolé, il n’y a aucun produit correspondant à ce tag.'
      },
      {
        locale: 'ga-IE',
        value: 'Ár leithscéal níl táirge ar fáil gaolta leis an gclib sin.'
      },
      {
        default: 'Sorry, there are no products related to that tag.'
      }
    ],
    expand_description: [
      {
        locale: 'en-GB',
        value: 'see more'
      },
      {
        locale: 'cy-CY',
        value: 'mwy'
      },
      {
        locale: 'fr-FR',
        value: 'afficher plus'
      },
      {
        locale: 'ga-IE',
        value: 'tuilleadh'
      },
      {
        default: 'see more'
      }
    ],
    collapse_description: [
      {
        locale: 'en-GB',
        value: 'hide'
      },
      {
        locale: 'cy-CY',
        value: 'cuddio'
      },
      {
        locale: 'fr-FR',
        value: 'masquer'
      },
      {
        locale: 'ga-IE',
        value: 'folaigh'
      },
      {
        default: 'hide'
      }
    ],
    retry: [
      {
        locale: 'en-GB',
        value: 'There is a problem loading products, please try again'
      },
      {
        locale: 'cy-CY',
        value: 'Mae yna problem wrth lwytho cynhyrchion, ceisiwch eto'
      },
      {
        locale: 'ga-IE',
        value: 'Tá fadhb le luchtú táirgí bain triail as arís'
      },
      {
        default: 'There is a problem loading products, please try again'
      }
    ]
  },
  show: {
    sales_disabled: [
      {
        locale: 'en-GB',
        value: 'Sales disabled'
      },
      {
        locale: 'cy-CY',
        value: 'Gwerthiant ddim ar gael'
      },
      {
        locale: 'ga-IE',
        value: 'Díolacháin díchumasaithe'
      },
      {
        default: 'Sales disabled'
      }
    ],
    no_shows: [
      {
        locale: 'en-GB',
        value: 'No shows'
      },
      {
        locale: 'cy-CY',
        value: 'Does dim sioeau'
      },
      {
        locale: 'fr-FR',
        value: 'Pas de spectacles'
      },
      {
        locale: 'ga-IE',
        value: 'Gan Léirithe'
      },
      {
        default: 'No shows'
      }
    ],
    cannot_load_shows: [
      {
        locale: 'en-GB',
        value: 'There is a problem loading shows, please try again'
      },
      {
        locale: 'cy-CY',
        value: "Mae yna problem wrth lwytho'r sioeau ceisiwch eto"
      },
      {
        locale: 'ga-IE',
        value: 'Tá fadhb le luchtú seónna bain triail as arís'
      },
      {
        default: 'There is a problem loading shows, please try again'
      }
    ],
    show_name: [
      {
        locale: 'en-GB',
        value: 'Show name'
      },
      {
        locale: 'cy-CY',
        value: "Enw'r Sioe"
      },
      {
        locale: 'fr-FR',
        value: 'Afficher le nom'
      },
      {
        locale: 'ga-IE',
        value: 'Ainm Léirithe'
      },
      {
        default: 'Show name'
      }
    ],
    company: [
      {
        locale: 'en-GB',
        value: 'Company'
      },
      {
        locale: 'cy-CY',
        value: 'Cwmni'
      },
      {
        locale: 'fr-FR',
        value: 'Société'
      },
      {
        locale: 'ga-IE',
        value: 'Comhlacht'
      },
      {
        default: 'Company'
      }
    ],
    category: [
      {
        locale: 'en-GB',
        value: 'Category'
      },
      {
        locale: 'cy-CY',
        value: 'Categori'
      },
      {
        locale: 'fr-FR',
        value: 'Catégorie'
      },
      {
        locale: 'ga-IE',
        value: 'Catagóir'
      },
      {
        default: 'Category'
      }
    ],
    dates: [
      {
        locale: 'en-GB',
        value: 'Dates'
      },
      {
        locale: 'cy-CY',
        value: 'Dyddiadau'
      },
      {
        locale: 'fr-FR',
        value: 'Dates'
      },
      {
        locale: 'ga-IE',
        value: 'Dátaí'
      },
      {
        default: 'Dates'
      }
    ],
    venue: [
      {
        locale: 'en-GB',
        value: 'Venue'
      },
      {
        locale: 'cy-CY',
        value: 'Lleoliad'
      },
      {
        locale: 'fr-FR',
        value: 'Lieu'
      },
      {
        locale: 'ga-IE',
        value: 'Ionad'
      },
      {
        default: 'Venue'
      }
    ],
    locations: [
      {
        locale: 'en-GB',
        value: '%{count} locations'
      },
      {
        locale: 'cy-CY',
        value: '%{count} lleoliad'
      },
      {
        locale: 'fr-FR',
        value: '%{count} emplacements'
      },
      {
        locale: 'ga-IE',
        value: '%{count} shuíomh'
      },
      {
        default: '%{count} locations'
      }
    ],
    upcoming_shows: [
      {
        locale: 'en-GB',
        value: 'Upcoming shows'
      },
      {
        locale: 'cy-CY',
        value: 'Sioeau sydd yn dod'
      },
      {
        locale: 'fr-FR',
        value: 'Spectacles à venir'
      },
      {
        locale: 'ga-IE',
        value: 'Léirithe ag teacht Aníos'
      },
      {
        default: 'Upcoming shows'
      }
    ],
    upcoming_category_shows: [
      {
        locale: 'en-GB',
        value: 'Upcoming %{category} shows'
      },
      {
        locale: 'cy-CY',
        value: 'Sioeau %{category} sydd yn dod'
      },
      {
        locale: 'fr-FR',
        value: 'Spectacles %{category} à venir'
      },
      {
        locale: 'ga-IE',
        value: 'Léirithe %{category} ag teacht aníos'
      },
      {
        default: 'Upcoming %{category} shows'
      }
    ]
  },
  venue: {
    shows_at: [
      {
        locale: 'en-GB',
        value: 'Shows at'
      },
      {
        locale: 'cy-CY',
        value: 'Sioeau yn'
      },
      {
        locale: 'fr-FR',
        value: 'Spectacles à'
      },
      {
        locale: 'ga-IE',
        value: 'Léirithe ag'
      },
      {
        default: 'Shows at'
      }
    ]
  },
  actions: {
    return_and_browse_more_tickets: [
      {
        locale: 'en-GB',
        value: 'return and browse for more tickets'
      },
      {
        locale: 'cy-CY',
        value: 'Dychwelyd i chwilio am fwy o docynnau'
      },
      {
        locale: 'fr-FR',
        value: 'revenir en arrière et rechercher plus de billets'
      },
      {
        locale: 'ga-IE',
        value: 'Fill agus brabhsáil ar tuilleadh ticéad'
      },
      {
        default: 'return and browse for more tickets'
      }
    ],
    back: [
      {
        locale: 'en-GB',
        value: 'back'
      },
      {
        locale: 'cy-CY',
        value: 'dychwelyd'
      },
      {
        locale: 'fr-FR',
        value: 'revenir'
      },
      {
        locale: 'ga-IE',
        value: 'ar ais'
      },
      {
        default: 'back'
      }
    ],
    book_tickets_now: [
      {
        locale: 'en-GB',
        value: 'Book tickets now'
      },
      {
        locale: 'cy-CY',
        value: 'Archebwch eich tocynnau nawr'
      },
      {
        locale: 'fr-FR',
        value: 'Réserver des billets maintenant'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir ticéid in áirithe anois'
      },
      {
        default: 'Book tickets now'
      }
    ],
    cart_page: [
      {
        locale: 'en-GB',
        value: 'Go to cart page'
      },
      {
        locale: 'cy-CY',
        value: "Ewch i'r cert"
      },
      {
        locale: 'fr-FR',
        value: 'Voir le panier'
      },
      {
        locale: 'ga-IE',
        value: 'Téigh chuig ciseán'
      },
      {
        default: 'Go to cart page'
      }
    ],
    choose_date_time_below: [
      {
        locale: 'en-GB',
        value: 'Choose a date and time below'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswch ddyddiad ac amser isod'
      },
      {
        locale: 'fr-FR',
        value: 'Choisissez une date et une heure ci-dessous'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaigh dáta agus praghas thíos'
      },
      {
        default: 'Choose a date and time below'
      }
    ],
    return_to_shows: [
      {
        locale: 'en-GB',
        value: 'Return to shows page'
      },
      {
        locale: 'cy-CY',
        value: 'Dychwelwch i tudalen sioeau'
      },
      {
        locale: 'fr-FR',
        value: 'Retourner à la page des spectacles'
      },
      {
        locale: 'ga-IE',
        value: 'Fill ar seónna'
      },
      {
        default: 'Return to shows page'
      }
    ],
    back_to_show_listing: [
      {
        locale: 'en-GB',
        value: 'Back to show listing'
      },
      {
        locale: 'cy-CY',
        value: "Yn ol i'r rhestr sioeau"
      },
      {
        locale: 'fr-FR',
        value: 'Retourner à la liste des spectacles'
      },
      {
        locale: 'ga-IE',
        value: 'Fill ar liostaí seónna'
      },
      {
        default: 'Back to show listing'
      }
    ],
    close: [
      {
        locale: 'en-GB',
        value: 'Close'
      },
      {
        locale: 'cy-CY',
        value: 'Cau'
      },
      {
        locale: 'fr-FR',
        value: 'Fermer'
      },
      {
        locale: 'ga-IE',
        value: 'Dún'
      },
      {
        default: 'Close'
      }
    ],
    close_window: [
      {
        locale: 'en-GB',
        value: 'Close window'
      },
      {
        locale: 'cy-CY',
        value: 'Cau ffenestr'
      },
      {
        locale: 'fr-FR',
        value: 'Fermer la fenêtre'
      },
      {
        locale: 'ga-IE',
        value: 'Dún an fhuinneog'
      },
      {
        default: 'Close window'
      }
    ],
    add: [
      {
        locale: 'en-GB',
        value: 'Add'
      },
      {
        locale: 'cy-CY',
        value: 'Ychwanegu'
      },
      {
        locale: 'fr-FR',
        value: 'Ajouter'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir'
      },
      {
        default: 'Add'
      }
    ],
    add_one_ticket: [
      {
        locale: 'en-GB',
        value: 'Add one ticket'
      },
      {
        locale: 'cy-CY',
        value: 'Ychwanegwch un tocyn'
      },
      {
        locale: 'fr-FR',
        value: 'Ajouter un billet'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir ticéad amháin leis'
      },
      {
        default: 'Add one ticket'
      }
    ],
    remove_one_ticket: [
      {
        locale: 'en-GB',
        value: 'Remove one ticket'
      },
      {
        locale: 'cy-CY',
        value: 'Tynnwch un tocyn'
      },
      {
        locale: 'fr-FR',
        value: 'Retirer un billet'
      },
      {
        locale: 'ga-IE',
        value: 'Bain ticéad amháin'
      },
      {
        default: 'Remove one ticket'
      }
    ],
    proceed_to_checkout: [
      {
        locale: 'en-GB',
        value: 'Proceed to checkout'
      },
      {
        locale: 'cy-CY',
        value: 'Man Talu'
      },
      {
        locale: 'fr-FR',
        value: 'Payer'
      },
      {
        locale: 'ga-IE',
        value: 'Lean ar aghaidh chuig seiceáil amach'
      },
      {
        default: 'Proceed to checkout'
      }
    ],
    return_to_cart: [
      {
        locale: 'en-GB',
        value: 'Review your cart'
      },
      {
        locale: 'cy-CY',
        value: 'Adolygu eich cert'
      },
      {
        locale: 'fr-FR',
        value: 'Voir le panier'
      },
      {
        locale: 'ga-IE',
        value: 'Súil siar ar do chiseán'
      },
      {
        default: 'Review your cart'
      }
    ],
    view: [
      {
        locale: 'en-GB',
        value: 'View'
      },
      {
        locale: 'cy-CY',
        value: 'Golygu'
      },
      {
        locale: 'fr-FR',
        value: 'Voir'
      },
      {
        locale: 'ga-IE',
        value: 'Amharc'
      },
      {
        default: 'View'
      }
    ],
    checkout_shortcut: [
      {
        locale: 'en-GB',
        value: 'Checkout'
      },
      {
        locale: 'cy-CY',
        value: 'Man Talu'
      },
      {
        locale: 'fr-FR',
        value: 'Paiement'
      },
      {
        locale: 'ga-IE',
        value: 'Seiceáil Amach'
      },
      {
        default: 'Checkout'
      }
    ],
    previous_step: [
      {
        locale: 'en-GB',
        value: 'Back to previous step'
      },
      {
        locale: 'cy-CY',
        value: "Yn ôl i'r cam diwethaf"
      },
      {
        locale: 'fr-FR',
        value: 'Retour à l’étape précédente'
      },
      {
        locale: 'ga-IE',
        value: 'Ar ais'
      },
      {
        default: 'Back to previous step'
      }
    ]
  },
  extras: {
    title: [
      {
        locale: 'en-GB',
        value: 'Extra Details'
      },
      {
        locale: 'cy-CY',
        value: 'Manylion ychwanegol'
      },
      {
        locale: 'fr-FR',
        value: 'Plus d’infos'
      },
      {
        locale: 'ga-IE',
        value: 'Tuilleadh Sonraí'
      },
      {
        default: 'Extra Details'
      }
    ],
    filter_by: [
      {
        locale: 'en-GB',
        value: 'Filter by'
      },
      {
        locale: 'cy-CY',
        value: 'Scagaire le'
      },
      {
        locale: 'fr-FR',
        value: 'Filtrer par'
      },
      {
        locale: 'ga-IE',
        value: 'Hidlo gan'
      },
      {
        default: 'Filter by'
      }
    ]
  },
  login: {
    title: [
      {
        locale: 'en-GB',
        value: 'Login'
      },
      {
        locale: 'cy-CY',
        value: 'Mewngofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Se connecter'
      },
      {
        locale: 'ga-IE',
        value: 'Logáil isteach'
      },
      {
        default: 'Login'
      }
    ],
    how_would_you_like_to_proceed: [
      {
        locale: 'en-GB',
        value: 'How would you like to proceed?'
      },
      {
        locale: 'cy-CY',
        value: 'Sut hoffech barhau?'
      },
      {
        locale: 'fr-FR',
        value: 'Comment voulez-vous procéder ?'
      },
      {
        locale: 'ga-IE',
        value: 'Conas ar mhaith leat dul ar aghaidh?'
      },
      {
        default: 'How would you like to proceed?'
      }
    ],
    create_account: [
      {
        locale: 'en-GB',
        value: 'Create account'
      },
      {
        locale: 'cy-CY',
        value: 'Creu Cyfrif'
      },
      {
        locale: 'fr-FR',
        value: 'Créer un compte'
      },
      {
        locale: 'ga-IE',
        value: 'Cruthaigh cuntas'
      },
      {
        default: 'Create account'
      }
    ],
    create_a_new_account: [
      {
        locale: 'en-GB',
        value: 'Create a new account'
      },
      {
        locale: 'cy-CY',
        value: 'Creu Cyfrif Newydd'
      },
      {
        locale: 'fr-FR',
        value: 'Créer un nouveau compte'
      },
      {
        locale: 'ga-IE',
        value: 'Cruthaigh cuntas nua'
      },
      {
        default: 'Create a new account'
      }
    ],
    continue_as_guest: [
      {
        locale: 'en-GB',
        value: 'Continue as guest'
      },
      {
        locale: 'cy-CY',
        value: 'Parhau Heb Mewngofnodi'
      },
      {
        locale: 'fr-FR',
        value: 'Continuer en tant qu’invité'
      },
      {
        locale: 'ga-IE',
        value: 'Lean ort mar aoi'
      },
      {
        default: 'Continue as guest'
      }
    ]
  },
  password: {
    email_sent: [
      {
        locale: 'en-GB',
        value:
          'We have sent you an email with further instructions to reset your password.'
      },
      {
        locale: 'cy-CY',
        value:
          'Rydym wedi anfon ebost atoch gyda chyfarwyddiadau pellach i ailosod eich cyfrinair.'
      },
      {
        locale: 'fr-FR',
        value:
          'Nous vous avons envoyé un email d’instructions pour réinitialiser votre mot de passe.'
      },
      {
        locale: 'ga-IE',
        value:
          'Sheolamar ríomhphost chugat le treoracha athshocraithe pasfhocail'
      },
      {
        default:
          'We have sent you an email with further instructions to reset your password.'
      }
    ],
    reset_your_password: [
      {
        locale: 'en-GB',
        value: 'Reset your password'
      },
      {
        locale: 'cy-CY',
        value: 'Ailosod eich cyfrinair'
      },
      {
        locale: 'fr-FR',
        value: 'Réinitialiser votre mot de passe'
      },
      {
        locale: 'ga-IE',
        value: 'Athshocraigh do phasfhocal'
      },
      {
        default: 'Reset your password'
      }
    ],
    successfully_reset: [
      {
        locale: 'en-GB',
        value: 'Your new password has been set successfully.'
      },
      {
        locale: 'cy-CY',
        value: "Mae eich cyfrinair newydd wedi'i osod yn llwyddiannus."
      },
      {
        locale: 'fr-FR',
        value: 'Votre nouveau mot de passe a été défini avec succès.'
      },
      {
        locale: 'ga-IE',
        value: "D'éirigh le socrú do phasfhocal nua"
      },
      {
        default: 'Your new password has been set successfully.'
      }
    ],
    you_should_be_redirected: [
      {
        locale: 'en-GB',
        value:
          'You should be redirected to the homepage in 5 seconds, if nothing happens click'
      },
      {
        locale: 'cy-CY',
        value:
          'Dylech gael eich ailgyfeirio at y dudalen hafan mewn 5 eiliad, pe bai dim byd yn digwydd cliciwch'
      },
      {
        locale: 'fr-FR',
        value:
          'Vous allez être redirigé vers la page d’accueil dans 5 secondes. Si rien ne passe cliquez'
      },
      {
        locale: 'ga-IE',
        value:
          'Atreorófar thú chuig an leathance baile i gceann 5 soicind muna tharlaíonn rud ar bith, gliogáil'
      },
      {
        default:
          'You should be redirected to the homepage in 5 seconds, if nothing happens click'
      }
    ],
    you_should_be_redirected_checkout: [
      {
        locale: 'en-GB',
        value:
          'You should be redirected to the checkout process in 5 seconds, if nothing happens click'
      },
      {
        locale: 'cy-CY',
        value:
          'Dylech gael eich ailgyfeirio at y dudalen hafan mewn 5 eiliad, pe bai dim byd yn digwydd cliciwch'
      },
      {
        locale: 'fr-FR',
        value:
          'Vous allez être redirigé vers le processus de paiement dans 5 secondes. Si rien ne passe cliquez'
      },
      {
        locale: 'ga-IE',
        value:
          'Atreorófar thú chuig an bpróiseas seiceála amach i gceann 5 soicind muna tharlaíonn rud ar bith, gliogáil'
      },
      {
        default:
          'You should be redirected to the checkout process in 5 seconds, if nothing happens click'
      }
    ]
  },
  product_prompt: {
    title: [
      {
        locale: 'en-GB',
        value: 'Suggested products'
      },
      {
        locale: 'cy-CY',
        value: 'Awgrymir y cynhyrchion isod'
      },
      {
        locale: 'fr-FR',
        value: 'Produits suggérés'
      },
      {
        locale: 'ga-IE',
        value: 'Táirgí Molta'
      },
      {
        default: 'Suggested products'
      }
    ]
  },
  confirm_order: {
    your_details: [
      {
        locale: 'en-GB',
        value: 'Your details'
      },
      {
        locale: 'cy-CY',
        value: 'Eoch manylion'
      },
      {
        locale: 'fr-FR',
        value: 'Vos coordonnées'
      },
      {
        locale: 'ga-IE',
        value: 'Do chuid sonraí'
      },
      {
        default: 'Your details'
      }
    ],
    user_details: {
      location: [
        {
          locale: 'en-GB',
          value: 'Location'
        },
        {
          locale: 'cy-CY',
          value: 'Lleoliad'
        },
        {
          locale: 'fr-FR',
          value: 'Emplacement'
        },
        {
          locale: 'ga-IE',
          value: 'Suíomh'
        },
        {
          default: 'Location'
        }
      ]
    },
    ssl_secure_payment: [
      {
        locale: 'en-GB',
        value: 'Secured by 256bit SSL Cert'
      },
      {
        locale: 'cy-CY',
        value: 'Ddiogelwch gan 256bit SSL Cert'
      },
      {
        locale: 'fr-FR',
        value: 'Sécurisé par certificat SSL 256 bit'
      },
      {
        locale: 'ga-IE',
        value: 'Slánaithe ag Teastas 256bit SSL'
      },
      {
        default: 'Secured by 256bit SSL Cert'
      }
    ],
    extra_order_details: [
      {
        locale: 'en-GB',
        value: 'Extra order details'
      },
      {
        locale: 'cy-CY',
        value: 'Manylion ychwanegol'
      },
      {
        locale: 'fr-FR',
        value: 'Détails de la commande'
      },
      {
        locale: 'ga-IE',
        value: 'Sonraí breise ordaithe'
      },
      {
        default: 'Extra order details'
      }
    ],
    payment_details: [
      {
        locale: 'en-GB',
        value: 'Payment details'
      },
      {
        locale: 'cy-CY',
        value: 'Manylion talu'
      },
      {
        locale: 'fr-FR',
        value: 'Détails du paiement'
      },
      {
        locale: 'ga-IE',
        value: 'Sonraí íocaíochta'
      },
      {
        default: 'Payment details'
      }
    ],
    title: [
      {
        locale: 'en-GB',
        value: 'Confirm order'
      },
      {
        locale: 'cy-CY',
        value: 'Cadarnhau cert'
      },
      {
        locale: 'fr-FR',
        value: 'Confirmer la commande'
      },
      {
        locale: 'ga-IE',
        value: 'Cuir isteach cód dearbháin'
      },
      {
        default: 'Confirm order'
      }
    ],
    pay_with_voucher_codes: [
      {
        locale: 'en-GB',
        value: 'Pay with voucher code'
      },
      {
        locale: 'cy-CY',
        value: 'Pay with voucher code'
      },
      {
        locale: 'fr-FR',
        value: 'Payer avec un code promotionnel'
      },
      {
        locale: 'ga-IE',
        value: 'Cliceáil anseo más ag íocadh le dearbhán atá tú'
      },
      {
        default: 'Pay with voucher code'
      }
    ],
    voucher_payment: [
      {
        locale: 'en-GB',
        value: 'Voucher payment'
      },
      {
        locale: 'cy-CY',
        value: 'Taliad Taleb'
      },
      {
        locale: 'fr-FR',
        value: 'Paiement par code promotionnel'
      },
      {
        locale: 'ga-IE',
        value: 'Íocaíocht Dhearbháin'
      },
      {
        default: 'Voucher payment'
      }
    ],
    insert_voucher_code: [
      {
        locale: 'en-GB',
        value: 'Insert voucher code here'
      },
      {
        locale: 'cy-CY',
        value: 'Rhowch eich god taleb yma'
      },
      {
        locale: 'fr-FR',
        value: 'Entrer le code promotionnel ici'
      },
      {
        locale: 'ga-IE',
        value: 'Rhowch eich god taleb yma'
      },
      {
        default: 'Insert voucher code here'
      }
    ],
    do_you_have_voucher: [
      {
        locale: 'en-GB',
        value: 'Do you have a voucher code?'
      },
      {
        locale: 'cy-CY',
        value: 'Oes gennych chi god taleb?'
      },
      {
        locale: 'fr-FR',
        value: 'Vous avez un code promotionnel ?'
      },
      {
        locale: 'ga-IE',
        value: 'An bhfuil cód dearbháin agat?'
      },
      {
        default: 'Do you have a voucher code?'
      }
    ],
    complete_order: [
      {
        locale: 'en-GB',
        value: 'Complete the order'
      },
      {
        locale: 'cy-CY',
        value: 'Complete the order'
      },
      {
        locale: 'fr-FR',
        value: 'Finaliser la commande'
      },
      {
        locale: 'ga-IE',
        value: 'Críochnaigh ordú'
      },
      {
        default: 'Complete the order'
      }
    ]
  },
  order: {
    to_be_paid: [
      {
        locale: 'en-GB',
        value: 'To be paid now'
      },
      {
        locale: 'cy-CY',
        value: "I'w dalu"
      },
      {
        locale: 'fr-FR',
        value: 'À payer maintenant'
      },
      {
        locale: 'ga-IE',
        value: 'Le híoc anois'
      },
      {
        default: 'To be paid now'
      }
    ],
    empty: [
      {
        locale: 'en-GB',
        value: 'Your order is empty'
      },
      {
        locale: 'cy-CY',
        value: "Mae'ch archeb yn wag"
      },
      {
        locale: 'fr-FR',
        value: 'Votre commande est vide'
      },
      {
        locale: 'ga-IE',
        value: "Tá d'ordú folamh"
      },
      {
        default: 'Your order is empty'
      }
    ],
    cancelled: [
      {
        locale: 'en-GB',
        value: 'Your order is cancelled'
      },
      {
        locale: 'cy-CY',
        value: "Mae'ch archeb wedi'i ganslo"
      },
      {
        locale: 'fr-FR',
        value: 'Votre commande est annulée'
      },
      {
        locale: 'ga-IE',
        value: "Tá d'ordú curtha ar ceall"
      },
      {
        default: 'Your order is cancelled'
      }
    ]
  },
  orders: {
    cannot_load_orders: [
      {
        locale: 'en-GB',
        value: 'There is a problem loading orders, please try again.'
      },
      {
        locale: 'cy-CY',
        value: 'Mae yna problem wrth lwytho archebion ceisiwch eto.'
      },
      {
        locale: 'ga-IE',
        value: 'Tá fadhb le luchtú orduithe bain triail as arís.'
      },
      {
        default: 'There is a problem loading orders, please try again.'
      }
    ],
    email_notification: [
      {
        locale: 'en-GB',
        value:
          'You will receive an email shortly at %{email} with your complete order details.'
      },
      {
        locale: 'cy-CY',
        value:
          'Byddwch yn derbyn e-bost yn fuan ar %{email} Gyda manylion eich archeb gyflawn.'
      },
      {
        locale: 'fr-FR',
        value:
          'Vous recevrez sous peu un email à %{email} contenant les détails de votre commande.'
      },
      {
        locale: 'ga-IE',
        value:
          'Cuirfear ríomhphost chugat go luath ag %{email} le do chuid sonraí comhlánaithe'
      },
      {
        default:
          'You will receive an email shortly at %{email} with your complete order details.'
      }
    ],
    successfully_placed: [
      {
        locale: 'en-GB',
        value: 'Your order was successfully placed.'
      },
      {
        locale: 'cy-CY',
        value: 'Mae eich archeb yn llwyddiannus.'
      },
      {
        locale: 'fr-FR',
        value: 'Votre commande a été réalisée avec succès.'
      },
      {
        locale: 'ga-IE',
        value: "D'éirigh le d'ordú"
      },
      {
        default: 'Your order was successfully placed.'
      }
    ],
    delivery_method: [
      {
        locale: 'en-GB',
        value: 'Delivery method'
      },
      {
        locale: 'cy-CY',
        value: 'Dull cyflwyno'
      },
      {
        locale: 'fr-FR',
        value: 'Mode de livraison'
      },
      {
        locale: 'ga-IE',
        value: 'Modh seachadta'
      },
      {
        default: 'Delivery method'
      }
    ],
    extra_order_details: [
      {
        locale: 'en-GB',
        value: 'Extra order details'
      },
      {
        locale: 'cy-CY',
        value: 'Manylion archeb ychwanegol'
      },
      {
        locale: 'fr-FR',
        value: 'Détails de la commande'
      },
      {
        locale: 'ga-IE',
        value: 'Tuilleadh sonraí ordaithe'
      },
      {
        default: 'Extra order details'
      }
    ],
    nothing_due: [
      {
        locale: 'en-GB',
        value: 'No payment due at this time'
      },
      {
        locale: 'cy-CY',
        value: 'Does dim angen talu ar hyn o bryd'
      },
      {
        locale: 'fr-FR',
        value: 'Aucun paiement dû'
      },
      {
        locale: 'ga-IE',
        value: 'Níl íocaíocht dlite faoi láthair'
      },
      {
        default: 'No payment due at this time'
      }
    ],
    nothing_due_amount: [
      {
        locale: 'en-GB',
        value:
          'No payment is due at this time, however you can pay the entire remaining balance %{amount} below'
      },
      {
        locale: 'cy-CY',
        value:
          'Does dim yw talu ar hyn o bryd ond gallwch talu yr holl cyfanswm o %{amount} isod'
      },
      {
        locale: 'fr-FR',
        value:
          'Aucun paiement n’est dû pour l’instant, toutefois, vous pouvez payer la totalité du solde restant %{amount} ci-dessous'
      },
      {
        locale: 'ga-IE',
        value:
          'Níl íocaíocht dlite faoi láthair, ach is féidir an iarmhéid iomlán atá fágtha a íocadh %{amount} thíos'
      },
      {
        default:
          'No payment is due at this time, however you can pay the entire remaining balance %{amount} below'
      }
    ],
    order_date: [
      {
        locale: 'en-GB',
        value: 'Order Date'
      },
      {
        locale: 'cy-CY',
        value: 'Dyddiad archeb'
      },
      {
        locale: 'fr-FR',
        value: 'Date de la commande'
      },
      {
        locale: 'ga-IE',
        value: 'Dáta Ordaithe'
      },
      {
        default: 'Order Date'
      }
    ],
    order_details: [
      {
        locale: 'en-GB',
        value: 'Order details'
      },
      {
        locale: 'cy-CY',
        value: 'Manylion archeb'
      },
      {
        locale: 'fr-FR',
        value: 'Détails de la commande'
      },
      {
        locale: 'ga-IE',
        value: 'Sonraí Orduithe'
      },
      {
        default: 'Order details'
      }
    ],
    order_number: [
      {
        locale: 'en-GB',
        value: 'Order Number'
      },
      {
        locale: 'cy-CY',
        value: 'Rhif archeb'
      },
      {
        locale: 'fr-FR',
        value: 'Numéro de la commande'
      },
      {
        locale: 'ga-IE',
        value: 'Uimhir Ordaithe'
      },
      {
        default: 'Order Number'
      }
    ],
    order_n_summary: [
      {
        locale: 'en-GB',
        value: 'Order #%{order_id} summary'
      },
      {
        locale: 'cy-CY',
        value: 'Manylion archeb #%{order_id}'
      },
      {
        locale: 'fr-FR',
        value: 'Commande #% {order_id} résumé'
      },
      {
        locale: 'ga-IE',
        value: 'Achoimre Ordaithe #%{order_id}'
      },
      {
        default: 'Order #%{order_id} summary'
      }
    ],
    payment_due: [
      {
        locale: 'en-GB',
        value: 'A payment of %{amount} is due'
      },
      {
        locale: 'cy-CY',
        value: 'Mae yna taliad o %{amount} yn ddyledus'
      },
      {
        locale: 'fr-FR',
        value: 'Un paiement de %{amount} est dû'
      },
      {
        locale: 'ga-IE',
        value: 'Tá íocaíocht %{amount} dlite'
      },
      {
        default: 'A payment of %{amount} is due'
      }
    ],
    pay_next_installment: [
      {
        locale: 'en-GB',
        value: 'Pay minimum %{amount}'
      },
      {
        locale: 'cy-CY',
        value: 'Talwch y cyfanswm o %{amount}'
      },
      {
        locale: 'fr-FR',
        value: 'Payer minimum %{amount}'
      },
      {
        locale: 'ga-IE',
        value: 'Íoc íosmhéid %{amount}'
      },
      {
        default: 'Pay minimum %{amount}'
      }
    ],
    pay_remaining: [
      {
        locale: 'en-GB',
        value: 'Pay all remaining %{amount}'
      },
      {
        locale: 'cy-CY',
        value: 'Talwch y swm cyfanswm o %{amount}'
      },
      {
        locale: 'fr-FR',
        value: 'Payer tous les %{amount}'
      },
      {
        locale: 'ga-IE',
        value: 'Íoc suim atá fágtha %{amount}'
      },
      {
        default: 'Pay all remaining %{amount}'
      }
    ],
    placed_by: [
      {
        locale: 'en-GB',
        value: 'Placed by'
      },
      {
        locale: 'cy-CY',
        value: 'Archebwyd gan'
      },
      {
        locale: 'fr-FR',
        value: 'Commandé par'
      },
      {
        locale: 'ga-IE',
        value: 'Ordaithe ag'
      },
      {
        default: 'Placed by'
      }
    ],
    print_order: [
      {
        locale: 'en-GB',
        value: 'Download tickets/items in your order'
      },
      {
        locale: 'cy-CY',
        value: 'Dadlwythwch docynnau / eitemau yn eich archeb'
      },
      {
        locale: 'fr-FR',
        value: 'Télécharger des billets/Articles de votre commande'
      },
      {
        locale: 'ga-IE',
        value: "Íoslódáil ticéad/míreanna i d'ordú"
      },
      {
        default: 'Download tickets/items in your order'
      }
    ],
    print_page: [
      {
        locale: 'en-GB',
        value: 'Print this page'
      },
      {
        locale: 'cy-CY',
        value: 'Argraffwch y tudalen hon'
      },
      {
        locale: 'fr-FR',
        value: 'Imprimer cette page'
      },
      {
        locale: 'ga-IE',
        value: 'Priontáil an leathanach seo'
      },
      {
        default: 'Print this page'
      }
    ],
    rewards_payment: [
      {
        locale: 'en-GB',
        value: 'Rewards Payment'
      },
      {
        locale: 'cy-CY',
        value: 'Gwobrwyon Talu'
      },
      {
        locale: 'fr-FR',
        value: 'Paiement des récompenses'
      },
      {
        locale: 'ga-IE',
        value: 'Íocaíocht Duaiseanna'
      },
      {
        default: 'Rewards Payment'
      }
    ],
    go_home: [
      {
        locale: 'en-GB',
        value: 'Go back to homepage'
      },
      {
        locale: 'cy-CY',
        value: "Dychwelwch i'r Hafan"
      },
      {
        locale: 'fr-FR',
        value: 'Revenir à la page d’accueil'
      },
      {
        locale: 'ga-IE',
        value: 'Fill ar leathanach Baile'
      },
      {
        default: 'Go back to homepage'
      }
    ],
    thanks: [
      {
        locale: 'en-GB',
        value: 'Thanks'
      },
      {
        locale: 'cy-CY',
        value: 'Diolch'
      },
      {
        locale: 'fr-FR',
        value: 'Merci'
      },
      {
        locale: 'ga-IE',
        value: 'Go raibh maith agat'
      },
      {
        default: 'Thanks'
      }
    ],
    your_comments: [
      {
        locale: 'en-GB',
        value: 'Your Comments'
      },
      {
        locale: 'cy-CY',
        value: 'Eich sylwadau'
      },
      {
        locale: 'fr-FR',
        value: 'Vos commentaires'
      },
      {
        locale: 'ga-IE',
        value: 'Do chuid Tagairtí'
      },
      {
        default: 'Your Comments'
      }
    ],
    your_orders: [
      {
        locale: 'en-GB',
        value: 'Your Orders'
      },
      {
        locale: 'cy-CY',
        value: 'Eich archebion'
      },
      {
        locale: 'fr-FR',
        value: 'Vos commandes'
      },
      {
        locale: 'ga-IE',
        value: 'Do chuid Orduithe'
      },
      {
        default: 'Your Orders'
      }
    ],
    interests: [
      {
        locale: 'en-GB',
        value: 'Interests'
      },
      {
        locale: 'cy-CY',
        value: 'Diddordebau'
      },
      {
        locale: 'fr-FR',
        value: 'Intérêts'
      },
      {
        locale: 'ga-IE',
        value: 'Suimeanna'
      },
      {
        default: 'Interests'
      }
    ]
  },
  sign_up: {
    title: [
      {
        locale: 'en-GB',
        value: 'Sign up'
      },
      {
        locale: 'cy-CY',
        value: 'Cofrestru'
      },
      {
        locale: 'fr-FR',
        value: 'Inscrivez-vous'
      },
      {
        locale: 'ga-IE',
        value: 'Clarú'
      },
      {
        default: 'Sign up'
      }
    ]
  },
  forms: {
    please_check_errors: [
      {
        locale: 'en-GB',
        value: 'Please check the following errors:'
      },
      {
        locale: 'cy-CY',
        value: 'Gwiriwch y gwallau canlynol:'
      },
      {
        locale: 'fr-FR',
        value: 'Veuillez vérifier les erreurs suivantes :'
      },
      {
        locale: 'ga-IE',
        value: 'Seiceáil na hearráid a leanas:'
      },
      {
        default: 'Please check the following errors:'
      }
    ],
    optional: [
      {
        locale: 'en-GB',
        value: 'optional'
      },
      {
        locale: 'cy-CY',
        value: 'dewisol'
      },
      {
        locale: 'fr-FR',
        value: 'facultatif'
      },
      {
        locale: 'ga-IE',
        value: 'roghnach'
      },
      {
        default: 'optional'
      }
    ],
    required: [
      {
        locale: 'en-GB',
        value: 'required'
      },
      {
        locale: 'cy-CY',
        value: 'gofynnol'
      },
      {
        locale: 'fr-FR',
        value: 'obligatoire'
      },
      {
        locale: 'ga-IE',
        value: 'ag teastáil'
      },
      {
        default: 'required'
      }
    ]
  },
  social: {
    share: [
      {
        locale: 'en-GB',
        value: 'Share this show'
      },
      {
        locale: 'cy-CY',
        value: 'Rhannwch y digwyddiad hwn'
      },
      {
        locale: 'fr-FR',
        value: 'Partager cet événement'
      },
      {
        locale: 'ga-IE',
        value: 'Roinn an imeacht seo'
      },
      {
        default: 'Share this show'
      }
    ],
    share_message: [
      {
        locale: 'en-GB',
        value: "I've just seen this at"
      },
      {
        locale: 'cy-CY',
        value: 'Rwyf wedi gweld y sioe yma yn'
      },
      {
        locale: 'fr-FR',
        value: "Je' viens de le voir à"
      },
      {
        locale: 'ga-IE',
        value: 'Tá seo díreach feicthe agam ar'
      },
      {
        default: "I've just seen this at"
      }
    ]
  },
  vouchers: {
    remaining: [
      {
        locale: 'en-GB',
        value: 'Remaining:'
      },
      {
        locale: 'cy-CY',
        value: 'Ar ol'
      },
      {
        locale: 'fr-FR',
        value: 'Restant :'
      },
      {
        locale: 'ga-IE',
        value: 'Fágtha'
      },
      {
        default: 'Remaining:'
      }
    ],
    created_on: [
      {
        locale: 'en-GB',
        value: 'Created on'
      },
      {
        locale: 'cy-CY',
        value: 'Crewyd ar'
      },
      {
        locale: 'fr-FR',
        value: 'Créé le'
      },
      {
        locale: 'ga-IE',
        value: 'Cruthaithe ar'
      },
      {
        default: 'Created on'
      }
    ],
    has_been_redeemed: [
      {
        locale: 'en-GB',
        value: '%{amount} has been redeemed to date, last used on %{updated_on}'
      },
      {
        locale: 'cy-CY',
        value:
          '%{amount} wedi ei hadennill hyd yn hyn, a defnyddiwyd diwethaf ar %{updated_on}'
      },
      {
        locale: 'fr-FR',
        value:
          '%{amount} a déjà été utilisé, utilisé en dernier le % {updated_on}'
      },
      {
        locale: 'ga-IE',
        value:
          '%{amount} fuascailte chun dáta, úsáid is déanaí ar %{updated_on}'
      },
      {
        default:
          '%{amount} has been redeemed to date, last used on %{updated_on}'
      }
    ]
  },
  activerecord: {
    errors: {
      messages: {
        inclusion: [
          {
            locale: 'en-GB',
            value: 'is not included in the list'
          },
          {
            locale: 'cy-CY',
            value: "nid yw'n cael ei gynnwys yn y rhestr"
          },
          {
            locale: 'fr-FR',
            value: 'n’est pas inclus dans la liste'
          },
          {
            locale: 'ga-IE',
            value: 'níl sé san áireamh ar a liosta'
          },
          {
            default: 'is not included in the list'
          }
        ],
        exclusion: [
          {
            locale: 'en-GB',
            value: 'is reserved'
          },
          {
            locale: 'cy-CY',
            value: "i'w cadw"
          },
          {
            locale: 'fr-FR',
            value: 'est réservé'
          },
          {
            locale: 'ga-IE',
            value: 'curtha in áirithe'
          },
          {
            default: 'is reserved'
          }
        ],
        invalid: [
          {
            locale: 'en-GB',
            value: 'is invalid'
          },
          {
            locale: 'cy-CY',
            value: 'yn annilys'
          },
          {
            locale: 'fr-FR',
            value: 'est non valable'
          },
          {
            locale: 'ga-IE',
            value: 'neamhbhailí'
          },
          {
            default: 'is invalid'
          }
        ],
        confirmation: [
          {
            locale: 'en-GB',
            value: "doesn't match confirmation"
          },
          {
            locale: 'cy-CY',
            value: "ddim yn cyfateb a'r cadarnhad"
          },
          {
            locale: 'fr-FR',
            value: "ne' correspond pas à la confirmation"
          },
          {
            locale: 'ga-IE',
            value: 'ní thagann sé le dearbhú'
          },
          {
            default: "doesn't match confirmation"
          }
        ],
        doesnt_match_email: [
          {
            locale: 'en-GB',
            value: 'does not match email'
          },
          {
            locale: 'cy-CY',
            value: "nid yw'n cyfateb i'r e-bost"
          },
          {
            locale: 'fr-FR',
            value: 'ne correspond pas à l’adresse email'
          },
          {
            locale: 'ga-IE',
            value: 'ní thagann sé leis an ríomhphost'
          },
          {
            default: 'does not match email'
          }
        ],
        accepted: [
          {
            locale: 'en-GB',
            value: 'must be accepted'
          },
          {
            locale: 'cy-CY',
            value: 'rhaid eu derbyn'
          },
          {
            locale: 'fr-FR',
            value: 'doit être accepté'
          },
          {
            locale: 'ga-IE',
            value: 'caithfear glacadh le'
          },
          {
            default: 'must be accepted'
          }
        ],
        empty: [
          {
            locale: 'en-GB',
            value: "can't be empty"
          },
          {
            locale: 'cy-CY',
            value: 'maeth bod yn wag'
          },
          {
            locale: 'fr-FR',
            value: "ne peut' pas être vide"
          },
          {
            locale: 'ga-IE',
            value: 'ní féidir leis a bheith folamh'
          },
          {
            default: "can't be empty"
          }
        ],
        blank: [
          {
            locale: 'en-GB',
            value: "can't be blank"
          },
          {
            locale: 'cy-CY',
            value: 'methu bod yn wag'
          },
          {
            locale: 'fr-FR',
            value: "ne peut' pas être vierge"
          },
          {
            locale: 'ga-IE',
            value: 'ní féidir leis a bheith glan'
          },
          {
            default: "can't be blank"
          }
        ],
        too_long: [
          {
            locale: 'en-GB',
            value: 'is too long (maximum is %{count} characters)'
          },
          {
            locale: 'cy-CY',
            value: 'yn rhi hir (uchafswm o %{count} gymeriadau)'
          },
          {
            locale: 'fr-FR',
            value: 'est trop long (maximum %{count} caractères)'
          },
          {
            locale: 'ga-IE',
            value: 'ró-fhada (uasmhéid %{count} carachtar)'
          },
          {
            default: 'is too long (maximum is %{count} characters)'
          }
        ],
        too_short: [
          {
            locale: 'en-GB',
            value: 'is too short (minimum is %{count} characters)'
          },
          {
            locale: 'cy-CY',
            value: 'yn rhi byr (lleiafswm o %{count} gymeriadau)'
          },
          {
            locale: 'fr-FR',
            value: 'est trop court (minimum %{count} caractères)'
          },
          {
            locale: 'ga-IE',
            value: 'ró-ghearr (uasmhéid %{count} carachtar)'
          },
          {
            default: 'is too short (minimum is %{count} characters)'
          }
        ],
        wrong_length: [
          {
            locale: 'en-GB',
            value: 'is the wrong length (should be %{count} characters)'
          },
          {
            locale: 'cy-CY',
            value: "yw'r hyd anghywir (dele fod yn %{count} gymeriadau)"
          },
          {
            locale: 'fr-FR',
            value: 'n’a pas la bonne longueur (%{count} caractères)'
          },
          {
            locale: 'ga-IE',
            value: 'fad mícheart (ba chóir %{count} carachtar)'
          },
          {
            default: 'is the wrong length (should be %{count} characters)'
          }
        ],
        taken: [
          {
            locale: 'en-GB',
            value: 'has already been taken'
          },
          {
            locale: 'cy-CY',
            value: 'eisoes wedi cael ei gymryd'
          },
          {
            locale: 'fr-FR',
            value: 'a déjà été pris'
          },
          {
            locale: 'ga-IE',
            value: 'tógtha cheana féin'
          },
          {
            default: 'has already been taken'
          }
        ],
        not_a_number: [
          {
            locale: 'en-GB',
            value: 'is not a number'
          },
          {
            locale: 'cy-CY',
            value: 'dim yn rhif'
          },
          {
            locale: 'fr-FR',
            value: 'n’est pas un nombre'
          },
          {
            locale: 'ga-IE',
            value: 'ní uimhir í'
          },
          {
            default: 'is not a number'
          }
        ],
        greater_than: [
          {
            locale: 'en-GB',
            value: 'must be greater than %{count}'
          },
          {
            locale: 'cy-CY',
            value: 'rhaid fod yn fwy na %{count}'
          },
          {
            locale: 'fr-FR',
            value: 'doit être supérieur à %{count}'
          },
          {
            locale: 'ga-IE',
            value: 'caithfear a bheith níos mó ná %{count}'
          },
          {
            default: 'must be greater than %{count}'
          }
        ],
        greater_than_or_equal_to: [
          {
            locale: 'en-GB',
            value: 'must be greater than or equal to %{count}'
          },
          {
            locale: 'cy-CY',
            value: 'rhaid fod yn fwy neu yn cyfartal a %{count}'
          },
          {
            locale: 'fr-FR',
            value: 'doit être supérieur ou égal à %{count}'
          },
          {
            locale: 'ga-IE',
            value: 'caithfear a bheith níos mó ná nó ar chómhéid le %{count}'
          },
          {
            default: 'must be greater than or equal to %{count}'
          }
        ],
        equal_to: [
          {
            locale: 'en-GB',
            value: 'must be equal to %{count}'
          },
          {
            locale: 'cy-CY',
            value: 'rhaid fod yn cyfartal a% {count}'
          },
          {
            locale: 'fr-FR',
            value: 'doit être égal à %{count}'
          },
          {
            locale: 'ga-IE',
            value: 'caithfear a bheith ar chómhéid le %{count}'
          },
          {
            default: 'must be equal to %{count}'
          }
        ],
        less_than: [
          {
            locale: 'en-GB',
            value: 'must be less than %{count}'
          },
          {
            locale: 'cy-CY',
            value: 'rhiad fod yn llai na %{count}'
          },
          {
            locale: 'fr-FR',
            value: 'doit être inférieur à %{count}'
          },
          {
            locale: 'ga-IE',
            value: 'caithfear a bheith níos lú ná %{count}'
          },
          {
            default: 'must be less than %{count}'
          }
        ],
        less_than_or_equal_to: [
          {
            locale: 'en-GB',
            value: 'must be less than or equal to %{count}'
          },
          {
            locale: 'cy-CY',
            value: 'rhaid fod yn llai neu yn cafartal a %{count}'
          },
          {
            locale: 'fr-FR',
            value: 'doit être inférieur ou égal à %{count}'
          },
          {
            locale: 'ga-IE',
            value: 'caithfear a bheith níos lú ná nó ar chómhéid le %{count}'
          },
          {
            default: 'must be less than or equal to %{count}'
          }
        ],
        odd: [
          {
            locale: 'en-GB',
            value: 'must be odd'
          },
          {
            locale: 'cy-CY',
            value: 'rhaid fod yn odrif'
          },
          {
            locale: 'fr-FR',
            value: 'doit être impair'
          },
          {
            locale: 'ga-IE',
            value: 'caithfear a bheith corr'
          },
          {
            default: 'must be odd'
          }
        ],
        even: [
          {
            locale: 'en-GB',
            value: 'must be even'
          },
          {
            locale: 'cy-CY',
            value: 'rhiad fod yn eilrif'
          },
          {
            locale: 'fr-FR',
            value: 'doit être pair'
          },
          {
            locale: 'ga-IE',
            value: 'caithfear a bheith réidh'
          },
          {
            default: 'must be even'
          }
        ],
        record_invalid: [
          {
            locale: 'en-GB',
            value: 'Validation failed: %{errors}'
          },
          {
            locale: 'cy-CY',
            value: 'Methodd dilysu: %{errors}'
          },
          {
            locale: 'fr-FR',
            value: 'La validation a échoué : %{errors}'
          },
          {
            locale: 'ga-IE',
            value: 'Bailíochtú teipthe: %{errors}'
          },
          {
            default: 'Validation failed: %{errors}'
          }
        ]
      }
    }
  },
  a11y: {
    account_options: [
      {
        locale: 'en-GB',
        value: 'Account options'
      },
      {
        locale: 'cy-CY',
        value: 'Opsiynau cyfrif'
      },
      {
        locale: 'ga-IE',
        value: 'Roghanna cuntais'
      },
      {
        default: 'Account options'
      }
    ],
    cart: {
      customer_form: {
        postcode_instructions: [
          {
            locale: 'en-GB',
            value: 'Enter your postcode to list options'
          },
          {
            locale: 'cy-CY',
            value: 'Rhowch eich cod post i restru opsiynau'
          },
          {
            locale: 'ga-IE',
            value: 'Cuir isteach do chód poist chun roghanna a liostú'
          },
          {
            default: 'Enter an address and then search using the search button'
          }
        ]
      },
      checkout_form: [
        {
          locale: 'en-GB',
          value: 'Your details form'
        },
        {
          locale: 'cy-CY',
          value: 'Ffurflen manylion'
        },
        {
          locale: 'ga-IE',
          value: 'Foirm do chuid sonraí'
        },
        {
          default: 'Your details form'
        }
      ],
      contents: [
        {
          locale: 'en-GB',
          value: 'Cart contents'
        },
        {
          locale: 'cy-CY',
          value: 'Cynnwysion y fasged'
        },
        {
          locale: 'ga-IE',
          value: 'A bhfuil sa chiseán'
        },
        {
          default: 'Cart contents'
        }
      ],
      details: [
        {
          locale: 'en-GB',
          value: 'View cart details'
        },
        {
          locale: 'cy-CY',
          value: 'Gweld manylion y fasged'
        },
        {
          locale: 'ga-IE',
          value: 'Amharc ar sonraí ciseáin'
        },
        {
          default: 'View cart details'
        }
      ],
      remove_line_item: [
        {
          locale: 'en-GB',
          value: 'Remove %{name}}'
        },
        {
          locale: 'cy-CY',
          value: 'Dileu %{name}'
        },
        {
          locale: 'ga-IE',
          value: 'Bain %{name}'
        },
        {
          default: 'Remove %{name}'
        }
      ]
    },
    footer: {
      info: [
        {
          locale: 'en-GB',
          value: 'Cart session details'
        },
        {
          locale: 'cy-CY',
          value: 'Gwybodaeth sesiwn y fasged'
        },
        {
          locale: 'ga-IE',
          value: 'Eolas faoin seisiún ciseáin'
        },
        {
          default: 'Cart session details'
        }
      ]
    },
    membership: {
      form: [
        {
          locale: 'en-GB',
          value: 'Member profile form'
        },
        {
          locale: 'cy-CY',
          value: 'Ffurflen proffil aelod'
        },
        {
          locale: 'ga-IE',
          value: 'Foirm próifíle baill'
        },
        {
          default: 'Member profile form'
        }
      ]
    },
    products: {
      add_benefit: [
        {
          locale: 'en-GB',
          value: 'Add %{name} to cart'
        },
        {
          locale: 'cy-CY',
          value: 'Ychwanegwch %{name} i’r fasged'
        },
        {
          locale: 'ga-IE',
          value: 'Cuir %{name} sa chiseán'
        },
        {
          default: 'Add %{name} to cart'
        }
      ]
    },
    show: {
      events: {
        event_information: [
          {
            locale: 'en-GB',
            value: 'Event information'
          },
          {
            locale: 'cy-CY',
            value: 'Gwybodaeth sioe'
          },
          {
            locale: 'ga-IE',
            value: 'Eolas imeachta'
          },
          {
            default: 'Event information'
          }
        ],
        venue_name: [
          {
            locale: 'en-GB',
            value: 'Venue name:'
          },
          {
            locale: 'cy-CY',
            value: "Enw'r lleoliad:"
          },
          {
            locale: 'ga-IE',
            value: 'Anim ionad:'
          },
          {
            default: 'Venue name:'
          }
        ],
        dates: [
          {
            locale: 'en-GB',
            value: 'Dates:'
          },
          {
            locale: 'cy-CY',
            value: 'Dyddiadau:'
          },
          {
            locale: 'ga-IE',
            value: 'Dátaí:'
          },
          {
            default: 'Dates:'
          }
        ],
        add_tickets: [
          {
            locale: 'en-GB',
            value: 'Add tickets to cart'
          },
          {
            locale: 'cy-CY',
            value: "Ychwanegu tocynnau i'r drol"
          },
          {
            locale: 'ga-IE',
            value: 'Cuir ticéid leis an gciseán'
          },
          {
            default: 'Add tickets to cart'
          }
        ],
        add_more_tickets: [
          {
            locale: 'en-GB',
            value: 'Add more tickets to cart'
          },
          {
            locale: 'cy-CY',
            value: "Ychwanegu mwy o docynnau i'r drol"
          },
          {
            locale: 'ga-IE',
            value: 'Cuir tuilleadh ticéad leis an tralaí'
          },
          {
            default: 'Add more tickets to cart'
          }
        ],
        ticket_price: [
          {
            locale: 'en-GB',
            value: 'Ticket price'
          },
          {
            locale: 'cy-CY',
            value: 'Pris tocyn'
          },
          {
            locale: 'ga-IE',
            value: 'Praghas ticéid'
          },
          {
            default: 'Ticket price'
          }
        ],
        close_ticket_prices: [
          {
            locale: 'en-GB',
            value: 'Close ticket prices'
          },
          {
            locale: 'cy-CY',
            value: 'Cau prisiau tocynnau'
          },
          {
            locale: 'ga-IE',
            value: 'Dún praghsanna ticéid'
          },
          {
            default: 'Close ticket prices'
          }
        ],
        category: [
          {
            locale: 'en-GB',
            value: 'Category: %{name}'
          },
          {
            locale: 'cy-CY',
            value: 'Categori: %{name}'
          },
          {
            locale: 'ga-IE',
            value: 'Catagóir: %{name}'
          },
          {
            default: 'Category: %{name}'
          }
        ],
        details: [
          {
            locale: 'en-GB',
            value: 'Details for %{name}'
          },
          {
            locale: 'cy-CY',
            value: 'Manylion ar gyfer %{name}'
          },
          {
            locale: 'ga-IE',
            value: 'Sonraí %{name}'
          },
          {
            default: 'Details for %{name}'
          }
        ],
        events_tickets: [
          {
            locale: 'en-GB',
            value: 'Event tickets'
          },
          {
            locale: 'cy-CY',
            value: 'Tocynnau digwyddiad'
          },
          {
            locale: 'ga-IE',
            value: 'Ticéid imeachta'
          },
          {
            default: 'Event tickets'
          }
        ]
      },
      calendar: {
        info: [
          {
            locale: 'en-GB',
            value: 'Calendar of events'
          },
          {
            locale: 'cy-CY',
            value: 'Calendr Digwyddiadau'
          },
          {
            locale: 'ga-IE',
            value: 'Feilire Imeachtaí'
          },
          {
            default: 'Calendar of events'
          }
        ],
        navigation: [
          {
            locale: 'en-GB',
            value: 'Calendar navigation'
          },
          {
            locale: 'cy-CY',
            value: 'Mordwyo Calendr'
          },
          {
            locale: 'ga-IE',
            value: 'Nascleanúint Féilire'
          },
          {
            default: 'Calendar navigation'
          }
        ],
        dates: [
          {
            locale: 'en-GB',
            value: 'Calendar dates'
          },
          {
            locale: 'cy-CY',
            value: 'Dyddiadau Calendr'
          },
          {
            locale: 'ga-IE',
            value: 'Dataí Féilire'
          },
          {
            default: 'Calendar dates'
          }
        ],
        events_on_date: [
          {
            locale: 'en-GB',
            value: 'Events on %{date}'
          },
          {
            locale: 'cy-CY',
            value: 'Digwyddiadau ar %{date}'
          },
          {
            locale: 'ga-IE',
            value: 'Imeachtaí ar %{date}'
          },
          {
            default: 'Events on %{date}'
          }
        ],
        next_month: [
          {
            locale: 'en-GB',
            value: 'View next month, %{date}'
          },
          {
            locale: 'cy-CY',
            value: 'Mis nesaf, %{date}'
          },
          {
            locale: 'ga-IE',
            value: 'An mhí seo chugainn %{date}'
          },
          {
            default: 'View next month, %{date}'
          }
        ],
        previous_month: [
          {
            locale: 'en-GB',
            value: 'Previous month, %{date}'
          },
          {
            locale: 'cy-CY',
            value: 'Mis diwethaf, %{date}'
          },
          {
            locale: 'ga-IE',
            value: 'An mhí seo caite %{date}'
          },
          {
            default: 'Previous month, %{date}'
          }
        ]
      }
    },
    shows: {
      title: [
        {
          locale: 'en-GB',
          value: 'Shows'
        },
        {
          locale: 'cy-CY',
          value: 'Sioeau'
        },
        {
          locale: 'ga-IE',
          value: 'Seónna'
        },
        {
          default: 'Shows'
        }
      ],
      return_to_shows: [
        {
          locale: 'en-GB',
          value: 'Return to shows page'
        },
        {
          locale: 'cy-CY',
          value: "Dychwelyd i'r dudalen digwyddiadau"
        },
        {
          locale: 'ga-IE',
          value: 'Fill are leathanach seónna'
        },
        {
          default: 'Return to shows page'
        }
      ]
    }
  },
  gift_aid_prompt: {
    all_donations: [
      {
        locale: 'en-GB',
        value: 'All Donations'
      },
      {
        locale: 'cy-CY',
        value: 'Pob Rhodd'
      },
      {
        locale: 'fr-FR',
        value: 'Tous les Dons'
      },
      {
        locale: 'ga-IE',
        value: 'Gach Síntiús'
      },
      {
        default: 'All Donations'
      }
    ],
    today_onwards: [
      {
        locale: 'en-GB',
        value: 'Today & Onwards'
      },
      {
        locale: 'cy-CY',
        value: 'Heddiw & Ymlaen'
      },
      {
        locale: 'fr-FR',
        value: 'Aujourd’hui & Ensuite'
      },
      {
        locale: 'ga-IE',
        value: 'Inniu & Ar Aghaidh'
      },
      {
        default: 'Today & Onwards'
      }
    ],
    today_only: [
      {
        locale: 'en-GB',
        value: 'Today only'
      },
      {
        locale: 'cy-CY',
        value: 'Heddiw yn unig'
      },
      {
        locale: 'fr-FR',
        value: 'Aujourd’hui seulement'
      },
      {
        locale: 'ga-IE',
        value: 'Inniu amháin'
      },
      {
        default: 'Today only'
      }
    ],
    treat_as_gift_aid_donations_made: [
      {
        locale: 'en-GB',
        value: 'Treat as Gift Aid donations made:'
      },
      {
        locale: 'cy-CY',
        value: 'Ystyriwch fel rhoddion Cymorth Rhodd a wnaed:'
      },
      {
        locale: 'fr-FR',
        value: 'Considérer comme des dons Aid Gift effectués:'
      },
      {
        locale: 'ga-IE',
        value: 'Láimhseáil mar shíntiúis Cúnaimh Bronntanais a rinneadh:'
      },
      {
        default: 'Treat as Gift Aid donations made:'
      }
    ],
    only_treat_as_gift_aid_the_donations_in_your_current_cart: [
      {
        locale: 'en-GB',
        value: 'Only treat as Gift Aid the donations in your current cart.'
      },
      {
        locale: 'cy-CY',
        value:
          'Triniaethwch yn unig fel Cymorth Rhodd y rhoddion yn eich cert presennol.'
      },
      {
        locale: 'fr-FR',
        value:
          'Traitez uniquement comme Aide sous forme de Don les dons dans votre panier actuel.'
      },
      {
        locale: 'ga-IE',
        value:
          'Láimhseáil mar Chúnamh Bronntanas na síntiúis i do chairt reatha amháin.'
      },
      {
        default: 'Only treat as Gift Aid the donations in your current cart.'
      }
    ],
    option_all: {
      title: [
        {
          locale: 'en-GB',
          value: 'Previously'
        },
        {
          locale: 'cy-CY',
          value: 'Yn flaenorol'
        },
        {
          locale: 'fr-FR',
          value: 'Précédemment'
        },
        {
          locale: 'ga-IE',
          value: 'Roimhe seo'
        },
        {
          default: 'Previously'
        }
      ],
      text: [
        {
          locale: 'en-GB',
          value: 'All donations made today & for last 4 years'
        },
        {
          locale: 'cy-CY',
          value: 'Pob rhodd a wnaed heddiw ac am y 4 blynedd diwethaf'
        },
        {
          locale: 'fr-FR',
          value:
            "Tous les dons faits aujourd'hui et au cours des 4 dernières années"
        },
        {
          locale: 'ga-IE',
          value: 'Gach síntiús a rinneadh inniu agus le 4 bliana anuas'
        },
        {
          default: 'All donations made today & for last 4 years'
        }
      ]
    },
    option_onwards: {
      title: [
        {
          locale: 'en-GB',
          value: 'Today'
        },
        {
          locale: 'cy-CY',
          value: 'Heddiw'
        },
        {
          locale: 'fr-FR',
          value: 'Aujourd’hui'
        },
        {
          locale: 'ga-IE',
          value: 'Inniu'
        },
        {
          default: 'Today'
        }
      ],
      text: [
        {
          locale: 'en-GB',
          value: 'All donations in your current cart'
        },
        {
          locale: 'cy-CY',
          value: 'Pob rhodd yn eich cert presennol'
        },
        {
          locale: 'fr-FR',
          value: 'Tous les dons dans votre panier actuel'
        },
        {
          locale: 'ga-IE',
          value: 'Gach síntiús i do chairt reatha'
        },
        {
          default: 'All donations in your current cart'
        }
      ]
    },
    option_cart: {
      title: [
        {
          locale: 'en-GB',
          value: 'Onwards'
        },
        {
          locale: 'cy-CY',
          value: 'Ymlaen'
        },
        {
          locale: 'fr-FR',
          value: 'En avant'
        },
        {
          locale: 'ga-IE',
          value: 'Ar aghaidh'
        },
        {
          default: 'Onwards'
        }
      ],
      text: [
        {
          locale: 'en-GB',
          value: 'Any future donation you make on this site'
        },
        {
          locale: 'cy-CY',
          value: 'Unrhyw rodd yn y dyfodol a wnewch ar y safle hwn'
        },
        {
          locale: 'fr-FR',
          value: 'Tout don futur que vous effectuez sur ce site'
        },
        {
          locale: 'ga-IE',
          value: 'Aon síntiús amach anseo a dhéanfaidh tú ar an suíomh seo'
        },
        {
          default: 'Any future donation you make on this site'
        }
      ]
    },
    suggested: [
      {
        locale: 'en-GB',
        value: 'Suggested'
      },
      {
        locale: 'cy-CY',
        value: 'Awgrymedig'
      },
      {
        locale: 'fr-FR',
        value: 'Suggéré'
      },
      {
        locale: 'ga-IE',
        value: 'Molta'
      },
      {
        default: 'suggested'
      }
    ],
    selected: [
      {
        locale: 'en-GB',
        value: 'Selected'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswyd'
      },
      {
        locale: 'fr-FR',
        value: 'Sélectionné'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaithe'
      },
      {
        default: 'Selected'
      }
    ],
    select: [
      {
        locale: 'en-GB',
        value: 'Select'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswch'
      },
      {
        locale: 'fr-FR',
        value: 'Sélectionner'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaigh'
      },
      {
        default: 'Select'
      }
    ],
    choose_your_option: [
      {
        locale: 'en-GB',
        value: 'Choose your option'
      },
      {
        locale: 'cy-CY',
        value: 'Dewiswch eich opsiwn'
      },
      {
        locale: 'fr-FR',
        value: 'Choisissez votre option'
      },
      {
        locale: 'ga-IE',
        value: 'Roghnaigh do rogha'
      },
      {
        default: 'Choose your option'
      }
    ],
    opt_out: [
      {
        locale: 'en-GB',
        value: "I don't want to gift aid."
      },
      {
        locale: 'cy-CY',
        value: 'Dydw i ddim eisiau rhoi cymorth rhodd.'
      },
      {
        locale: 'fr-FR',
        value: 'Je ne veux pas faire de don aidé.'
      },
      {
        locale: 'ga-IE',
        value: 'Níl mé ag iarraidh cabhrú le tabhartas.'
      },
      {
        default: "I don't want to gift aid."
      }
    ]
  }
};

export default locales;
